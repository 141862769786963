<div class="profile-settings-wrapper">
  <div class="dialog-header">
    <h1 mat-dialog-title *ngIf="!isChangingPassword">Profile settings</h1>
    <div class="change-password-top" *ngIf="isChangingPassword">
      <button mat-icon-button (click)="onBack()">
        <img src="/assets/images/icons/arrow-back.svg" />
      </button>
      <h1 mat-dialog-title>Change password</h1>
    </div>
    <button cdkFocusInitial mat-dialog-close class="close-button" *ngIf="!isChangingPassword">
      <img src="/assets/images/icons/close.svg" />
    </button>
  </div>

  <form #userForm="ngForm" class="dialog-form">
    <div mat-dialog-content *ngIf="!isChangingPassword">
      <div class="user-image-and-details">
        <div class="user-image-camera">
          <div class="form-group">
            <app-upload
              [accept]="acceptedMimeTypes.imageJpeg + ',' + acceptedMimeTypes.imagePng"
              (change)="fileDetails = $event"
              [dataUrl]="data?.profilePictureUrl || '/assets/images/user-placeholder.svg'"
              [dataType]="'image'"
              [uploadKey]="data?.profilePictureKey"
              [showInfo]="false"
            >
            </app-upload>
          </div>
        </div>
        <div class="user-name-details-type">
          <div class="user-name-details">
            <div class="form-group">
              <label for="firstName">First name</label>
              <!-- hidden input needed for disabling autofill -->
              <input class="hide-element-display" type="text" name="firstName" />
              <input
                [(ngModel)]="data.firstName"
                type="text"
                placeholder="Enter first name"
                id="firstName"
                name="firstName"
                maxlength="40"
                #firstName
                autocomplete="do-not-autofill"
              />
              <app-input-error>{{ firstName.validationMessage }}</app-input-error>
            </div>
            <div class="form-group">
              <label for="lastName">Last name</label>
              <!-- hidden input needed for disabling autofill -->
              <input class="hide-element-display" type="text" name="firstName" />
              <input
                [(ngModel)]="data.lastName"
                type="text"
                placeholder="Enter last name"
                id="lastName"
                name="lastName"
                maxlength="40"
                #lastName
                autocomplete="do-not-autofill"
              />
              <app-input-error>{{ lastName.validationMessage }}</app-input-error>
            </div>
          </div>
          <div class="form-group">
            <label>Role</label>
            <ng-select
              placeholder="Change type"
              [(ngModel)]="data.type"
              [items]="adminTypes"
              name="type"
              required
              disabled
            >
            </ng-select>
            <app-input-error>Type is invalid.</app-input-error>
          </div>
        </div>
      </div>

      <div class="user-email-password">
        <div class="form-group">
          <label for="email">Email</label>
          <input [(ngModel)]="data.email" type="email" id="email" name="email" readonly disabled />
        </div>
        <div class="form-group">
          <button class="change-password" (click)="togglePasswordField()"><span>Change Password</span></button>
        </div>
      </div>

      <div mat-dialog-actions [appLoading]="isLoading">
        <button
          mat-button
          class="save-changes-button"
          [disabled]="
            (userForm.invalid || userForm.pristine || !data.firstName || !data.lastName) &&
            this.fileDetails?.filename == null
          "
          (click)="onSaveChanges()"
        >
          <span>Save changes</span>
        </button>
        <button mat-button class="loading-dark log-out-button" (click)="onLogout()" [appLoading]="isLoggingOut">
          <span>Log out</span>
        </button>
      </div>
      <app-error [error]="error"></app-error>
    </div>
    <div *ngIf="isChangingPassword">
      <form #changePasswordForm="ngForm" class="change-password-form">
        <div class="form-group">
          <label for="password">Current password</label>
          <input
            [(ngModel)]="data.password"
            [type]="hidePassword ? 'password' : 'text'"
            class="password-input"
            id="password"
            name="password"
            required
          />
          <app-input-error>Current password is required.</app-input-error>
          <img
            [src]="hidePassword ? '/assets/images/icons/eye.svg' : '/assets/images/icons/eye-off.svg'"
            class="icon eye-icon"
            (click)="hidePassword = !hidePassword"
          />
        </div>

        <div class="form-group">
          <label for="newPassword">New password</label>
          <input
            [(ngModel)]="data.newPassword"
            [type]="hideNewPassword ? 'password' : 'text'"
            class="password-input"
            id="newPassword"
            name="newPassword"
            required
          />
          <app-input-error>New password is required.</app-input-error>
          <img
            [src]="hideNewPassword ? '/assets/images/icons/eye.svg' : '/assets/images/icons/eye-off.svg'"
            class="icon eye-icon"
            (click)="hideNewPassword = !hideNewPassword"
          />
        </div>

        <div class="form-group">
          <label for="confirmPassword">Confirm password</label>
          <input
            [(ngModel)]="data.confirmPassword"
            [type]="hideConfirmPassword ? 'password' : 'text'"
            class="password-input"
            id="confirmPassword"
            name="confirmPassword"
            required
          />
          <app-input-error>Confirm password is required.</app-input-error>
          <img
            [src]="hideConfirmPassword ? '/assets/images/icons/eye.svg' : '/assets/images/icons/eye-off.svg'"
            class="icon eye-icon"
            (click)="hideConfirmPassword = !hideConfirmPassword"
          />
        </div>
        <app-password-constraints></app-password-constraints>
        <button
          class="save-changes-button"
          [appLoading]="isLoading"
          [disabled]="changePasswordForm.invalid"
          (click)="changePassword()"
        >
          <span>Set new password</span>
        </button>
        <app-error [error]="error"></app-error>
      </form>
    </div>
  </form>
</div>
