import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { AdminTypeAll, AdminType, Concierge, AdminStatus } from '../../models/admin';
import { UploadScope } from '../../models/upload';
import { AdminUserService } from '../../services/admin-user.service';
import { AuthService } from '../../services/auth.service';
import { TabService } from '../../services/tab.service';
import { ToastService } from '../../services/toast.service';
import { UploadService } from '../../services/upload.service';
import { ErrorUtils } from '../../utils/error.utils';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AcceptedMimeTypes, FileDetails } from '../upload/upload.component';

export interface EditConciergeDialogData extends Partial<Concierge> {
  initialType: AdminType;
}
export interface CloseEditAdminDialogComponent {
  hasChangedRole?: boolean;
  hasDeletedUser?: boolean;
}

@Component({
  selector: 'app-edit-admin-dialog',
  templateUrl: './edit-admin-dialog.component.html',
  styleUrls: ['./edit-admin-dialog.component.scss'],
})
export class EditAdminDialogComponent {
  error: any;
  adminTypes = AdminTypeAll.map((item) => ({ label: item, value: item }));
  adminStatus = AdminStatus;
  fileDetails: FileDetails;
  filename: string;
  isLoading: boolean;
  acceptedMimeTypes = AcceptedMimeTypes;

  constructor(
    public dialogRef: MatDialogRef<EditAdminDialogComponent>,
    private toastService: ToastService,
    private authService: AuthService,
    private adminUserService: AdminUserService,
    private uploadService: UploadService,
    public tabService: TabService,
    @Inject(MAT_DIALOG_DATA) public data: EditConciergeDialogData,
    public dialogConfirmation: MatDialog
  ) {}

  getUploadScope() {
    const userType = this.authService.user.type;
    if (userType === AdminType.SUPERADMIN || userType === AdminType.CONCIERGE || userType === AdminType.BROKER) {
      return UploadScope.adminProfilePicture;
    }
    return UploadScope.userProfilePicture;
  }

  async onSaveChanges(): Promise<void> {
    this.isLoading = true;
    try {
      const updatedAdmin = await this.adminUserService.updateOne(this.data._id, this.data);
      if (this.fileDetails) {
        await this.uploadService.uploadFile({
          name: this.fileDetails.filename,
          scope: this.getUploadScope(),
          file: this.fileDetails.file,
          owner: updatedAdmin._id,
          metadata: {
            filename: this.fileDetails.filename,
            height: this.fileDetails.height,
            width: this.fileDetails.width,
            size: this.fileDetails.size,
          },
        });
        this.adminUserService.markUpdate();
      }
      const closeResult: CloseEditAdminDialogComponent = {
        hasChangedRole: this.data.type !== this.data.initialType,
      };
      this.dialogRef.close(closeResult);
      this.toastService.showSuccess('Profile updated');
    } catch (error) {
      this.toastService.showError(ErrorUtils.toString(error));
    } finally {
      this.isLoading = false;
    }
  }

  async clickUpdateStatus(status: AdminStatus) {
    if (status !== this.adminStatus.DISABLED) {
      return this.updateAdminStatus(status);
    }

    const title = `Disable ${this.data.fullName || this.data.firstName + ' ' + this.data.lastName}?`;
    const message = 'Disabling an account will restrict the user from logging in.';
    const actionMessage = 'Disable account';
    this.openConfirmationDialog(title, message, actionMessage, 'info', status);
  }

  async clickDeleteButton() {
    const title = `Delete ${this.data.fullName || this.data.firstName + ' ' + this.data.lastName}?`;
    const message = 'Deleting an account will remove all data attached to it.';
    const actionMessage = 'Delete account';
    this.openConfirmationDialog(title, message, actionMessage, 'warn');
  }

  openConfirmationDialog(
    title: string,
    message: string,
    actionMessage: string,
    color: 'warn' | 'info',
    status?: AdminStatus
  ) {
    let dialogConfirmationRef = this.dialogConfirmation.open(ConfirmationDialogComponent, {
      data: {
        title,
        message,
        actionMessage,
        color,
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogConfirmationRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: boolean) => {
        if (!result) return;
        if (status) return this.updateAdminStatus(status);

        const closeResult: CloseEditAdminDialogComponent = {
          hasDeletedUser: true,
        };
        await this.deleteAccount();
        this.dialogRef.close(closeResult);
      });
  }

  async updateAdminStatus(status: AdminStatus) {
    try {
      this.error = null;
      this.isLoading = true;
      await this.adminUserService.updateOne(this.data._id, { status });
      this.toastService.show('User updated');
      this.dialogRef.close();
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteAccount() {
    try {
      this.error = null;
      this.isLoading = true;
      await this.adminUserService.deleteOne(this.data._id);
      this.toastService.show('User deleted');
      this.dialogRef.close();
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }
}
