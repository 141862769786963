<header *ngIf="isOnAdmin">
  <app-toolbar (menuToggle)="toggleSidenav()"></app-toolbar>
</header>

<div class="backdrop" *ngIf="isMobile && sidenavOpened"></div>

<aside *ngIf="isOnAdmin || isMobile" [class.opened]="sidenavOpened" (click)="toggleSidenav()">
  <app-sidenav
    [isMobile]="isMobile"
    [isCondensed]="condensed"
    (menuToggle)="toggleSidenav()"
    (logout)="sidenavOpened = false"
    (click)="$event.stopPropagation()"
  ></app-sidenav>
</aside>

<main [class.google-recaptcha-enabled]="isGoogleRecaptchaEnabled">
  <router-outlet></router-outlet>
  <app-view-as-banner></app-view-as-banner>
</main>
