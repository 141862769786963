import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { SoundtrackCategory } from '../models/soundtrack-category';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { PagedResponse } from '../models/paged-response';

export interface SoundtrackAssetsCategory {
  _id: string;
  name: string;
  order: number;
  count: string;
}

export interface SoundtrackAssetsCategoryEdit extends Partial<SoundtrackAssetsCategory> {
  isPendingAdd?: boolean;
}

export interface SoundtrackAssetsCategoryUpdate {
  id: string;
  name?: string;
  order?: number;
}

@Injectable({
  providedIn: 'root',
})
export class SoundtrackCategoryService extends CrudService<SoundtrackCategory> {
  private hasChanges = false;

  constructor(http: HttpClient) {
    super(http, 'soundtracks-categories');
  }

  async getSoundtracksCategories(limit = 25) {
    const paged = await firstValueFrom(
      this.http.get<PagedResponse<SoundtrackAssetsCategory>>('soundtracks-categories/group', {
        params: {
          limit,
        },
      })
    );
    return paged.data;
  }

  get hasSavedChanges(): boolean {
    return this.hasChanges;
  }

  set hasSavedChanges(value: boolean) {
    this.hasChanges = value;
  }
}
