import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateUserProfileDialog } from 'src/app/admin/overview/overview-notable/add-or-edit-notable/add-or-edit-notable.component';
import { SuccessScreenComponent } from 'src/app/core/success-screen/success-screen.component';

@Injectable({
  providedIn: 'root',
})
export class SuccessDialogService {
  constructor(private dialog: MatDialog) {}

  openSuccessDialog(data: CreateUserProfileDialog = {}, width = '100vw', height = '100vh', maxWidth = '100vw') {
    this.dialog.open(SuccessScreenComponent, {
      width,
      height,
      maxWidth,
      data,
    });
  }
}
