import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from '../../../environments/environment';
// import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  hidePassword: boolean = true;
  isLoading: boolean;
  error: any;

  isCaptchaEnabled = environment.googleRecaptcha.enabled;

  constructor(
    private authService: AuthService,
    private router: Router,
    // private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['admin']);
    }
  }

  public async login() {
    if (this.isLoading) return;

    try {
      // await this.validateCaptcha();

      this.error = null;
      this.isLoading = true;
      await this.authService.login(this.email, this.password);
      const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/admin';
      this.router.navigateByUrl(returnUrl);
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  // private async validateCaptcha() {
  //   // Skip checks if check is not enabled
  //   if (!this.isCaptchaEnabled) return;

  //   const token = await this.recaptchaV3Service.execute('login').toPromise();

  //   const isLegit = await this.authService.verifyCaptcha(token);
  //   if (!isLegit) throw new Error('Captcha failed. Please try again later');
  // }

  forgotPassword() {
    this.router.navigate(['/forgot-password']);
  }
}
