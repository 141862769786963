import { AssetResponseDto } from '../services/assets.service';
import { BrushDefinition } from '../utils/constants';
import { NotableAsset } from './notable';

export enum AssetStatus {
  SHOWING = 'showing',
  DISABLED = 'disabled',
}

export enum AssetType {
  audio = 'audio',
  video = 'video',
  brush = 'brush',
  brushDefinition = 'brushDefinition',
  soundtrackCategory = 'soundtrackCategory', // enforced
}

export enum BrushType {
  // WHITE = 'White',
  RUBIN = 'Rubin',
  GOLD = 'Gold',
  SILVER = 'Silver',
  ROYAL_BLUE = 'Royal blue',
  EMERALD = 'Emerald',
}

export enum AssetTab {
  GENERAL = 'general',
  NOTABLES = 'notables',
}

export enum BackgroundTextPosition {
  LEFT = 'Left',
  RIGHT = 'Right',
}

export class CreateAssets {
  assetsKeys: NotableAsset[];
  notableId: string;
  soundtrackCategoryId?: string;
}

export interface ReassignAssets {
  assetsKeys: NotableAsset[];
  notableId?: string;
  soundtrackCategoryId?: string;
}

export interface Asset {
  _id?: string;
  updatedAt?: Date;
  createdAt?: Date;
  name: string;
  key?: string;
  url?: string;
  status: AssetStatus;
  soundtrackCategoryId?: string;
  notableId: string;
  type: AssetType;
  backgroundTextPosition?: BackgroundTextPosition;
  thumbnailKey?: string;
  thumbnailUrl?: string;
}

export class GraphAssets {
  backgroundId?: string;
  background?: AssetResponseDto;

  soundtrackId?: string;
  soundtrack?: AssetResponseDto;

  brushType?: BrushType;
  brush?: BrushDefinition;
}
