import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../utils/string.utils';

@Pipe({
  name: 'trimFileExtension',
})
export class TrimFileExtensionPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return StringUtils.trimFileNameExtension(value);
  }
}
