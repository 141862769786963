import { Component, Input, OnInit } from '@angular/core';
import { AdminStatus } from '../../models/admin';
import { NotableStatus } from '../../models/notable';
import { UserStatus } from '../../models/user';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  @Input() color: AdminStatus | UserStatus | NotableStatus | string;

  constructor() {}

  ngOnInit(): void {}
}
