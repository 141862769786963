import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { firstValueFrom } from 'rxjs';
import { Admin } from '../models/admin';

@Injectable({
  providedIn: 'root',
})
export class AdminUserService extends CrudService<Admin> {
  constructor(http: HttpClient) {
    super(http, 'admins');
  }

  public async getDashboardStats() {
    return firstValueFrom(this.http.get<any>('admins/dashboard/stats'));
  }

  public async getMe() {
    return firstValueFrom(this.http.get<any>('admins/me'));
  }

  public async resendInvite(email: string) {
    return firstValueFrom(this.http.post<any>('admins/resend-invite', { email }));
  }
}
