import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TableUtils } from '../../utils/table.utils';

export enum SortColumnType {
  ALPHABETICAL = 'alphabetical',
  NUMERICAL = 'numerical',
  DATE = 'date',
}

@Component({
  selector: 'app-sort-by-column-name',
  templateUrl: './sort-by-column-name.component.html',
  styleUrls: ['./sort-by-column-name.component.scss'],
})
export class SortByColumnNameComponent implements OnChanges {
  @Input() queryParams: ParamMap;
  @Input() fragment: string | null;
  @Input() columnName: string;
  @Input() columnDisplayName: string;
  @Input() sortColumnTypeInput: SortColumnType;
  @Input('setQueryParams') setQueryParams: (queryParams: any) => Promise<void>;

  sortColumnType = SortColumnType;
  activeSortOrder = '';
  activeSortColumn = '';

  constructor(private router: Router, private route: ActivatedRoute) {
    // router and route are needed in setQueryParams method on DataTableComponent
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.activeSortOrder = changes.queryParams?.currentValue.get('sortOrder');
    this.activeSortColumn = changes.queryParams?.currentValue.get('column');
  }

  async sortByName(sortOrder: 'asc' | 'desc' | '') {
    if (this.activeSortOrder === sortOrder && this.columnName === this.activeSortColumn) {
      sortOrder = '';
    }
    const tableQueryParams = TableUtils.parseParams(this.queryParams);
    await this.setQueryParams({ ...tableQueryParams, column: this.columnName, sortOrder });
  }
}
