import { SafeResourceUrl } from '@angular/platform-browser';
import { AssetResponseDto } from '../services/assets.service';
import { AssetStatus, AssetType, GraphAssets } from './asset';
import { Request } from './request';
import { BaseUser } from './user';

export enum NotableStatus {
  approve = 'approve',
  active = 'active',
  inactive = 'inactive',
  paused = 'paused',
  disabled = 'disabled',
  away = 'away',
  pending = 'pending',
  blocked = 'blocked',
  reported = 'reported',
}

export interface NotableAsset {
  key?: string | ArrayBuffer | SafeResourceUrl;
  thumbnailKey?: string | ArrayBuffer | SafeResourceUrl;
  thumbnailUrl?: string;
  name?: string;
  type?: AssetType;
  _id?: string;
}

export interface NotableAssetDisplayed extends AssetResponseDto {
  status?: AssetStatus;
  createdAt?: Date;
}

export interface NotableAssetForUpdate {
  name: string;
}

export enum NotableAvailableForSigningStatus {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
}

export interface DeviceVerification {
  deviceSerialNumber?: string;
  verificationCode: string;
  expiresAt: Date;
  activatedAt?: Date;
}

export interface Notable extends BaseUser {
  status: NotableStatus;
  profession: string;
  phone: string;
  graphPrice: number; // in cents
  graphWithVoiceOverPrice?: number; // in cents
  brokerTake: number; // percentage
  email: string;
  emails?: string[]; // deprecated - it was in initial implementation
  bio: string;
  introVideoKey?: string;
  introVideoUrl?: string;
  soundtracksKeys?: NotableAsset[]; // only for adding
  soundtracks?: NotableAssetDisplayed[];
  backgroundVideosKeys?: NotableAsset[]; // only for adding
  backgroundVideos?: NotableAssetDisplayed[];
  brushes?: NotableAssetDisplayed[];
  asset?: NotableAsset; // only for adding and update
  assetsKeys?: NotableAsset[]; // only for adding
  deviceVerification?: DeviceVerification;
  maxGraphRequestsCount: number;
  availableForSigning: NotableAvailableForSigningStatus;
  graphs?: Request[];
  introVideoAssets?: GraphAssets;
  stats: { activeRequests: number; fulfilledRequests: number; declinedRequests: number };
}

export enum NotableProfession {
  actor = 'actor',
  artist = 'artist',
  singer = 'singer',
  producer = 'producer',
}
