import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { isValidPhoneNumber } from 'libphonenumber-js';

@Directive({
  selector: 'input[validPhoneNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidPhoneNumberDirective,
      multi: true,
    },
  ],
})
export class ValidPhoneNumberDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const phoneNumber = control.value;
    if (!phoneNumber) return null;

    return isValidPhoneNumber(phoneNumber, 'US')
      ? null
      : {
          invalidPhoneNumber: 'Phone number is invalid.',
        };
  }
}
