<article class="entry-page">
  <app-logo></app-logo>
  <section class="password-change">
    <div class="form-group">
      <p class="title">Set up new password</p>

      <div class="form-group">
        <label for="password">New password</label>
        <div class="password-box">
          <input
            [(ngModel)]="password"
            [type]="hidePassword ? 'password' : 'text'"
            id="password"
            name="password"
            autocomplete="password"
            (keyup.enter)="resetPassword()"
            placeholder="Enter your password"
          />
          <i class="icon eye-icon" (click)="hidePassword = !hidePassword">{{
            hidePassword ? 'visibility_off' : 'visibility'
          }}</i>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="samepassword">Confirm password</label>
      <div class="password-box">
        <input
          [(ngModel)]="samepassword"
          [type]="hideSamePassword ? 'password' : 'text'"
          id="samepassword"
          name="samepassword"
          autocomplete="samepassword"
          (keyup.enter)="resetPassword()"
          placeholder="Enter the same password"
        />
        <i class="icon eye-icon" (click)="hideSamePassword = !hideSamePassword">{{
          hideSamePassword ? 'visibility_off' : 'visibility'
        }}</i>
      </div>
    </div>
    <app-password-constraints></app-password-constraints>
    <button (click)="resetPassword()" [disabled]="!password || !samepassword">Set new password</button>

    <app-error [error]="error"></app-error>
  </section>
</article>
