import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SuccessScreenComponent } from './success-screen/success-screen.component';
import { JoinBusinessComponent } from './join-business/join-business.component';

@NgModule({
  declarations: [
    LoginComponent,
    SidenavComponent,
    ToolbarComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    SuccessScreenComponent,
    JoinBusinessComponent,
  ],
  imports: [CommonModule, SharedModule, RecaptchaV3Module],
  exports: [LoginComponent, SidenavComponent, ToolbarComponent],
})
export class CoreModule {}
