import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RouterUtils } from '../../shared/utils/router.utils';
import { AdminUserService } from 'src/app/shared/services/admin-user.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>();

  isMobile: boolean;
  pageName = 'Dashboard';
  allAvailablePages = 'overview dashboard assets orders';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public dialog: MatDialog,
    protected adminUserService: AdminUserService
  ) {}

  ngOnInit() {
    this.updatePageNameInHeader();

    this.router.events.subscribe(() => {
      this.updatePageNameInHeader();
    });
  }

  ngAfterViewInit(): void {
    this.breakpointObserver.observe(['(max-width: 1023px)']).subscribe((state: BreakpointState) => {
      setTimeout(() => {
        this.isMobile = state.matches;
      });
    });
  }

  updatePageNameInHeader() {
    this.pageName = RouterUtils.getLastSegmentNameOfUrl(this.router);
  }

  shouldDisplayPageName() {
    return this.allAvailablePages.includes(this.pageName.toLowerCase());
  }
}
