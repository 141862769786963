import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AssetStatus } from '../../models/asset';
import { NotableStatus } from '../../models/notable';
import { RequestStatus } from '../../models/request';
import { TableUtils } from '../../utils/table.utils';

export enum StatusEntity {
  NOTABLE = 'notable',
  ASSET = 'asset',
  REQUEST = 'request',
}

@Component({
  selector: 'app-sort-by-status',
  templateUrl: './sort-by-status.component.html',
  styleUrls: ['./sort-by-status.component.scss'],
})
export class SortByStatusComponent implements OnChanges {
  @Input() queryParams: ParamMap;
  @Input() fragment: string | null;
  @Input() columnName: string;
  @Input() columnDisplayName: string;
  @Input('setQueryParams') setQueryParams: (queryParams: any) => Promise<void>;
  @Input() statusEntityInput: StatusEntity;

  requestStatus = RequestStatus;
  assetStatus = AssetStatus;
  statusEntity = StatusEntity;

  activeSortOrder = '';
  activeSortColumn = '';
  activeStatus = '';

  constructor(private router: Router, private route: ActivatedRoute) {
    // router and route are needed in setQueryParams method on DataTableComponent
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.activeSortOrder = changes.queryParams?.currentValue.get('sortOrder');
    this.activeSortColumn = changes.queryParams?.currentValue.get('column');
    this.activeStatus = this.queryParams.get('status');
  }

  async sortOrderByStatus(sortOrder: 'asc' | 'desc' | '') {
    if (this.activeSortOrder === sortOrder) {
      sortOrder = '';
    }
    const tableQueryParams = TableUtils.parseParams(this.queryParams);
    await this.setQueryParams({ ...tableQueryParams, column: this.columnName, status: '', sortOrder });
  }

  async sortByStatusOnly(status: AssetStatus | NotableStatus | RequestStatus | '') {
    if (this.activeStatus === status) {
      status = '';
    }
    const tableQueryParams = TableUtils.parseParams(this.queryParams);
    await this.setQueryParams({
      ...tableQueryParams,
      column: this.columnName,
      sortOrder: '',
      status,
    });
  }
}
