import { BrushType } from './asset';
import { Graph } from './graph';
import { Notable } from './notable';

export enum Pronoun {
  HE_HIM = 'He/Him',
  SHE_HER = 'She/Her',
  THEY_THEM = 'They/Them',
}

export enum RequestStatus {
  PENDING = 'pending',
  DELIVERED = 'delivered',
  DECLINED = 'declined',
}

export enum RequestForType {
  SOMEONE_ELSE = 'Someone else',
  MYSELF = 'Myself',
}

export enum RequestPaymentStatus {
  PENDING = 'pending',
  PAID = 'paid',
  REFUNDED = 'refunded',
  PARTIALLY_REFUNDED = 'partially refunded',
}

export class RequestFor {
  type: RequestForType;
  name: string;
  pronoun: Pronoun;
}

export class RequestFrom {
  name: string;
  pronoun: Pronoun;
}

export class RequestAssets {
  backgroundId: string;
  soundtrackId: string;
  // textureId: string;
  brushType: BrushType;
}
export interface Request {
  _id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  userId: string;
  notableId: string;
  notable: Partial<Notable>;
  graphId?: string;
  instructions: string;
  isPrivate: boolean;
  for: RequestFor;
  from: RequestFrom;
  assets: RequestAssets;
  paymentStatus: RequestPaymentStatus;
  status: RequestStatus;
  price: number | string;
  graph?: Graph;
}
