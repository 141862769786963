import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static minDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null || control.value === '') {
        return null;
      }

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const startDate = new Date(control.value);

      if (startDate < today) {
        return { minDate: true };
      }
      return null;
    };
  }

  // static maxDateValidator(days: number = 90): any {
  //   return (group: FormGroup): ValidationErrors | null => {
  //     const startDateStr = group.controls.startDate.value;
  //     const endDateStr = group.controls.endDate.value;
  //     if (startDateStr == null || startDateStr === '' || endDateStr == null || endDateStr === '') {
  //       return null;
  //     }

  //     const startDate = new Date(startDateStr);
  //     const endDate = new Date(endDateStr);
  //     const diffDays = DateUtils.diff(startDate, endDate, 'days');

  //     if (diffDays > days) {
  //       group.controls.endDate.setErrors({
  //         maxEndDate: true,
  //       });
  //     }
  //     return null;
  //   };
  // }

  static isPasswordValid(password: string) {
    /*
        ^
          (?=.*[A-Z])                                    must contain at least one upper case
          (?=.*[a-z])                                    must contain at least one lower case
          (?=.*?[0-9])                                   must contain at least one digit
          (?=.*?[!@#\$&*~`)\%\-(_+=;:,.<>/?"'[{\]}\|^])  must contain at least one special character
          .{8,}                                          must be at least 8 characters long  
        $
      */

    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~`)\%\-(_+=;:,.<>/?"'[{\]}\|^]).{8,}$/;
    if (!regex.test(password)) {
      return false;
    }

    return true;
  }

  static isEmailValid(email: string) {
    /*
      SO answer: https://stackoverflow.com/a/9204568/11322237 
      Validates emails in form of anyString@anyString.anyString

      We keep a simple email validation just for the structure because
        - The only way to reliably verify that a supplied email is a working valid email is to send a mail with a verification link
      Source: https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript#comment119081127_46155
    */
    const regex = /\S+@\S+\.\S+/;
    if (!regex.test(email)) {
      return false;
    }

    return true;
  }
}
