import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AdminType, Admin } from '../models/admin';
import { Constants } from '../utils/constants';
import { RouterUtils } from '../utils/router.utils';
import { StringUtils } from '../utils/string.utils';
import { AuthService } from './auth.service';
import { ViewAsService } from './view-as.service';

@Injectable({
  providedIn: 'root',
})
export class TabService {
  tabEntity: string;
  user: Admin;

  constructor(private router: Router, private authService: AuthService, private viewAsService: ViewAsService) {
    this.user = this.authService.user;
    this.updateTabEntity();

    router.events.subscribe((s) => {
      this.user = this.authService.user;
      this.updateTabEntity();

      // if needed, uncomment to scroll into view of the href # in the page
      // if (s instanceof NavigationEnd) {
      //   const tree = router.parseUrl(router.url);
      //   if (tree.fragment) {
      //     const element = document.querySelector('#' + tree.fragment);
      //     if (element) {
      //       element.scrollIntoView(true);
      //     }
      //   }
      // }
    });

    viewAsService.dataChanged$().subscribe(() => {
      this.user = this.authService.user;
      this.updateTabEntity();
    });
  }

  private refreshActiveTabEntity() {
    if (!this.user) {
      this.tabEntity = '';
      return;
    }

    if (this.router.url.includes(Constants.profilePage)) {
      this.tabEntity = Constants.overviewTabs.notables;
      return;
    }

    if (this.user.type === AdminType.SUPERADMIN) {
      if (this.router.url.includes(Constants.overviewPage)) {
        this.tabEntity = RouterUtils.getUrlHash(this.router) || Constants.overviewTabs.concierges;
      } else {
        this.tabEntity = RouterUtils.getUrlHash(this.router) || Constants.assetsTabs.backgrounds;
      }
      return;
    }

    if (this.router.url.includes(Constants.overviewPage)) {
      this.tabEntity = RouterUtils.getUrlHash(this.router) || Constants.overviewTabs.notables;
    } else {
      this.tabEntity = RouterUtils.getUrlHash(this.router) || Constants.assetsTabs.backgrounds;
    }
  }

  private removePluralOfTabEntity() {
    this.tabEntity = StringUtils.removePlural(this.tabEntity);
  }

  private updateTabEntity() {
    this.refreshActiveTabEntity();
    this.removePluralOfTabEntity();
  }
}
