import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnChanges {
  @Input() queryParams: string; // Optional - include chips with query params
  @Input() includeSearch = true; // Optional - include search bar or not
  @Input() displayedColumns: string[]; // Optional - filter table columns
  @Input() filtersExpanded = false; // Optional - expand filters on initial page load
  @Input() searchPlaceholder: string;

  @Output() displayedColumnsChange = new EventEmitter<string[]>();

  tableColumns: { name: string; checked: boolean }[];

  constructor() {}

  ngOnInit(): void {
    if (this.displayedColumns) {
      this.tableColumns = this.displayedColumns.map((c) => ({ name: c, checked: true }));
    }
  }

  ngOnChanges() {}

  onColumnsChanged() {
    this.displayedColumnsChange.emit(this.tableColumns.filter((c) => c.checked).map((c) => c.name));
  }
}
