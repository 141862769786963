import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { StringUtils } from '../../utils/string.utils';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnInit {
  @Input() startField = 'startDate';
  @Input() endField = 'endDate';
  @Input() placeholder = 'Filter by date range';

  @Input() min: Date;
  @Input() max: Date;
  @Input() dateClass: MatCalendarCellClassFunction<Date>;

  prevStart: Date;
  start: Date;
  end: Date;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParamMap.pipe(take(1)).subscribe((params) => {
      const startStr = params.get(this.startField);
      const endStr = params.get(this.endField);

      const dayjsStart = dayjs(startStr);
      const dayjsEnd = dayjs(endStr);

      if (dayjsStart.isValid()) {
        this.start = dayjsStart.toDate();
        // assigning value to prevStart here prevents request when only start date is selected after page load
        this.prevStart = this.start;
      }
      if (dayjsEnd.isValid()) {
        this.end = dayjsEnd.toDate();
        const dateEnd = new Date(this.end.toISOString());
        this.end = new Date(dateEnd.getTime() + dateEnd.getTimezoneOffset() * 60 * 1000);
      }
    });
  }

  dateChanged() {
    if (this.start && !this.end) {
      const isoDateStart = new Date(this.start.toISOString());
      if (this.prevStart && this.start.getDate() === this.prevStart.getDate()) {
        this.start = isoDateStart;
      } else {
        this.start = StringUtils.convertUTCDateToLocalDate(isoDateStart);
      }
      this.prevStart = this.start;
    }
    if (
      !this.start ||
      !this.end ||
      this.start.getDate() > this.end.getDate() ||
      (this.prevStart && this.prevStart.getDate() > this.start.getDate())
    ) {
      return;
    }
    if (this.end) {
      const dateEnd = new Date(this.end.toISOString());
      this.end = StringUtils.convertUTCDateToLocalDate(dateEnd);
      let dayjsEnd = dayjs(this.end);
      dayjsEnd = dayjsEnd.add(23, 'hours');
      dayjsEnd = dayjsEnd.add(59, 'minutes');
      dayjsEnd = dayjsEnd.add(59, 'seconds');
      this.end = dayjsEnd.toDate();
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        [this.startField]: this.start.toISOString(),
        [this.endField]: this.end.toISOString(),
        ['start']: 0,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
    // for correct display of date picker
    const dateEnd = new Date(this.end.toISOString());
    this.end = StringUtils.convertLocalDateToUTCDate(dateEnd);
  }

  clear() {
    this.start = null;
    this.end = null;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        [this.startField]: null,
        [this.endField]: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }
}
