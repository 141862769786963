<form #editSoundtracksCategoriesForm="ngForm" class="edit-soundtracks-categories-dialog">
  <div>
    <div class="header-row">
      <div class="grid-center-column-items justify-start front">
        <button *ngIf="!data.showCloseIcon" class="cursor-pointer">
          <img src="/assets/images/icons/arrow-back.svg" matTooltip="Back" (click)="closeDialog(false)" />
        </button>
        <p>Edit categories</p>
      </div>
      <button *ngIf="data.showCloseIcon" class="cursor-pointer">
        <img src="/assets/images/icons/close.svg" matTooltip="Close" (click)="closeDialog(false)" />
      </button>
    </div>

    <div class="dropdown-selection-wrapper" [appLoading]="isLoading">
      <div class="dropdown-selection">
        <div class="grid-center-column-items justify-start">
          <button (click)="prepareAddCategory()">
            <div class="grid-center-column-items justify-start frame">
              <img src="/assets/images/icons/add.svg" matTooltip="Add category" />
              <span class="text-add">Add category</span>
            </div>
          </button>
        </div>
        <div *ngIf="isAddingCategory" class="grid-center-column-items justify-start dropdown-selection add-category">
          <input
            placeholder="Enter category name..."
            #categoryInput
            (keyup.enter)="savePrepareAddCategory()"
            class="rename-category"
          />
          <div *ngIf="shouldDisplayActions" class="grid-center-column-items justify-start actions-add">
            <button (click)="savePrepareAddCategory()" [disabled]="!categoryInput?.value?.length">
              <span>Save</span>
            </button>
            <button (click)="cancelPrepareAddCategory()"><span>Cancel</span></button>
          </div>
        </div>
      </div>
      <section
        class="order-cards"
        [class.is-disabled-grab]="isDisabledDrag"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
        (drag)="showDisabledDragMessageOnPendingSave($event)"
        (click)="showDisabledDragMessageOnPendingSave($event)"
      >
        <ng-container *ngFor="let category of displayedCategories; index as i">
          <ng-container
            *ngIf="{
              type: assetType.soundtrackCategory,
              name: category.name
            } as variableAsset"
          >
            <div
              class="grid-center-column-items frame"
              cdkDrag
              appDragCursor
              [cdkDragDisabled]="isDisabledDrag || !!pendingSaveCategories.length"
            >
              <div class="drag-placeholder" *cdkDragPlaceholder></div>
              <app-asset
                [isOnModal]="true"
                [givenAsset]="variableAsset"
                [isOnModal]="true"
                [isDisabledDragInput]="isDisabledDrag"
                (saveRenameChange)="saveRenameCategory($event, i)"
                (removeItemChange)="removeCategory($event, i)"
                (isInputFocusedEmit)="isDisabledDrag = $event"
              ></app-asset>
            </div>
          </ng-container>
        </ng-container>
      </section>
    </div>
  </div>

  <div class="actions">
    <button
      (click)="saveChanges()"
      [appLoading]="isLoading"
      [disabled]="
        !editSoundtracksCategoriesForm.invalid &&
        !pendingSaveCategories.length &&
        !deletedCategoriesIds.length &&
        !pendingUpdateCategories.length &&
        !hasChangedOrder
      "
    >
      <span>Save changes</span>
    </button>
  </div>
</form>
