import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewAsService {
  private dataChangedSubject: Subject<boolean> = new Subject<boolean>();

  shouldShowBanner: boolean;
  userFullName: string;

  public dataChanged$(): Observable<boolean> {
    return this.dataChangedSubject.asObservable();
  }

  updateVisibilityBanner(visiblity: boolean) {
    this.shouldShowBanner = visiblity;
    this.dataChangedSubject.next(visiblity);
  }

  updateUserFullName(userFullName: string) {
    this.userFullName = userFullName;
    this.dataChangedSubject.next(true);
  }
}
