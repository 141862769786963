<section class="table-wrapper column-style-only-box-shadow" [appLoading]="isLoading">
  <div
    [class]="selection?.selected?.length ? 'search-and-table-selection' : 'search-and-table-selection'"
    *ngIf="selection"
  >
    <div
      [class.visible]="selection?.selected?.length"
      [class]="
        selection?.selected?.length
          ? 'table-selection selection-wrapper'
          : 'table-selection selection-wrapper hide-element-display'
      "
    >
      <app-item-selection-text [count]="selection?.selected?.length"></app-item-selection-text>
      <section class="selection-wrapper" *ngIf="selection?.selected?.length === 1 && selectedId && selectedItem">
        <button (click)="addOrEditNotableProfile('edit', selectedItem)">
          <span>Edit</span>
        </button>
        <button
          *ngIf="selectedItem.status !== userStatuses.paused"
          (click)="changeNotableStatus(selectedId, userStatuses.paused)"
        >
          <span>Pause</span>
        </button>
        <button
          *ngIf="selectedItem.status !== userStatuses.disabled"
          (click)="changeNotableStatus(selectedId, userStatuses.disabled)"
        >
          <span>Disable</span>
        </button>
        <button
          *ngIf="selectedItem.status !== userStatuses.active"
          (click)="changeNotableStatus(selectedId, userStatuses.active)"
        >
          <span>Activate</span>
        </button>
      </section>

      <section class="selection-wrapper" *ngIf="selection?.selected?.length > 1">
        <button (click)="bulkChangeNotableStatus(userStatuses.paused)">
          <span>Pause</span>
        </button>
        <button (click)="bulkChangeNotableStatus(userStatuses.disabled)">
          <span>Disable</span>
        </button>
        <button (click)="bulkChangeNotableStatus(userStatuses.active)">
          <span>Activate</span>
        </button>
      </section>

      <!-- <div class="actions">
        <button mat-icon-button matTooltip="Clear selection" (click)="clearSelection()">
          <i class="icon">close</i>
        </button>
      </div> -->
    </div>
    <div class="flex-row-wrapper w100 search-bar-add-user-wrapper">
      <app-filters queryParams="status" [(displayedColumns)]="displayedColumns"> </app-filters>
      <div class="header-tools" *ngIf="shouldShowAddButton">
        <button mat-flat-button class="add-button" color="primary" (click)="addOrEditNotableProfile('add')">
          Add notable
        </button>
      </div>
    </div>
  </div>

  <!-- <section class="table-tab-button-wrapper">
    <ng-container *ngFor="let tab of tabsTypeAll">
      <button
        [class]="activeTab === tab ? 'table-tab-button active' : 'table-tab-button inactive'"
        (click)="changeActiveTab(tab)"
      >
        {{ tab }}
      </button>
    </ng-container>
  </section> -->

  <div class="table-container">
    <table
      mat-table
      matSort
      [dataSource]="dataTable || data"
      [matSortActive]="activeSort"
      [matSortDirection]="activeSortDirection"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle($event) : null" #masterToggleCheckbox></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" (click)="handleClickSelect($event, row._id)">
          <mat-checkbox (change)="$event ? toggleCheckbox(row._id) : null" [checked]="selection.isSelected(row._id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>

        <td
          mat-cell
          *matCellDef="let row; let i = index"
          [routerLink]="['/admin/overview', 'notable', row._id]"
          class="cursor-pointer"
        >
          <div class="user-image">
            <app-avatar
              width="24"
              height="24"
              [type]="'circle'"
              [initialsFontSize]="'12px'"
              [src]="row.profilePictureUrl"
              [userFullName]="row.fullName || row.firstName.concat(' ').concat(row.lastName)"
            ></app-avatar>
            <p class="name-display">{{ row.firstName }} {{ row.lastName }}</p>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let row; let i = index">
          <app-badge [color]="row.status">{{ row.status | textHumanize }}</app-badge>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastActivity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last seen</th>
        <td mat-cell *matCellDef="let row">{{ row.lastActivity | timeDisplay }}</td>
      </ng-container>

      <ng-container matColumnDef="graphPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Graph price</th>
        <ng-container>
          <td mat-cell *matCellDef="let row" class="cell-right">{{ row.graphPrice | moneyTransform }}</td>
        </ng-container>
      </ng-container>
      <ng-container matColumnDef="brokerTake">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ isSuperadmin ? "Broker's take" : 'Profit take' }}</th>
        <ng-container *matCellDef="let row">
          <ng-container *ngIf="row.brokerTake">
            <td mat-cell class="cell-right">{{ row.brokerTake }} %</td>
          </ng-container>
          <ng-container *ngIf="!row.brokerTake">
            <td mat-cell class="cell-right">{{ take }} %</td>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- <ng-container matColumnDef="earnings">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total earned</th>
        <td mat-cell *matCellDef="let row" class="cell-right">{{ row.earnings | moneyTransform }}</td>
      </ng-container>
      <ng-container matColumnDef="potentialEarnings">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Potential</th>
        <td mat-cell *matCellDef="let row" class="cell-right">{{ row.potentialEarnings | moneyTransform }}</td>
      </ng-container> -->

      <!-- <ng-container matColumnDef="allRequests">
        <th mat-header-cell *matHeaderCellDef>Requests</th>
        <td mat-cell *matCellDef="let row">24</td>
      </ng-container>
      <ng-container matColumnDef="unfulfilled">
        <th mat-header-cell *matHeaderCellDef>Fulfilled</th>
        <td mat-cell *matCellDef="let row">16</td>
      </ng-container>
      <ng-container matColumnDef="sold">
        <th mat-header-cell *matHeaderCellDef>Expired</th>
        <td mat-cell *matCellDef="let row">6</td>
      </ng-container>
      <ng-container matColumnDef="declined">
        <th mat-header-cell *matHeaderCellDef>Declined</th>
        <td mat-cell *matCellDef="let row">2</td>
      </ng-container> -->

      <!-- <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>Notes</th>
        <td mat-cell *matCellDef="let row">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean scelerisque sem...
        </td>
      </ng-container>
      <ng-container matColumnDef="socialLinks">
        <th mat-header-cell *matHeaderCellDef class="social-links">
          <p>Social links</p>
          <i class="icon">add</i>
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="flex-row-wrapper w100 social-icons">
            <img src="/assets/images/icons/twitter.svg" />
            <img src="/assets/images/icons/instagram.svg" />
          </div>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
      <tr mat-row *matRowDef="let row; columns: getDisplayedColumns(); let i = index"></tr>
      <tr class="mat-row empty-table-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="getDisplayedColumns().length">No data available in table</td>
      </tr>
    </table>
  </div>

  <mat-paginator
    [length]="dataLength"
    [pageIndex]="activePage"
    [pageSize]="activeLength"
    [pageSizeOptions]="pageSizeOptions"
    *ngIf="showDefaultPaginator"
  >
  </mat-paginator>

  <mat-paginator
    [length]="dataLengthPaginator"
    [pageIndex]="activePagePaginator"
    [pageSize]="activeLengthPaginator"
    [pageSizeOptions]="pageSizeOptionsPaginator"
    *ngIf="!showDefaultPaginator"
  >
  </mat-paginator>
</section>
