import { Pipe, PipeTransform } from '@angular/core';
import { TableUtils } from '../utils/table.utils';

@Pipe({
  name: 'arraySum',
})
export class ArraySumPipe implements PipeTransform {
  transform(value: number[][] | number[], ...args: any[]): number | null {
    if (!value) return null;

    if (typeof value === 'number') {
      return value;
    }

    let totalSum = 0;
    if (value[0] instanceof Array) {
      value.map((item) => {
        totalSum += TableUtils.arraySum(item);
      });
    } else {
      if (Array.isArray(value)) {
        totalSum += TableUtils.arraySum(value as number[]);
      } else {
        totalSum += value;
      }
    }
    return totalSum;
  }
}
