<div class="mat-sort-header-container">
  <p>{{ columnDisplayName }}</p>
  <button class="filter-table-row-arrow" [matMenuTriggerFor]="nameSort">
    <i></i>
  </button>
  <mat-menu #nameSort="matMenu" xPosition="before" class="menu-wrapper">
    <button *ngIf="soundtrackCategoriesInput.length" mat-menu-item [matMenuTriggerFor]="categories">
      <span>Show only</span>
    </button>
    <button mat-menu-item (click)="openEditCategoriesDialog()">
      <span>Edit categories</span>
    </button>
  </mat-menu>
  <mat-menu #categories="matMenu" xPosition="after" class="menu-wrapper category-menu-wrapper">
    <ng-container *ngFor="let category of soundtrackCategoriesInput; index as i">
      <button mat-menu-item (click)="sortByCategoryOnly(category._id)">
        <span class="text-capitalize">{{ category.name }}</span>
        <i *ngIf="activeCategoryId === category._id" class="icon">check</i>
      </button>
    </ng-container>
  </mat-menu>
</div>
