import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreateUserProfileDialog } from 'src/app/admin/overview/overview-notable/add-or-edit-notable/add-or-edit-notable.component';

@Component({
  selector: 'app-success-screen',
  templateUrl: './success-screen.component.html',
  styleUrls: ['./success-screen.component.scss'],
})
export class SuccessScreenComponent implements OnInit {
  notableId: string;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<SuccessScreenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateUserProfileDialog
  ) {
    if (data && data.notableId) {
      this.notableId = data.notableId;
    }
  }

  ngOnInit(): void {
    if (!this.notableId || this.data?.hasCreatedAdminAccount) {
      setTimeout(() => {
        this.router.navigate(['/login']);
        this.dialogRef.close();
      }, 3000);
      return;
    }
    setTimeout(() => {
      this.router.navigate([`admin/overview/notable/${this.notableId}`]);
      this.dialogRef.close();
    }, 3000);
  }
}
