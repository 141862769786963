<div class="constraints">
  <p>Must contain at least:</p>
  <ul>
    <li>8 characters</li>
    <li>1 number</li>
    <li>1 lowercase letter</li>
    <li>1 uppercase letter</li>
    <li>1 special character</li>
  </ul>
</div>
