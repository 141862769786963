import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject, Observable, concat, of, merge, firstValueFrom } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map, distinctUntilChanged, startWith, debounceTime, tap, switchMap, catchError } from 'rxjs/operators';
import { AutocompleteService } from '../../services/autocomplete.service';
import { AbstractInputComponent } from '../abstract-input.component';
import { SoundtrackCategoryService } from '../../services/soundtrack-category.service';

export enum HttpResultsScope {
  NOTABLES = 'notables',
  SOUNDTRACKS = 'soundtracks',
}

@UntilDestroy()
@Component({
  selector: 'app-select-http-input',
  templateUrl: './select-http-input.component.html',
  styleUrls: ['./select-http-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectHttpInputComponent),
      multi: true,
    },
  ],
})
export class SelectHttpInputComponent extends AbstractInputComponent implements OnInit {
  @ViewChild(NgSelectComponent, { static: false }) select: NgSelectComponent;

  @Input() url: string;
  @Input() labelField: string;
  @Input() valueField: string;
  @Input() imageField: string;
  @Input() additionalFields: string; // This fields will be passed to the "summary" query param
  @Input() queryParams: { [key: string]: string } = {};
  @Input() maxSuggestions: number;
  @Input() labelFieldFunction: (item: any) => string;
  @Input() httpResultsScopeInput: HttpResultsScope;
  @Input() showCloseIconInput = true;
  @Input() typeToSearchTextInput = 'Type to search';
  @Output() emitSelectedSoundtrackCategoryId: EventEmitter<string> = new EventEmitter();

  isAfterFirstOpen = false;
  isOpenedSelect = false;
  httpResultsScope = HttpResultsScope;
  isLoading = false;

  page = 0;
  lastPage = false;

  input: string;
  input$ = new Subject<string>();
  searchValueFromInput: string;

  items: any[] = [];
  items$: Observable<any>;

  itemsSubject = new Subject();

  constructor(
    private autocompleteService: AutocompleteService,
    private soundtrackCategoryService: SoundtrackCategoryService
  ) {
    super();
  }

  ngOnInit() {}

  async onSearchValueInputChange(searchValueInput: string) {
    this.searchValueFromInput = searchValueInput;
    await this.valueChanged();
  }

  async valueChanged() {
    if (!this.componentInit) return;

    if (this.value) {
      this.items$ = concat(
        of([]),
        merge(
          this.itemsSubject.asObservable(),
          this.input$.pipe(
            // startWith(''),
            distinctUntilChanged(),
            debounceTime(400),
            tap((input: string) => this.onInput(input)),
            switchMap(() => this.search())
          )
        )
      ).pipe(tap((items: any) => (this.items = items)));
      // return this.getValueById();
    }
    this.items$ = concat(
      of([]),
      merge(
        this.itemsSubject.asObservable(),
        this.input$.pipe(
          startWith(''),
          distinctUntilChanged(),
          debounceTime(400),
          tap((input: string) => this.onInput(input)),
          switchMap(() => this.search())
        )
      )
    ).pipe(tap((items: any) => (this.items = items)));
  }

  onInput(input: string) {
    this.isLoading = true;
    this.input = input || this.searchValueFromInput;
    this.page = 0;
    this.lastPage = false;
  }

  async getValueById() {
    const v = await this.autocompleteService.findByIds(this.url, this.value).toPromise();
    const value = (v || [])[0] || {};
    this.itemsSubject.next([
      {
        label: value[this.labelField] || this.labelFieldFunction(value),
        value: value[this.valueField],
        ...(this.imageField && { image: value[this.imageField] }),
      },
    ]);
    return value;
  }

  private search() {
    const filterValue = (this.input || '').toLowerCase();
    return this.autocompleteService
      .search({
        url: this.url,
        search: filterValue,
        valueField: this.valueField,
        labelField: this.labelField,
        queryParams: { ...this.queryParams, start: this.page.toString() },
        maxSuggestions: this.maxSuggestions,
        imageField: this.imageField,
        additionalFields: this.additionalFields,
      })
      .pipe(
        map((val) => {
          return val.map((item) => ({
            ...item,
            label: this.labelField ? item[this.labelField] : this.labelFieldFunction(item),
            value: item[this.valueField],
            ...(this.imageField && { image: item[this.imageField] }),
          }));
        }),
        catchError(() => of([])),
        tap(() => (this.isLoading = false))
      );
  }

  async onScrollToEnd() {
    if (this.lastPage) return;
    this.page++;
    try {
      this.isLoading = true;
      const newPage = await firstValueFrom(this.search());
      if (newPage.length > 0) {
        this.itemsSubject.next([...this.items, ...newPage]);
      } else {
        this.lastPage = true;
      }
    } finally {
      this.isLoading = false;
    }
  }

  onClickSelect() {
    if (this.isAfterFirstOpen) {
      this.isOpenedSelect = !this.isOpenedSelect;
      return;
    }
    this.isOpenedSelect = true;
    this.isAfterFirstOpen = true;
  }

  async onFocusSelect() {
    this.isOpenedSelect = true;
    if (this.soundtrackCategoryService.hasSavedChanges) {
      this.soundtrackCategoryService.hasSavedChanges = false;
      await this.valueChanged();
    }
  }

  onBlurSelect() {
    if (this.httpResultsScopeInput !== this.httpResultsScope.NOTABLES) {
      this.isOpenedSelect = false;
    }
    this.isAfterFirstOpen = false;
  }

  onChangeSelect() {
    this.isOpenedSelect = false;
  }

  onClearSelect() {
    this.emitSelectedSoundtrackCategoryId.emit('');
    this.isOpenedSelect = true;
  }

  clickOption(selectedIndex: string) {
    this.emitSelectedSoundtrackCategoryId.emit(this.items[selectedIndex]._id);
  }
}
