import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { FileUtils } from '../../utils/file.utils';
import { AcceptedMimeTypes, FileDetails } from '../upload/upload.component';
import { DomSanitizer } from '@angular/platform-browser';
import { NotableAsset } from '../../models/notable';
import { AssetType, BackgroundTextPosition } from '../../models/asset';
import { FileService } from '../../services/file.service';
import { AssetResponseDto } from '../../services/assets.service';

@Component({
  selector: 'app-upload-asset',
  templateUrl: './upload-asset.component.html',
  styleUrls: ['./upload-asset.component.scss'],
})
export class UploadAssetComponent implements OnInit {
  @Input() isNotableSelected = true;
  @Input() isGeneralTab = false;
  @Input() assetType: AssetType = AssetType.audio;
  @Input() isAddingNotable = false;
  @Output() changeFiles: EventEmitter<FileDetails[]> = new EventEmitter();

  isLoading = false;
  fileDetails: FileDetails;
  multipleFilesDetails: FileDetails[] = [];
  fileName: string;
  pendingUploadFiles: AssetResponseDto[] = [];
  acceptedFiles: AcceptedMimeTypes;
  fileCategoryMessage: string;
  iconDisplay: string;
  allAssetType = AssetType;

  constructor(private toastService: ToastService, private sanitizer: DomSanitizer, private fileService: FileService) {}

  ngOnInit(): void {
    switch (this.assetType) {
      case AssetType.audio:
        this.fileCategoryMessage = 'soundtracks';
        this.iconDisplay = 'music';
        this.acceptedFiles = AcceptedMimeTypes.audioAccepted;
        break;
      case AssetType.video:
        this.fileCategoryMessage = 'video files';
        this.iconDisplay = 'video';
        this.acceptedFiles = AcceptedMimeTypes.videoAccepted;
        break;
      case AssetType.brush:
        this.fileCategoryMessage = 'brushes';
        this.iconDisplay = 'pen';
        this.acceptedFiles = AcceptedMimeTypes.videoAccepted;
        break;

      default:
        break;
    }
  }

  async onMultipleFilesSelected(events: File[]) {
    if (!events) return;

    for (const event of events) {
      if (this.assetType === AssetType.audio && !FileUtils.isAudio(event.type)) {
        continue;
      }
      if (
        (this.assetType === AssetType.video || this.assetType === AssetType.brush) &&
        !FileUtils.isVideo(event.type)
      ) {
        continue;
      }

      if (this.multipleFilesDetails.length && this.isFileAlreadyPendingUpload(event.name)) {
        if (this.assetType === AssetType.audio) {
          this.toastService.show(`Soundtrack ${event.name} is already in the list`);
          continue;
        }
        if (this.assetType === AssetType.video) {
          this.toastService.show(`Video ${event.name} is already in the list`);
          continue;
        }
        if (this.assetType === AssetType.brush) {
          this.toastService.show(`Brush ${event.name} is already in the list`);
          continue;
        }
      }

      if (this.assetType === AssetType.video || this.assetType === AssetType.brush) {
        const isVideoResolutionValid = await this.fileService.isVideoResolutionValid(event);
        if (isVideoResolutionValid) {
          this.putFileEventDetails(event);
        }
      } else {
        this.putFileEventDetails(event);
      }
    }
    this.emitChanges();
  }

  startLoading() {
    this.isLoading = true;
  }

  stopLoading() {
    this.isLoading = false;
  }

  putFileEventDetails(file: File) {
    this.fileDetails = {
      file: file,
      filename: file.name,
      size: file.size,
      extension: /(?:\.([^.]+))?$/.exec(file.name)[0],
      type: file.type,
      dataUrl: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)),
      backgroundTextPosition: BackgroundTextPosition.LEFT,
    };
    this.multipleFilesDetails.push({
      ...this.fileDetails,
    });
    this.pendingUploadFiles.push({
      name: this.fileDetails.filename,
      key: this.fileDetails.dataUrl as any,
      type: this.assetType,
    });
  }

  private isFileAlreadyPendingUpload(filename: string) {
    return this.multipleFilesDetails.findIndex((item) => item.filename === filename) !== -1;
  }

  private emitChanges() {
    this.changeFiles.emit(this.multipleFilesDetails);
  }

  onRemove(event, index: number) {
    this.multipleFilesDetails.splice(index, 1);
    this.pendingUploadFiles.splice(index, 1);
    this.emitChanges();
  }

  onSaveRename(newName: string, index: number) {
    this.multipleFilesDetails[index].filename = newName;
    this.emitChanges();
  }

  onBackgroundPositionChange(backgroundTextPosition: BackgroundTextPosition, index: number) {
    this.multipleFilesDetails[index].backgroundTextPosition = backgroundTextPosition;
    this.emitChanges();
  }
}
