import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Notable } from '../models/notable';
import { CrudService } from './crud.service';
import { firstValueFrom } from 'rxjs';
import { Constants } from '../utils/constants';

@Injectable({
  providedIn: 'root',
})
export class NotableService extends CrudService<Notable> {
  constructor(http: HttpClient) {
    super(http, 'notables');
  }

  async getOne(id: string): Promise<Notable> {
    const response = await firstValueFrom(this.http.get<Notable>(`${this.endpoint}/${id}`));
    if (response?.introVideoAssets?.brushType) {
      response.introVideoAssets.brush = Constants.brushes.find((b) => b.name === response.introVideoAssets.brushType);
    }
    return response;
  }

  updateDeviceVerification(notableId: string, data: { deviceSerialNumber?: string }) {
    return firstValueFrom(
      this.http.put<Notable['deviceVerification']>(`${this.endpoint}/${notableId}/device-verification`, data)
    );
  }

  deleteDeviceVerification(notableId: string) {
    return firstValueFrom(
      this.http.delete<Notable['deviceVerification']>(`${this.endpoint}/${notableId}/device-verification`)
    );
  }
}
