import { AssetType, BrushType } from '../models/asset';

export type BrushDefinition = {
  name: BrushType;
  thumbnailUrl: string;
  type: AssetType.brushDefinition;
};

export class Constants {
  static appName = 'Neon Mango Dashboard';

  static profilePage = 'profile';

  static overviewPage = 'overview';

  static assetsPage = 'assets';

  static overviewTabs = {
    concierges: 'concierges',
    brokers: 'brokers',
    notables: 'notables',
    customers: 'customers',
  };

  static assetsTabs = {
    backgrounds: 'backgrounds',
    soundtracks: 'soundtracks',
    brushes: 'brushes', // not used
  };

  static brushes: BrushDefinition[] = [
    // {
    //   name: BrushType.WHITE,
    //   thumbnailUrl: 'images/brushes/white.png',
    //   type: AssetType.brushDefinition,
    // },
    {
      name: BrushType.RUBIN,
      thumbnailUrl: 'images/brushes/rubin.png',
      type: AssetType.brushDefinition,
    },
    {
      name: BrushType.GOLD,
      thumbnailUrl: 'images/brushes/gold.png',
      type: AssetType.brushDefinition,
    },
    {
      name: BrushType.SILVER,
      thumbnailUrl: 'images/brushes/silver.png',
      type: AssetType.brushDefinition,
    },
    {
      name: BrushType.ROYAL_BLUE,
      thumbnailUrl: 'images/brushes/royal_blue.png',
      type: AssetType.brushDefinition,
    },
    {
      name: BrushType.EMERALD,
      thumbnailUrl: 'images/brushes/emerald.png',
      type: AssetType.brushDefinition,
    },
  ];
}
