import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssetType } from '../../models/asset';
import {
  SoundtrackAssetsCategoryEdit,
  SoundtrackAssetsCategoryUpdate,
  SoundtrackCategoryService,
} from '../../services/soundtrack-category.service';
import { ToastService } from '../../services/toast.service';
import { EditSoundtracksCategoriesDialogData } from '../select-soundtrack-category-header/select-soundtrack-category-header.component';

@Component({
  selector: 'app-edit-soundtracks-categories-dialog',
  templateUrl: './edit-soundtracks-categories-dialog.component.html',
  styleUrls: ['./edit-soundtracks-categories-dialog.component.scss'],
})
export class EditSoundtracksCategoriesDialogComponent implements OnInit {
  @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;

  isLoading = false;
  isAddingCategory = false;
  shouldDisplayActions = false;
  assetType = AssetType;
  displayedCategories: SoundtrackAssetsCategoryEdit[] = [];
  pendingSaveCategories: string[] = [];
  pendingUpdateCategories: SoundtrackAssetsCategoryUpdate[] = [];
  deletedCategoriesIds: string[] = [];
  hasChangedOrder = false;
  isDisabledDrag = false;

  constructor(
    public soundtrackCategoryService: SoundtrackCategoryService,
    public dialogRef: MatDialogRef<EditSoundtracksCategoriesDialogComponent>,
    private toastService: ToastService,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: EditSoundtracksCategoriesDialogData
  ) {
    this.displayedCategories = [...data.soundtrackCategories];
  }

  ngOnInit(): void {}

  closeDialog(hasSavedChanges: boolean) {
    this.soundtrackCategoryService.hasSavedChanges = hasSavedChanges;
    this.dialogRef.close();
  }

  prepareAddCategory() {
    this.isAddingCategory = true;
    this.shouldDisplayActions = true;
  }

  cancelPrepareAddCategory() {
    this.isAddingCategory = false;
  }

  savePrepareAddCategory() {
    this.pendingSaveCategories = [this.categoryInput.nativeElement.value, ...this.pendingSaveCategories];
    const newCategory: SoundtrackAssetsCategoryEdit = {
      name: this.categoryInput.nativeElement.value,
      isPendingAdd: true,
    };
    this.displayedCategories = [newCategory, ...this.displayedCategories];
    this.shouldDisplayActions = false;
    this.isAddingCategory = false;
  }

  saveRenameCategory(newName: string, index: number) {
    if (this.displayedCategories[index].isPendingAdd) {
      this.pendingSaveCategories[index] = newName;
      this.displayedCategories[index].name = newName;
      return;
    }
    this.pendingUpdateCategories.push({
      id: this.displayedCategories[index]._id,
      name: newName,
    });
  }

  removeCategory(event, index: number) {
    if (this.displayedCategories[index].isPendingAdd) {
      this.pendingSaveCategories.splice(index, 1);
      this.displayedCategories.splice(index, 1);
      return;
    }
    this.deletedCategoriesIds.push(this.displayedCategories[index]._id);
    this.displayedCategories.splice(index, 1);
  }

  showDisabledDragMessageOnPendingSave(event) {
    if (!this.pendingSaveCategories.length) return;
    this.toastService.show('Please save your changes before ordering categories!');
  }

  async saveChanges() {
    try {
      this.isLoading = true;
      if (this.pendingSaveCategories.length) {
        await this.soundtrackCategoryService.addOne({ categories: this.pendingSaveCategories });
      }
      if (this.deletedCategoriesIds.length) {
        await this.soundtrackCategoryService.deleteMany(this.deletedCategoriesIds);
      }
      if (this.pendingUpdateCategories.length) {
        await this.soundtrackCategoryService.updateManyData(this.pendingUpdateCategories);
      }
      if (this.hasChangedOrder) {
        const newOrder = this.displayedCategories.map((item, index) => ({ id: item._id, order: index }));
        await this.soundtrackCategoryService.updateManyData(newOrder);
      }
      this.toastService.showSuccess('Soundtracks categories updated');
      this.closeDialog(true);
    } catch (error) {
      this.toastService.showError(error);
    } finally {
      this.isLoading = false;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedCategories, event.previousIndex, event.currentIndex);
    this.hasChangedOrder = true;
  }
}
