import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: 'input[greaterThanZero]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: GreaterThanZeroDirective,
      multi: true,
    },
  ],
})
export class GreaterThanZeroDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    return parseInt(control.value.toString()) <= 0
      ? {
          notGreaterThanZero: 'Value must be greater than zero',
        }
      : null;
  }
}
