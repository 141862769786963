import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminTypeAll, AdminType, Admin } from '../../models/admin';
import { UploadScope } from '../../models/upload';
import { AdminUserService } from '../../services/admin-user.service';
import { AuthService } from '../../services/auth.service';
import { TabService } from '../../services/tab.service';
import { ToastService } from '../../services/toast.service';
import { UploadService } from '../../services/upload.service';
import { AcceptedMimeTypes, FileDetails } from '../upload/upload.component';

export interface AddAdminDialogData extends Partial<Admin> {}

@Component({
  selector: 'app-add-admin-dialog',
  templateUrl: './add-admin-dialog.component.html',
  styleUrls: ['./add-admin-dialog.component.scss'],
})
export class AddAdminDialogComponent {
  error: any;
  adminTypes = AdminTypeAll.map((item) => ({ label: item, value: item }));
  fileDetails: FileDetails;
  filename: string;
  isLoading: boolean;
  acceptedMimeTypes = AcceptedMimeTypes;

  constructor(
    public dialogRef: MatDialogRef<AddAdminDialogComponent>,
    private toastService: ToastService,
    private authService: AuthService,
    private adminUserService: AdminUserService,
    private uploadService: UploadService,
    public tabService: TabService,
    @Inject(MAT_DIALOG_DATA) public data: AddAdminDialogData
  ) {}

  getUploadScope() {
    const userType = this.authService.user.type;
    if (userType === AdminType.SUPERADMIN || userType === AdminType.CONCIERGE || userType === AdminType.BROKER) {
      return UploadScope.adminProfilePicture;
    }
    return UploadScope.userProfilePicture;
  }

  async onSaveChanges(): Promise<void> {
    this.isLoading = true;
    try {
      const newAdmin = await this.adminUserService.addOne(this.data);
      if (this.fileDetails) {
        await this.uploadService.uploadFile({
          name: this.fileDetails.filename,
          scope: this.getUploadScope(),
          file: this.fileDetails.file,
          owner: newAdmin._id,
          metadata: {
            filename: this.fileDetails.filename,
            height: this.fileDetails.height,
            width: this.fileDetails.width,
            size: this.fileDetails.size,
          },
        });
        this.adminUserService.markUpdate();
      }
      this.dialogRef.close();
      this.toastService.showSuccess('User added');
    } catch (error) {
      this.toastService.showError(error);
    } finally {
      this.isLoading = false;
    }
  }
}
