import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssetType, BackgroundTextPosition } from '../../models/asset';
import { AssetResponseDto } from '../../services/assets.service';
import { OverlayVideoService } from '../../services/overlay-video.service';
import { BrushDefinition } from '../../utils/constants';
import { StringUtils } from '../../utils/string.utils';

@Component({
  selector: 'app-asset-preview',
  templateUrl: './asset-preview.component.html',
  styleUrls: ['./asset-preview.component.scss'],
})
export class AssetPreviewComponent implements OnInit {
  @Input() asset: AssetResponseDto | BrushDefinition;

  @ViewChild('element') audioElement: ElementRef<HTMLAudioElement | HTMLVideoElement>;
  @ViewChild('progressCircle') progressCircle: ElementRef<SVGCircleElement>;

  isLoading = false;
  assetId: string;
  assetDuration: number; // in seconds
  percentages: number[];
  percentageIndex = 0;
  progressInterval: any;
  assetName: string;
  beforeRenameAssetName: string;
  beforeBlurAssetName: string;
  AssetType = AssetType;
  isAssetLoaded = false;

  BackgroundTextPosition = BackgroundTextPosition;
  backgroundTextPosition = BackgroundTextPosition.LEFT;

  constructor(public dialogConfirmation: MatDialog, public overlayVideoService: OverlayVideoService) {}

  ngOnInit(): void {
    if ('key' in this.asset && this.asset.key) {
      this.assetId = this.asset.key.toString();
    }

    this.assetName = StringUtils.trimFileNameExtension(this.asset?.name);
    this.beforeBlurAssetName = this.beforeRenameAssetName = this.assetName;
  }

  ngAfterViewInit(): void {
    if (this.asset.type === AssetType.audio) {
      if (this.asset?.thumbnailUrl) {
        this.audioElement.nativeElement.style.backgroundImage = `url('${this.asset.thumbnailUrl}')`;
      }
    }
  }

  private isAssetPlaying() {
    return this.audioElement && !this.audioElement.nativeElement.paused;
  }

  playPauseAudio() {
    if (this.isAssetPlaying()) {
      this.audioElement.nativeElement.pause();
      clearInterval(this.progressInterval);
      return;
    }

    this.audioElement.nativeElement.play();
    this.progressInterval = setInterval(() => {
      this.progressCircle.nativeElement.style.strokeDashoffset = (
        100 -
        (this.percentages[this.percentageIndex] / this.percentages.length) * 100
      ).toString();
      this.percentageIndex++;

      if (this.percentageIndex === this.percentages.length - 1) {
        clearInterval(this.progressInterval);
        this.audioElement.nativeElement.pause();
        this.percentageIndex = 0;
        this.progressCircle.nativeElement.style.strokeDashoffset = '100';
      }
    }, 1000);
  }

  loadAsset(shouldPlayAfterLoad = true) {
    if (!('url' in this.asset)) return;

    const loadAssetMetadata = () => {
      this.audioElement.nativeElement.addEventListener('loadedmetadata', async () => {
        this.assetDuration = Math.round(this.audioElement.nativeElement.duration);
        this.percentages = [...Array(this.assetDuration + 1).keys()];
        this.isLoading = false;
        this.isAssetLoaded = true;
        if (shouldPlayAfterLoad) {
          this.playAsset();
        }
      });
    };

    if (this.isAssetLoaded) {
      if (shouldPlayAfterLoad) {
        this.playAsset();
      }
      return;
    }

    this.isLoading = true;
    this.audioElement.nativeElement.src = this.asset.url.toString();
    loadAssetMetadata();
  }

  playAsset() {
    if (!('type' in this.asset)) return;

    switch (this.asset.type) {
      case this.AssetType.audio:
        this.playPauseAudio();
        break;
      case this.AssetType.video:
        this.overlayVideoService.openVideoOverlay(this.asset);
        break;
      default:
        break;
    }
  }
}
