<article [appLoading]="isLoading" class="entry-page join-business">
  <app-logo></app-logo>
  <section *ngIf="invitedAdmin">
    <div class="form-group">
      <div class="grid-center-row-items">
        <p class="title">Create a password for your</p>
        <div class="grid-center-column-items">
          <p class="title highlight">Graph {{ invitedAdmin.type }} <span>account</span></p>
        </div>
      </div>

      <div class="email">
        <p class="no-margin">{{ invitedAdmin.email }}</p>
      </div>

      <div class="form-group">
        <label for="password">Your password</label>
        <div class="password-box">
          <input
            [(ngModel)]="password"
            [type]="hidePassword ? 'password' : 'text'"
            id="password"
            name="password"
            autocomplete="current-password"
            (keyup.enter)="setPassword()"
            placeholder="Enter your password"
          />
          <i class="icon eye-icon" (click)="hidePassword = !hidePassword">{{
            hidePassword ? 'visibility_off' : 'visibility'
          }}</i>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="samepassword">Confirm password</label>
      <div class="password-box">
        <input
          [(ngModel)]="samepassword"
          [type]="hidePassword ? 'password' : 'text'"
          id="samepassword"
          name="samepassword"
          autocomplete="current-password"
          (keyup.enter)="setPassword()"
          placeholder="Enter the same password"
        />
        <i class="icon eye-icon" (click)="hidePassword = !hidePassword">{{
          hidePassword ? 'visibility_off' : 'visibility'
        }}</i>
      </div>
    </div>
    <app-password-constraints></app-password-constraints>
    <button (click)="setPassword()" [disabled]="this.password == undefined">Create password</button>

    <app-error [error]="error"></app-error>
  </section>
  <section *ngIf="!invitedAdmin">
    <p class="join-business-error">
      {{ error }}
    </p>
  </section>
</article>
