import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '../../services/auth.service';
import { ViewAsService } from '../../services/view-as.service';

@UntilDestroy()
@Component({
  selector: 'app-view-as-banner',
  templateUrl: './view-as-banner.component.html',
  styleUrls: ['./view-as-banner.component.scss'],
})
export class ViewAsBannerComponent implements OnInit {
  shouldShowBanner: boolean;
  userFullName: string;

  constructor(private viewAsService: ViewAsService, private router: Router, private authService: AuthService) {
    this.shouldShowBanner = this.viewAsService.shouldShowBanner;
    this.userFullName = this.viewAsService.userFullName;
  }

  ngOnInit(): void {
    this.viewAsService
      .dataChanged$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.shouldShowBanner = this.viewAsService.shouldShowBanner;
        this.userFullName = this.viewAsService.userFullName;
      });
  }

  async returnFromViewAs() {
    // log in back admin and retrieve return url
    const returnUrl = this.authService.handleReturnFromViewAs();

    // hide banner
    this.viewAsService.updateVisibilityBanner(false);

    // and redirect to the profile page admin was viewing
    await this.router.navigateByUrl(decodeURI(returnUrl));
  }
}
