import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SoundtrackAssetsCategory, SoundtrackCategoryService } from '../../services/soundtrack-category.service';
import { TableUtils } from '../../utils/table.utils';
import { EditSoundtracksCategoriesDialogComponent } from '../edit-soundtracks-categories-dialog/edit-soundtracks-categories-dialog.component';
import { EditSoundtracksCategoriesDialogData } from '../select-soundtrack-category-header/select-soundtrack-category-header.component';

@Component({
  selector: 'app-sort-by-soundtrack-category',
  templateUrl: './sort-by-soundtrack-category.component.html',
  styleUrls: ['./sort-by-soundtrack-category.component.scss'],
})
export class SortBySoundtrackCategoryComponent implements OnChanges {
  @Input() queryParams: ParamMap;
  @Input() fragment: string | null;
  @Input() columnName: string;
  @Input() columnDisplayName: string;
  @Input() soundtrackCategoriesInput: SoundtrackAssetsCategory[] = [];
  @Input('setQueryParams') setQueryParams: (queryParams: any) => Promise<void>;

  activeSortOrder = '';
  activeSortColumn = '';
  activeCategoryId = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private soundtrackCategoryService: SoundtrackCategoryService
  ) {
    // router and route are needed in setQueryParams method on DataTableComponent
    soundtrackCategoryService.hasSavedChanges = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.activeSortOrder = changes.queryParams?.currentValue.get('sortOrder');
    this.activeSortColumn = changes.queryParams?.currentValue.get('column');
    if (changes.queryParams?.currentValue?.get('soundtrackCategoryId')) {
      this.activeCategoryId = changes.queryParams?.currentValue?.get('soundtrackCategoryId');
    }
  }

  async sortByCategoryOnly(categoryId: string) {
    if (this.activeCategoryId === categoryId) {
      categoryId = '';
    }
    const tableQueryParams = TableUtils.parseParams(this.queryParams);
    await this.setQueryParams({
      ...tableQueryParams,
      column: this.columnName,
      soundtrackCategoryId: categoryId,
    });
  }

  openEditCategoriesDialog() {
    const data: EditSoundtracksCategoriesDialogData = {
      showCloseIcon: true,
      soundtrackCategories: this.soundtrackCategoriesInput,
    };
    this.dialog
      .open(EditSoundtracksCategoriesDialogComponent, {
        width: '480px',
        data,
        autoFocus: true,
      })
      .afterClosed()
      .subscribe(() => {
        if (this.soundtrackCategoryService.hasSavedChanges) {
          this.soundtrackCategoryService.hasSavedChanges = false;
        }
      });
  }
}
