import { Component, Input, OnInit } from '@angular/core';
import { AdminType } from '../../models/admin';
import { UserType } from '../../models/user';

@Component({
  selector: 'app-track-record',
  templateUrl: './track-record.component.html',
  styleUrls: ['./track-record.component.scss'],
})
export class TrackRecordComponent implements OnInit {
  @Input() inputUserType: AdminType | UserType;
  adminType = AdminType;
  userType = UserType;

  constructor() {}

  ngOnInit(): void {}
}
