<ng-select
  [items]="items$ | async"
  [placeholder]="placeholder"
  [typeahead]="input$"
  [loading]="isLoading"
  [(ngModel)]="value"
  [name]="name"
  [id]="cid"
  [required]="inputRequired"
  [readonly]="inputReadonly"
  [disabled]="inputDisabled"
  (open)="onTouched()"
  [searchable]="false"
  [virtualScroll]="true"
  (scrollToEnd)="onScrollToEnd()"
  (clear)="onClearSelect()"
  (click)="onClickSelect()"
  (focus)="onFocusSelect()"
  (blur)="onBlurSelect()"
  (change)="onChangeSelect()"
  [isOpen]="isOpenedSelect"
  [typeToSearchText]="typeToSearchTextInput"
  class="custom"
>
  <ng-template ng-header-tmp>
    <app-search-bar
      *ngIf="httpResultsScopeInput === httpResultsScope.NOTABLES"
      [searchPlaceholder]="'Search'"
      [isForTableSearch]="false"
      [showClear]="false"
      (searchValue)="onSearchValueInputChange($event)"
    ></app-search-bar>
    <app-select-soundtrack-category-header
      *ngIf="httpResultsScopeInput === httpResultsScope.SOUNDTRACKS"
      [showCloseIconInput]="showCloseIconInput"
      [soundtrackCategoriesInput]="items$ | async"
    >
    </app-select-soundtrack-category-header>
  </ng-template>

  <ng-template
    *ngIf="imageField && httpResultsScopeInput !== httpResultsScope.SOUNDTRACKS"
    ng-label-tmp
    let-item="item"
  >
    <div class="image-option">
      <app-avatar
        width="24"
        height="24"
        type="circle"
        [initialsFontSize]="'12px'"
        [src]="item.image"
        [userFullName]="item.label || ''"
      ></app-avatar>
      <p>{{ item.label }}</p>
    </div>
  </ng-template>

  <ng-template
    *ngIf="imageField && httpResultsScopeInput !== httpResultsScope.SOUNDTRACKS"
    ng-option-tmp
    let-item="item"
    let-index="index"
    let-search="searchTerm"
    class="custom"
  >
    <div class="image-option">
      <app-avatar
        width="24"
        height="24"
        type="circle"
        [initialsFontSize]="'12px'"
        [src]="item.image"
        [userFullName]="item.label || ''"
      ></app-avatar>
      <p>{{ item.label }}</p>
    </div>
  </ng-template>

  <ng-template *ngIf="httpResultsScopeInput === httpResultsScope.SOUNDTRACKS" ng-label-tmp let-item="item">
    <div class="image-option">
      <p>{{ item.label }}</p>
    </div>
  </ng-template>

  <ng-template
    *ngIf="httpResultsScopeInput === httpResultsScope.SOUNDTRACKS"
    ng-option-tmp
    let-item="item"
    let-index="index"
    let-search="searchTerm"
    class="custom"
  >
    <div class="grid-center-column-items justify-between soundtracks-categories w100" (click)="clickOption(index)">
      <p>{{ item.label }}</p>
      <p>{{ item.value }}</p>
    </div>
  </ng-template>
</ng-select>
