import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import {
  ProfileDialogComponent,
  ProfileDialogCloseResult,
} from 'src/app/shared/components/profile-dialog/profile-dialog.component';
import { Admin, AdminType } from 'src/app/shared/models/admin';
import { AdminUserService } from 'src/app/shared/services/admin-user.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ViewAsService } from 'src/app/shared/services/view-as.service';
import { ToastService } from '../../shared/services/toast.service';

export interface MenuItem {
  title: string;
  link?: string;
  icon?: string;
  submenu?: { title: string; link: string }[];
  expanded?: boolean;
  roles: AdminType[];
}

const allMenuItems: MenuItem[] = [
  {
    title: 'Dashboard',
    link: '/admin/dashboard',
    icon: 'trending-up',
    roles: [AdminType.SUPERADMIN, AdminType.CONCIERGE, AdminType.BROKER],
  },
  {
    title: 'Overview',
    link: '/admin/overview',
    roles: [AdminType.SUPERADMIN, AdminType.CONCIERGE, AdminType.BROKER],
    icon: 'users',
  },
  {
    title: 'Assets',
    link: '/admin/assets',
    roles: [AdminType.SUPERADMIN, AdminType.CONCIERGE, AdminType.BROKER],
    icon: 'assets',
  },
  {
    title: 'Orders',
    link: '/admin/orders',
    roles: [AdminType.SUPERADMIN],
    icon: 'orders',
  },
  // {
  //   title: 'Users',
  //   link: '/admin/users',
  //   icon: 'face',
  //   roles: [AdminType.CONCIERGE, AdminType.SUPERADMIN],
  // },
];

@UntilDestroy()
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @ViewChildren(MatMenu) menus: QueryList<MatMenu>;

  @Input() isMobile = false;
  @Input() isCondensed = false;

  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>();
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();

  tabEntity: string;
  isLoggingOut = false;
  user: Admin;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private toast: ToastService,
    public dialog: MatDialog,
    protected adminUserService: AdminUserService,
    private viewAsService: ViewAsService
  ) {}

  ngOnInit() {
    this.user = this.authService.user;

    this.urlChanged(this.router.url);

    this.viewAsService
      .dataChanged$()
      .pipe(untilDestroyed(this))
      .subscribe(async () => {
        await this.updateAdminUser();
      });

    this.adminUserService
      .dataChanged$()
      .pipe(untilDestroyed(this))
      .subscribe(async () => {
        await this.updateAdminUser();
      });
  }

  async updateAdminUser() {
    if (this.authService.user?._id) {
      this.user = await this.adminUserService.getOne(this.authService.user._id);
    }
  }

  get menuItems() {
    return allMenuItems.filter((item) => item.roles.includes(this.authService.user?.type));
  }

  get condensed() {
    return this.isMobile ? false : this.isCondensed;
  }

  urlChanged(url) {
    this.tabEntity = url.split('?')[0];
    this.setExpanded();
    this.cdr.detectChanges();
  }

  setExpanded() {
    this.menuItems
      .filter((item) => item.submenu != null)
      .forEach((item) => {
        item.expanded = item.submenu.map((i) => i.link).includes(this.tabEntity);
      });
  }

  async onLogout() {
    try {
      this.isLoggingOut = true;
      await this.authService.logout();
      this.logout.emit();
    } catch (error) {
      this.toast.showError(error);
    } finally {
      this.isLoggingOut = false;
    }
  }

  itemClick(item: MenuItem) {
    if (item.submenu && !this.condensed) {
      item.expanded = !item.expanded;
    }
  }

  openProfileDialog() {
    let dialogRef = this.dialog.open(ProfileDialogComponent, {
      data: {
        _id: this.user._id,
        profilePictureUrl: this.user.profilePictureUrl,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        type: this.user.type,
        email: this.user.email,
        password: this.user.password,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: ProfileDialogCloseResult) => {
        if (!result) return;
        if (!result.hasUploadedFile) return;

        // when user changes photo we need to update the user
        await this.authService.updateAdmin(await this.adminUserService.getMe());
        this.user = this.authService.user;
      });
  }
}
