import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssetType, BackgroundTextPosition } from '../../models/asset';
import { NotableAsset } from '../../models/notable';
import { StringUtils } from '../../utils/string.utils';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { OverlayVideoService } from '../../services/overlay-video.service';
import { take } from 'rxjs/operators';
import { AssetResponseDto } from '../../services/assets.service';
@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss'],
})
export class AssetComponent implements OnInit, AfterViewInit, AfterContentInit {
  @Input() givenAsset: AssetResponseDto;
  @Input() isDisplayedOnTable = false;
  @Input() isOnModal = false;
  @Input() isDisabledDragInput = false;
  @Input() hideAsset = false;
  @Input() isInputDisabled = false;
  @Input() shouldPreloadAsset = false;
  @Input() isLocalFile = false;
  @Input() hideRemove = false;
  @Input() shouldShowLargeVideo = false;
  @Input() shouldHideActions = false;
  @Output() saveRenameChange: EventEmitter<string> = new EventEmitter();
  @Output() backgroundTextPositionChange: EventEmitter<BackgroundTextPosition> = new EventEmitter();
  @Output() removeItemChange: EventEmitter<string> = new EventEmitter();
  @Output() isInputFocusedEmit: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('element') assetElement: ElementRef<HTMLAudioElement | HTMLVideoElement>;
  @ViewChild('progressCircle') progressCircle: ElementRef<SVGCircleElement>;

  isLoading = false;
  assetId: string;
  assetDuration: number; // in seconds
  percentages: number[];
  percentageIndex = 0;
  progressInterval: any;
  assetName: string;
  beforeRenameAssetName: string;
  beforeBlurAssetName: string;
  allAssetTypes = AssetType;
  isAssetLoaded = false;

  BackgroundTextPosition = BackgroundTextPosition;
  backgroundTextPosition = BackgroundTextPosition.LEFT;

  constructor(public dialogConfirmation: MatDialog, public overlayVideoService: OverlayVideoService) {}

  ngOnInit(): void {
    if (this.givenAsset.key) {
      this.assetId = this.givenAsset.key.toString();
    }
    this.assetName = StringUtils.trimFileNameExtension(this.givenAsset?.name);
    this.beforeBlurAssetName = this.beforeRenameAssetName = this.assetName;
  }

  ngAfterViewInit(): void {
    if (this.givenAsset?.thumbnailUrl) {
      this.assetElement.nativeElement.style.backgroundImage = `url('${this.givenAsset.thumbnailUrl}')`;
    }
    if (this.shouldPreloadAsset) {
      this.loadAsset(false);
    }
  }

  ngAfterContentInit(): void {
    if (this.shouldPreloadAsset) {
      this.isAssetLoaded = true;
    }
  }

  ngOnChanges() {
    this.backgroundTextPosition = this.givenAsset?.backgroundTextPosition ?? BackgroundTextPosition.LEFT;
  }

  private isAssetPlaying() {
    return this.assetElement && !this.assetElement.nativeElement.paused;
  }

  playPauseAudio() {
    if (this.isAssetPlaying()) {
      this.assetElement.nativeElement.pause();
      clearInterval(this.progressInterval);
      return;
    }

    this.assetElement.nativeElement.play();
    this.progressInterval = setInterval(() => {
      this.progressCircle.nativeElement.style.strokeDashoffset = (
        100 -
        (this.percentages[this.percentageIndex] / this.percentages.length) * 100
      ).toString();
      this.percentageIndex++;

      if (this.percentageIndex === this.percentages.length - 1) {
        clearInterval(this.progressInterval);
        this.assetElement.nativeElement.pause();
        this.percentageIndex = 0;
        this.progressCircle.nativeElement.style.strokeDashoffset = '100';
      }
    }, 1000);
  }

  onBlurInput() {
    this.beforeBlurAssetName = this.assetName;
    this.assetName = this.beforeRenameAssetName;
    this.isInputFocusedEmit.emit(false);
  }

  onRemove(event) {
    event.target.blur();

    // on modal dialog, do not show remove confirmation action
    if (this.isOnModal) {
      this.removeItemChange.emit(this.assetId);
      return;
    }

    let title = `Remove ${this.assetName} from `;
    const message = 'This action cannot be undone.';
    let actionMessage = 'Delete ';
    switch (this.givenAsset.type) {
      case AssetType.audio:
        title += 'soundtracks';
        actionMessage += 'soundtrack';
        break;
      case AssetType.video:
        title += 'video files';
        actionMessage += 'video';
        break;
      case AssetType.brush:
        title += 'brush files';
        actionMessage += 'brush';
        break;
      case AssetType.soundtrackCategory:
        title += 'soundtracks categories';
        actionMessage += 'soundtrack category';
        break;

      default:
        break;
    }
    title += '?';
    this.openConfirmationDialog(title, message, actionMessage, 'warn');
  }

  openConfirmationDialog(title: string, message: string, actionMessage: string, color: 'warn' | 'info') {
    const dialogConfirmationRef = this.dialogConfirmation.open(ConfirmationDialogComponent, {
      data: {
        title,
        message,
        actionMessage,
        color,
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogConfirmationRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: boolean) => {
        if (!result) return;
        this.removeItemChange.emit(this.assetId);
      });
  }

  onSaveRename(event) {
    event.preventDefault();
    event.stopPropagation();
    event.target.blur();
    this.assetName = this.beforeBlurAssetName;
    this.saveRenameChange.emit(this.assetName);
  }

  onCancelRename(event) {
    event.preventDefault();
    event.stopPropagation();
    event.target.blur();
    this.assetName = this.beforeRenameAssetName;
  }

  onClickInput(event) {
    event.preventDefault();
    event.stopPropagation();

    this.beforeRenameAssetName = this.assetName;
    this.isInputFocusedEmit.emit(true);
  }

  loadAsset(shouldPlayAfterLoad = true) {
    const loadAssetMetadata = () => {
      this.assetElement.nativeElement.addEventListener('loadedmetadata', async () => {
        this.assetDuration = Math.round(this.assetElement.nativeElement.duration);
        this.percentages = [...Array(this.assetDuration + 1).keys()];
        this.isLoading = false;
        this.isAssetLoaded = true;
        if (shouldPlayAfterLoad) {
          this.playAsset();
        }
      });
    };

    if (this.shouldPreloadAsset && !this.assetDuration) {
      loadAssetMetadata();
      return;
    }

    if (this.isAssetLoaded) {
      if (shouldPlayAfterLoad) {
        this.playAsset();
      }
      return;
    }

    this.isLoading = true;
    if (!this.isLocalFile) {
      // IMPORTANT: do not set src for local files
      this.assetElement.nativeElement.src = this.givenAsset.key.toString();
    }
    loadAssetMetadata();
  }

  playAsset() {
    switch (this.givenAsset.type) {
      case this.allAssetTypes.audio:
        this.playPauseAudio();
        break;
      case this.allAssetTypes.video:
      case this.allAssetTypes.brush:
        this.overlayVideoService.openVideoOverlay(this.givenAsset);
        break;
      default:
        break;
    }
  }

  isVideoAsset() {
    return this.givenAsset.type === this.allAssetTypes.video || this.givenAsset.type === this.allAssetTypes.brush;
  }
}
