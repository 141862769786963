<ng-select
  [items]="items$ | async"
  [multiple]="multiple"
  [placeholder]="placeholder"
  [typeahead]="input$"
  [(ngModel)]="selectedItems"
  [loading]="isLoading"
  [bindLabel]="null"
  [bindValue]="null"
  [closeOnSelect]="!multiple"
  (ngModelChange)="valueChanged($event)"
  [class.image-select]="imageField"
  class="select-http-filter"
  #select
>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-select-multiple-label">
      <span class="number">{{ items.length }}</span>
      <span class="clear" (click)="select.handleClearClick()" aria-hidden="true">×</span>
    </div>
  </ng-template>

  <ng-template *ngIf="imageField" ng-label-tmp let-item="item">
    <div class="image-option">
      <app-avatar
        [src]="item.image"
        type="square"
        width="24"
        height="24"
        [type]="'circle'"
        [initialsFontSize]="'12px'"
        [userFullName]="item.label || ''"
      ></app-avatar>
      {{ item.label }}
    </div>
  </ng-template>

  <ng-template
    *ngIf="imageField"
    ng-option-tmp
    let-item="item"
    let-index="index"
    let-search="searchTerm"
    class="custom"
  >
    <div class="image-option">
      <app-avatar
        [src]="item.image"
        type="square"
        width="24"
        height="24"
        [type]="'circle'"
        [initialsFontSize]="'12px'"
        [userFullName]="item.label || ''"
      ></app-avatar>

      <div>{{ item.label }}</div>
    </div>
  </ng-template>
</ng-select>
