import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TabService } from '../../services/tab.service';
import { StringUtils } from '../../utils/string.utils';

@UntilDestroy()
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() showClear = true;
  @Input() searchPlaceholder: string;
  @Input() isForTableSearch = true;
  @Output() searchValue: EventEmitter<string> = new EventEmitter();

  search = new FormControl('');

  public get value() {
    return this.search.value;
  }

  constructor(private route: ActivatedRoute, private router: Router, public tabService: TabService) {}

  ngOnInit() {
    this.search.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value) => this.searchChanged(value));

    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      const newValue = params.get('search') || '';
      if (newValue !== this.search.value) {
        this.search.setValue(newValue);
      }
    });
  }

  clear() {
    this.search.setValue('');
  }

  searchChanged(value: string) {
    if (value === '') {
      value = null;
    }

    if (!this.isForTableSearch) {
      this.searchValue.emit(value);
      return;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        search: value,
        start: 0,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
      fragment: this.tabService.tabEntity,
    });
  }
}
