import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'timeDisplay',
})
export class TimeDisplayPipe extends DatePipe implements PipeTransform {
  daysSinceGivenDate(date: Date) {
    return dayjs().diff(dayjs(date), 'days');
  }

  uppercaseTimeFormat(time: string) {
    // uppercase last 2 characters, meaning am/pm
    return time.slice(0, -2).concat(time.slice(-2).toUpperCase());
  }

  transform(value?: Date, ...args: any[]): any {
    if (!value) {
      return null;
    }

    const hoursSinceGivenDate = (date: Date) => {
      return dayjs().diff(dayjs(date), 'hours');
    };
    const getTodayHoursSinceMidnight = () => new Date().getHours();
    const isToday = () => getTodayHoursSinceMidnight() > hoursSinceGivenDate(value);
    const isYesterday = () => hoursSinceGivenDate(value) < 48;

    if (isToday()) {
      return 'Today, '.concat(this.uppercaseTimeFormat(super.transform(value, 'h:mm a')));
    }
    if (isYesterday()) {
      return 'Yersterday, '.concat(this.uppercaseTimeFormat(super.transform(value, 'h:mm a')));
    }
    if (this.daysSinceGivenDate(value) >= 365) {
      return this.uppercaseTimeFormat(super.transform(value, 'MMMM d, y, h:mm a'));
    }
    return this.uppercaseTimeFormat(super.transform(value, 'MMMM d, h:mm a'));
  }
}
