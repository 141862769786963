<img
  *ngIf="src"
  [imgPreloader]="src"
  [attr.alt]="alt"
  [ngClass]="{ square: type === 'square', circle: type === 'circle' }"
  [ngStyle]="{ width: imgWidth, height: imgHeight }"
/>
<i
  *ngIf="!src"
  [ngClass]="{ square: type === 'square', circle: type === 'circle' }"
  [ngStyle]="{ width: imgWidth, height: imgHeight, fontSize: initialsFontSize }"
  class="profile-initials"
>
  <span>{{ userInitials }}</span>
</i>
