import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth.token$.pipe(
      take(1),
      mergeMap((token) => {
        let headers = req.headers;
        if (token) {
          headers = req.headers.append('Authorization', `Bearer ${token}`);
        }

        // Captcha
        if (req.url === 'captcha') {
          const url = environment.apiBaseUrl.replace('/admin', '/captcha');
          return next.handle(req.clone({ url, headers }));
        }

        if (!req.url.startsWith('/assets') && !req.url.startsWith('https://') && !req.url.startsWith('http://')) {
          const url = `${environment.apiBaseUrl}/${req.url}`;
          const apiReq = req.clone({
            url,
            headers,
          });
          return next.handle(apiReq);
        }
        return next.handle(req);
      }),
      catchError(async (err) => {
        const { status, error } = err;
        if (status === 401) {
          this.auth.clearLocalStorage();
          if (error.code === 'DisabledAccount') {
            try {
              await this.auth.logout();
            } catch (error) {}
          }
        }
        throw err;
      })
    );
  }
}
