import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdminUserService } from '../../services/admin-user.service';
import { AuthService } from '../../services/auth.service';
import { ToastService } from '../../services/toast.service';
import { ViewAsService } from '../../services/view-as.service';
import { TabService } from '../../services/tab.service';
import { AdminStatus, AdminType, AdminTypeAll, Admin } from '../../models/admin';
import { User } from '../../models/user';
import { MatDialog } from '@angular/material/dialog';
import {
  CloseEditAdminDialogComponent,
  EditAdminDialogComponent,
} from '../edit-admin-dialog/edit-admin-dialog.component';
import { NotableAsset } from '../../models/notable';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-more-actions',
  templateUrl: './more-actions.component.html',
  styleUrls: ['./more-actions.component.scss'],
})
export class MoreActionsComponent implements OnInit {
  error: any;
  adminStatuses = { ...AdminStatus };
  adminTypesEnum = { ...AdminType };
  adminTypes = AdminTypeAll.map((item) => ({ label: item, value: item }));

  @Input() isLoading = false;
  @Input() selection: SelectionModel<string>;
  @Input() selectedId: string;
  @Input() selectedItem: User | Admin;
  @Input() isOnTableView = false;
  @Input() partialAssets: NotableAsset[] = [];
  @Input('clearSelection') clearSelection: () => void;

  showBanner = true;
  viewAsUser: Admin;
  loggedInUser: Admin;
  userFullName: string;
  isSendingInvite: boolean;

  constructor(
    public route: ActivatedRoute,
    private adminUserService: AdminUserService,
    public router: Router,
    private toast: ToastService,
    private authService: AuthService,
    private viewAsService: ViewAsService,
    public tabService: TabService,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.loggedInUser = this.authService.user;

    this.adminUserService
      .dataChanged$()
      .pipe(untilDestroyed(this))
      .subscribe(async () => {
        await this.refreshUser();
      });

    if (!this.isOnTableView) {
      this.route.params.pipe(untilDestroyed(this)).subscribe(async (params) => {
        this.viewAsUser = await this.adminUserService.getOne(params.id);
        this.buildUserFullName();
      });
    }
  }

  buildUserFullName() {
    this.userFullName = this.viewAsUser.firstName.concat(' ').concat(this.viewAsUser.lastName);
  }

  async refreshUser() {
    if (this.isOnTableView) {
      return;
    }
    this.viewAsUser = await this.adminUserService.getOne(this.viewAsUser._id);
    this.buildUserFullName();
  }

  async resendInvite(id: string) {
    this.isSendingInvite = true;
    try {
      const admin = await this.adminUserService.getOne(id);
      await this.adminUserService.resendInvite(admin.email);
      this.toast.showSuccess('Invitation resent');
    } catch (error) {
      this.toast.showError(error);
    } finally {
      this.isSendingInvite = false;
    }
  }

  async changeAdminStatus(id: string, status: AdminStatus) {
    try {
      this.error = null;
      this.isLoading = true;

      await this.adminUserService.updateOne(id, { status });

      this.toast.show('User updated');
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  async bulkChangeAdminStatus(status: AdminStatus) {
    try {
      this.error = null;
      this.isLoading = true;

      await this.adminUserService.updateManyStatus(this.selection.selected, status);
      this.toast.show('Users updated');
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  async viewAs() {
    // get return route
    const returnUrl = this.router.routerState.snapshot.url;

    this.viewAsUser = this.selectedItem as Admin;
    this.buildUserFullName();

    // log in as view user
    await this.authService.impersonate(this.viewAsUser._id, returnUrl);

    // show user name on banner
    this.viewAsService.updateUserFullName(this.userFullName);

    // show banner
    this.viewAsService.updateVisibilityBanner(true);

    // redirect to dashboard
    await this.router.navigate(['/']);
  }

  openEditAdminDialog() {
    this.dialog
      .open(EditAdminDialogComponent, {
        width: '560px',
        data: {
          _id: this.selectedItem._id,
          profilePictureUrl: this.selectedItem.profilePictureUrl,
          firstName: this.selectedItem.firstName,
          lastName: this.selectedItem.lastName,
          status: this.selectedItem.status,
          type: this.selectedItem.type,
          initialType: this.selectedItem.type,
          fullName: this.selectedItem.fullName || this.selectedItem.firstName + ' ' + this.selectedItem.lastName,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: CloseEditAdminDialogComponent) => {
        if (result.hasChangedRole || result.hasDeletedUser) {
          this.clearSelection();
        }
      });
  }
}
