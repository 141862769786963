<div class="outer-video" (click)="closeOverlay()">
  <div class="video-overlay-wrapper">
    <button (click)="playPauseVideoAsset($event)">
      <video #assetElement>
        <source [src]="videoAsset.url ?? videoAsset.key" type="video/mp4" />
        Cannot play video file
      </video>
    </button>

    <button
      class="slider-wrapper opacity-zero-with-transition"
      [appLoading]="isLoading"
      (click)="preventCloseOverlay($event)"
    >
      <input
        type="range"
        value="0"
        step="0.01"
        #sliderElement
        (input)="onInputSlider($event)"
        (change)="onChangeSlider($event)"
      />
    </button>
    <button class="close-video-overlay opacity-zero-with-transition" (click)="closeOverlay()">
      <img src="/assets/images/icons/close-video-overlay.svg" />
    </button>
    <button class="play-pause-video-overlay opacity-zero-with-transition" [appLoading]="isLoading">
      <img
        [src]="
          !isLoading && !assetElement.paused
            ? '/assets/images/icons/pause-video-overlay.svg'
            : '/assets/images/icons/play-video-overlay.svg'
        "
        [matTooltip]="!isLoading && !assetElement.paused ? 'Pause video' : 'Play video'"
        (click)="playPauseVideoAsset($event)"
        [class]="hasError ? 'cursor-not-allowed' : ''"
      />
    </button>
    <div class="time-display opacity-zero-with-transition" [appLoading]="isLoading">
      <p>{{ assetTimeDisplay }}</p>
    </div>
  </div>
</div>
