<div class="track-record-wrapper column-style">
  <div class="track-record">
    <div class="item">
      <p>Track record</p>
      <div class="actions">
        <ng-container *ngIf="inputUserType === adminType.SUPERADMIN">
          <button mat-flat-button [matMenuTriggerFor]="menuTrack">
            <span>Revenue</span>
            <i class="icon">arrow_drop_down</i>
          </button>
          <mat-menu #menuTrack="matMenu" class="menu-wrapper">
            <button mat-menu-item>
              <span> Revenue </span>
              <i class="icon">check</i>
            </button>
            <button mat-menu-item>Graphs sold</button>
            <button mat-menu-item>Brokers earnings</button>
            <button mat-menu-item>Concierge earnings</button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="inputUserType === adminType.CONCIERGE">
          <button mat-flat-button [matMenuTriggerFor]="menuTrack">
            <span>Earnings</span>
            <i class="icon">arrow_drop_down</i>
          </button>
          <mat-menu #menuTrack="matMenu" class="menu-wrapper">
            <button mat-menu-item>
              <span> Earnings </span>
              <i class="icon">check</i>
            </button>
            <button mat-menu-item>Fulfilled requests</button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="inputUserType === adminType.BROKER">
          <button mat-flat-button [matMenuTriggerFor]="menuTrack">
            <span>Earnings</span>
            <i class="icon">arrow_drop_down</i>
          </button>
          <mat-menu #menuTrack="matMenu" class="menu-wrapper">
            <button mat-menu-item>
              <span> Earnings </span>
              <i class="icon">check</i>
            </button>
            <button mat-menu-item>Fulfilled requests</button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="inputUserType === userType.notable">
          <button mat-flat-button [matMenuTriggerFor]="menuTrack">
            <span>Earnings</span>
            <i class="icon">arrow_drop_down</i>
          </button>
          <mat-menu #menuTrack="matMenu" class="menu-wrapper">
            <button mat-menu-item>
              <span> Earnings </span>
              <i class="icon">check</i>
            </button>
            <button mat-menu-item>Fulfilled requests</button>
          </mat-menu>
        </ng-container>
      </div>
    </div>
    <div class="year-navigation">
      <i class="icon">keyboard_arrow_left</i>
      <p>2021</p>
      <i class="icon">keyboard_arrow_right</i>
    </div>
  </div>
  <img src="/assets/images/track-record.png" class="track" />
</div>
