import * as dayjs from 'dayjs';
import { DatePipe } from '@angular/common';

export class StringUtils {
  public static textHumanize(value: string) {
    return (value || '').replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
  }

  public static removePlural(value: string) {
    if (!value) return '';
    if (value === '') return '';
    if (value.slice(-1) === 's') return value.slice(0, -1);
    return value;
  }

  public static capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  public static trimFileNameExtension(value: string) {
    if (!value || value === '') return '';
    return value?.split('.').slice(0, -1).join('.') || value;
  }

  public static convertUTCDateToLocalDate(date: Date) {
    if (!date) return null;

    date = new Date(date);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  }

  public static convertLocalDateToUTCDate(date: Date) {
    if (!date) return null;

    date = new Date(date);
    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  }

  public static parseCreatedAtDate(value: Date) {
    const daysSinceGivenDate = (date: Date) => {
      return dayjs().diff(dayjs(date), 'days');
    };

    const hoursSinceGivenDate = (date: Date) => {
      return dayjs().diff(dayjs(date), 'hours');
    };

    const uppercaseTimeFormat = (time: string) => {
      // uppercase last 2 characters, meaning am/pm
      return time.slice(0, -2).concat(time.slice(-2).toUpperCase());
    };

    const getTodayHoursSinceMidnight = () => new Date().getHours();
    const isToday = () => getTodayHoursSinceMidnight() > hoursSinceGivenDate(value);
    const isYesterday = () => hoursSinceGivenDate(value) < 48;

    if (!value) {
      return null;
    }

    if (isToday()) {
      return 'Today, '.concat(uppercaseTimeFormat(new DatePipe('en-US').transform(value, 'h:mm a')));
    }
    if (isYesterday()) {
      return 'Yersterday, '.concat(uppercaseTimeFormat(new DatePipe('en-US').transform(value, 'h:mm a')));
    }

    value = StringUtils.convertLocalDateToUTCDate(value);
    if (daysSinceGivenDate(value) >= 365) {
      return uppercaseTimeFormat(new DatePipe('en-US').transform(value, 'MMMM d, y, h:mm a'));
    }
    return uppercaseTimeFormat(new DatePipe('en-US').transform(value, 'MMMM d, h:mm a'));
  }

  public static isEmpty(str: string | null | undefined) {
    if (str === null || str === undefined || str === '') return true;
    return false;
  }
}
