<div class="mat-sort-header-container">
  <p>{{ columnDisplayName }}</p>
  <button class="filter-table-row-arrow" [matMenuTriggerFor]="nameSort">
    <i></i>
  </button>
  <mat-menu #nameSort="matMenu" xPosition="before" class="menu-wrapper">
    <ng-container *ngIf="sortColumnTypeInput !== sortColumnType.DATE">
      <button mat-menu-item (click)="sortByName('asc')">
        <ng-container *ngIf="sortColumnTypeInput === sortColumnType.ALPHABETICAL">
          <span>Sort A-Z</span>
        </ng-container>
        <ng-container *ngIf="sortColumnTypeInput === sortColumnType.NUMERICAL">
          <span>Sort Ascending</span>
          <img src="/assets/images/icons/sort-arrow-asc.svg" />
        </ng-container>
        <i *ngIf="activeSortOrder === 'asc' && columnName === activeSortColumn" class="icon">check</i>
      </button>
      <button mat-menu-item (click)="sortByName('desc')">
        <ng-container *ngIf="sortColumnTypeInput === sortColumnType.ALPHABETICAL">
          <span>Sort Z-A</span>
        </ng-container>
        <ng-container *ngIf="sortColumnTypeInput === sortColumnType.NUMERICAL">
          <span>Sort Descending</span>
          <img class="rotate-180" src="/assets/images/icons/sort-arrow-asc.svg" />
        </ng-container>
        <i *ngIf="activeSortOrder === 'desc' && columnName === activeSortColumn" class="icon">check</i>
      </button>
    </ng-container>
    <ng-container *ngIf="sortColumnTypeInput === sortColumnType.DATE">
      <button *ngIf="sortColumnTypeInput === sortColumnType.DATE" mat-menu-item (click)="sortByName('desc')">
        <span>Newest first</span>
        <i *ngIf="activeSortOrder === 'desc' && columnName === activeSortColumn" class="icon">check</i>
      </button>
      <button *ngIf="sortColumnTypeInput === sortColumnType.DATE" mat-menu-item (click)="sortByName('asc')">
        <span>Oldest first</span>
        <i *ngIf="activeSortOrder === 'asc' && columnName === activeSortColumn" class="icon">check</i>
      </button>
    </ng-container>
  </mat-menu>
</div>
