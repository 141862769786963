import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ErrorUtils } from '../../utils/error.utils';

interface ErrorMessageComponent {
  message: string;
  errors?: string[];
}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnChanges {
  @Input() error: any;
  @Input() margin = true; // Optional - specify if the component should have a margin-bottom of 1 rem or not

  errorMessage: string;

  ngOnChanges(changes: SimpleChanges): void {
    this.handleError(changes.error.currentValue);
  }

  handleError(error: any) {
    if (error == null) return;

    this.errorMessage = error.error?.errors ? error.error.errors[0] : ErrorUtils.toString(error);
  }

  @HostBinding('class.hidden')
  get isHidden() {
    return !this.error || !this.errorMessage;
  }
}
