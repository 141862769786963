<form
  *ngIf="!isEditingCategories"
  #assetForm="ngForm"
  class="upload-assets-dialog"
  [class.is-assigning]="data.assetType !== assetType.brush"
>
  <div>
    <div class="header-row">
      <p>{{ uploadTitle }}</p>
      <button>
        <img src="/assets/images/icons/close.svg" matTooltip="Cancel" (click)="cancel()" class="cursor-pointer" />
      </button>
    </div>

    <div
      *ngIf="!this.data.isGeneral || (this.data.isReassign && this.data.assetType !== assetType.audio)"
      [class]="assets?.length ? 'form-group assign-wrapper adjust-spacing' : 'form-group assign-wrapper'"
    >
      <div class="form-group">
        <label for="selectedNotable">Assign to</label>
        <app-select-http-input
          url="notables"
          placeholder="Select a notable"
          labelField="fullName"
          valueField="_id"
          imageField="profilePictureUrl"
          additionalFields="profilePictureKey"
          [(ngModel)]="selectedNotableId"
          [httpResultsScopeInput]="httpResultsScope.NOTABLES"
          [showCloseIconInput]="data.showCloseIcon"
          name="selectedNotable"
        >
        </app-select-http-input>
      </div>
    </div>
    <div
      *ngIf="this.data.assetType === assetType.audio && this.data.isGeneral && !this.data.user"
      class="form-group assign-wrapper"
      [class.adjust-spacing]="assets?.length"
    >
      <div class="form-group">
        <label for="selectedCategory">Category</label>
        <app-select-http-input
          url="soundtracks-categories/group"
          placeholder="Select a category"
          labelField="name"
          valueField="count"
          additionalFields="order,_id"
          [queryParams]="queryParamsUploadSoundtracks"
          [(ngModel)]="selectedSoundtrackCategoryName"
          [httpResultsScopeInput]="httpResultsScope.SOUNDTRACKS"
          [showCloseIconInput]="data.showCloseIcon"
          [typeToSearchTextInput]="'No category found. Start adding categories.'"
          (emitSelectedSoundtrackCategoryId)="selectedSoundtrackCategoryId = $event"
          name="selectedCategory"
        >
        </app-select-http-input>
      </div>
    </div>

    <div *ngIf="this.data.isReassign" class="to-upload-assets-dialog thin-scrollbar">
      <ng-container *ngFor="let partialAsset of data.partialAssets; index as i">
        <app-asset [isOnModal]="true" [givenAsset]="partialAsset" [isInputDisabled]="true"></app-asset>
      </ng-container>
    </div>
    <div *ngIf="!this.data.isReassign" [appLoading]="isLoading">
      <app-upload-asset
        (changeFiles)="assets = $event"
        [assetType]="data.assetType"
        [isNotableSelected]="!!selectedNotableId"
        [isGeneralTab]="this.data.isGeneral"
      >
      </app-upload-asset>
    </div>
    <app-error [error]="error"></app-error>
  </div>
  <div
    *ngIf="
      !this.data.isReassign &&
      assets?.length &&
      (this.data.isGeneral || selectedSoundtrackCategoryId || selectedNotableId)
    "
    class="actions"
  >
    <button (click)="upload()" [appLoading]="isLoading">
      <span>Upload</span>
    </button>
  </div>
  <div *ngIf="this.data.isReassign" class="actions">
    <button
      (click)="reassignItems()"
      [appLoading]="isLoading"
      [disabled]="!selectedNotableId && !selectedSoundtrackCategoryId"
    >
      <span *ngIf="this.data.partialAssets.length < 2"> Move 1 item</span>
      <span *ngIf="this.data.partialAssets.length >= 2">Move {{ this.data.partialAssets.length }} items</span>
    </button>
  </div>
</form>
