<section class="success-reset">
  <div>
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark-circle" cx="26" cy="26" r="25" fill="none" />
      <path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  </div>
  <div *ngIf="data.hasCreatedAdminAccount">
    <p>You’ve successfully created</p>
    <p>your Graph password</p>
  </div>
  <div *ngIf="!notableId && !data.hasCreatedAdminAccount">
    <p>You’ve set up a</p>
    <p>new password</p>
  </div>
  <div *ngIf="notableId">
    <p>Profile created</p>
    <p>successfully!</p>
  </div>
</section>
