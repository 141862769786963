import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthService } from '../../services/auth.service';
import { ToastService } from '../../services/toast.service';
import { TabService } from '../../services/tab.service';
import { Admin } from '../../models/admin';
import { MatDialog } from '@angular/material/dialog';
import { Asset, AssetStatus, AssetType, ReassignAssets } from '../../models/asset';
import { AssetResponseDto, AssetsService } from '../../services/assets.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {
  UploadAssetDialogData,
  UploadAssetsDialogComponent,
} from '../upload-assets-dialog/upload-assets-dialog.component';
import { NotableAsset } from '../../models/notable';
import { SoundtrackAssetsCategory } from '../../services/soundtrack-category.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-more-actions-assets',
  templateUrl: './more-actions-assets.component.html',
  styleUrls: ['./more-actions-assets.component.scss'],
})
export class MoreActionsAssetsComponent implements OnInit {
  error: any;
  assetStatus = AssetStatus;

  @Input() isLoading = false;
  @Input() selection: SelectionModel<string>;
  @Input() selectedId: string;
  @Input() selectedItem: Asset;
  @Input() isOnTableView = false;
  @Input() isGeneralTab = true;
  @Input() partialAssets: AssetResponseDto[] = [];
  @Input() hideMoveTo = false;
  @Input() soundtrackCategories: SoundtrackAssetsCategory[] = [];
  @Input('clearSelection')
  clearSelection: () => void;

  loggedInUser: Admin;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private toastService: ToastService,
    private authService: AuthService,
    public tabService: TabService,
    private assetsService: AssetsService,
    public dialogConfirmation: MatDialog
  ) {}

  async ngOnInit() {
    this.loggedInUser = this.authService.user;
  }

  async changeAssetStatus(status: AssetStatus) {
    try {
      this.error = null;
      this.isLoading = true;
      await this.assetsService.updateOne(this.selectedId, { status });
      this.afterAssetModify();
      this.toastService.show('Asset updated');
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  async removeAsset() {
    try {
      this.error = null;
      this.isLoading = true;
      this.deleteAsset();
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  private deleteAsset() {
    let title = `Remove ${this.selectedItem.name} from `;
    const message = 'This action cannot be undone.';
    let actionMessage = 'Delete ';
    switch (this.selectedItem.type) {
      case AssetType.audio:
        title += 'soundtracks';
        actionMessage += 'soundtrack';
        break;
      case AssetType.video:
        title += 'video files';
        actionMessage += 'video';
        break;
      case AssetType.brush:
        title += 'brush files';
        actionMessage += 'brush';
        break;

      default:
        break;
    }
    title += '?';
    this.openConfirmationDialogSingleDelete(title, message, actionMessage, 'warn');
  }

  openConfirmationDialogSingleDelete(title: string, message: string, actionMessage: string, color: 'warn' | 'info') {
    this.dialogConfirmation
      .open(ConfirmationDialogComponent, {
        data: {
          title,
          message,
          actionMessage,
          color,
        },
        autoFocus: false,
        restoreFocus: false,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: boolean) => {
        if (!result) return;
        await this.assetsService.deleteOne(this.selectedId);
        this.afterAssetModify();
        this.toastService.show('Asset deleted');
      });
  }

  async bulkChangeAssetStatus(status: AssetStatus) {
    try {
      this.error = null;
      this.isLoading = true;
      await this.assetsService.updateManyStatus(this.selection.selected, status);
      this.afterAssetModify();
      this.toastService.show('Assets updated');
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  async bulkDeleteAsset() {
    try {
      this.error = null;
      this.isLoading = true;
      const title = 'Remove all selected assets?';
      const message = 'This action cannot be undone.';
      const actionMessage = 'Delete all';
      this.openConfirmationDialogBulkDelete(title, message, actionMessage, 'warn');
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  openConfirmationDialogBulkDelete(title: string, message: string, actionMessage: string, color: 'warn' | 'info') {
    this.dialogConfirmation
      .open(ConfirmationDialogComponent, {
        data: {
          title,
          message,
          actionMessage,
          color,
        },
        autoFocus: false,
        restoreFocus: false,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: boolean) => {
        if (!result) return;
        await this.assetsService.deleteMany(this.selection.selected);
        this.afterAssetModify();
        this.toastService.show('Assets deleted');
      });
  }

  private afterAssetModify() {
    this.clearSelection();
  }

  openMoveToDialog() {
    const selectedAssets = this.partialAssets.filter((item) => this.selection.selected.includes(item._id));
    const selectedAssetType = this.selectedItem?.type || selectedAssets[0].type;

    if (selectedAssetType === AssetType.audio) {
      const data: UploadAssetDialogData = {
        assetType: selectedAssetType,
        isGeneral: this.isGeneralTab,
        isReassign: true,
        partialAssets: selectedAssets,
        soundtrackCategories: this.soundtrackCategories,
      };
      this.dialogConfirmation
        .open(UploadAssetsDialogComponent, {
          data,
          autoFocus: false,
          restoreFocus: false,
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(async (result: boolean) => {
          if (!result) return;
          this.afterAssetModify();
        });
      return;
    }

    // else, AssetType is brush
    if (!this.isGeneralTab) {
      this.dialogConfirmation
        .open(ConfirmationDialogComponent, {
          data: {
            title: `Move ${selectedAssets.length < 2 ? 'asset' : 'assets'} to General?`,
            message: `This action will affect ${selectedAssets.length} ${
              selectedAssets.length < 2 ? 'item.' : 'items.'
            }`,
            actionMessage: 'Yes',
            color: 'info',
          },
          autoFocus: false,
          restoreFocus: false,
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(async (result: boolean) => {
          if (!result) return;
          await this.moveAssetsToGeneral(selectedAssets);
          this.afterAssetModify();
        });
      return;
    }

    const data: UploadAssetDialogData = {
      assetType: selectedAssetType,
      isGeneral: this.isGeneralTab,
      isReassign: true,
      partialAssets: selectedAssets,
    };
    this.dialogConfirmation
      .open(UploadAssetsDialogComponent, {
        data,
        autoFocus: false,
        restoreFocus: false,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (result: boolean) => {
        if (!result) return;
        this.afterAssetModify();
      });
  }

  async moveAssetsToGeneral(selectedAssets: NotableAsset[]) {
    try {
      this.isLoading = true;
      const reassignAssets: ReassignAssets = {
        assetsKeys: selectedAssets,
      };
      await this.assetsService.updateManyData(reassignAssets);
      this.toastService.showSuccess('Assets updated');
    } catch (error) {
      this.toastService.showError(error);
    } finally {
      this.isLoading = false;
    }
  }
}
