import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Constants } from 'src/app/shared/utils/constants';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  isOnAdmin: boolean;
  isMobile: boolean;
  condensed: boolean = true;

  sidenavOpened = false;
  isGoogleRecaptchaEnabled = environment.googleRecaptcha.enabled;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private ngSelectConfig: NgSelectConfig
  ) {
    this.ngSelectConfig.appendTo = 'body';
    this.ngSelectConfig.bindValue = 'value';
    this.ngSelectConfig.bindLabel = 'label';
  }

  ngOnInit() {
    document.title = environment.production ? Constants.appName : `${Constants.appName} - ${environment.name}`;
    this.setScrollbarWidth();
  }

  ngAfterViewInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.isOnAdmin = (event as NavigationEnd).urlAfterRedirects.startsWith('/admin');
      if (this.isOnAdmin) {
        setTimeout(() => this.handleMenu(), 0);
      }
    });

    this.breakpointObserver.observe(['(max-width: 1023px)']).subscribe((state: BreakpointState) => {
      setTimeout(() => {
        this.isMobile = state.matches;
        if (this.isOnAdmin) {
          this.handleMenu();
        }
        this.setScrollbarWidth();
      });
    });
  }

  setScrollbarWidth() {
    // https://codepen.io/shshaw/pen/JqGmKx
    const scroller: any = document.scrollingElement;
    // Force scrollbars to display
    scroller.style.setProperty('overflow', 'scroll');
    // Wait for next from so scrollbars appear
    requestAnimationFrame(() => {
      // True width of the viewport, minus scrollbars
      scroller.style.setProperty('--vw', `${scroller.clientWidth / 100}px`);
      // Width of the scrollbar
      scroller.style.setProperty('--scrollbar-width', `${window.innerWidth - scroller.clientWidth}px`);
      // Reset overflow
      scroller.style.setProperty('overflow', '');
    });
  }

  handleMenu() {
    if (this.isMobile) {
      this.sidenavOpened = false;
    } else {
      this.sidenavOpened = true;
    }
  }

  async toggleSidenav() {
    if (this.isMobile) {
      this.sidenavOpened = !this.sidenavOpened;
    } else {
      this.condensed = !this.condensed;
    }
  }
}
