<article class="entry-page">
  <app-logo></app-logo>
  <section>
    <div class="form-group is-login-page">
      <p class="title">Log in to your account</p>

      <label for="email">Email</label>
      <!-- Fake email field so that browser will not autofill.
        See full answer here: https://stackoverflow.com/a/15917221/11322237

        If this is not present, then Log in button will be disabled because email will be null, 
        as autofill doesn't change the value of the input, the text it is just visible to the user.

        See detecting autofill thread: https://stackoverflow.com/questions/11708092/detecting-browser-autofill
      -->
      <input class="hide-element-display" type="text" name="email" />
      <input
        [(ngModel)]="email"
        type="email"
        id="email"
        name="email"
        (keyup.enter)="login()"
        placeholder="Enter your email"
        autocomplete="email"
      />
    </div>

    <div class="form-group">
      <label for="password">Password</label>
      <div class="password-box">
        <input
          [(ngModel)]="password"
          [type]="hidePassword ? 'password' : 'text'"
          id="password"
          name="password"
          autocomplete="current-password"
          (keyup.enter)="login()"
          placeholder="Enter your password"
        />
        <i class="icon eye-icon" (click)="hidePassword = !hidePassword">{{
          hidePassword ? 'visibility_off' : 'visibility'
        }}</i>
      </div>
    </div>

    <button (click)="login()" [appLoading]="isLoading" [disabled]="!email || !password">Log in</button>
    <p (click)="forgotPassword()">Forgot password?</p>

    <app-error [error]="error"></app-error>
  </section>
</article>
