<ng-container *ngIf="viewAsUser && !isOnTableView">
  <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="More actions">
    <i class="icon">more_vert</i>
  </button>
  <mat-menu #menu="matMenu" class="menu-wrapper">
    <button mat-menu-item (click)="openEditAdminDialog()">Edit</button>
    <button mat-menu-item (click)="viewAs()" *ngIf="loggedInUser.type === adminTypesEnum.SUPERADMIN">View as...</button>
    <button
      mat-menu-item
      *ngIf="viewAsUser.status !== adminStatuses.DISABLED"
      (click)="changeAdminStatus(viewAsUser._id, adminStatuses.DISABLED)"
    >
      Disable
    </button>
    <button
      mat-menu-item
      *ngIf="viewAsUser.status !== adminStatuses.ACTIVE"
      (click)="changeAdminStatus(viewAsUser._id, adminStatuses.ACTIVE)"
    >
      Activate
    </button>
  </mat-menu>
</ng-container>
<ng-container *ngIf="selection && isOnTableView">
  <section class="selection-wrapper" *ngIf="selection?.selected?.length === 1 && selectedId && selectedItem">
    <button class="edit-button" (click)="openEditAdminDialog()">
      <span>Edit</span>
    </button>

    <button *ngIf="loggedInUser.type === adminTypesEnum.SUPERADMIN" (click)="viewAs()" class="view-as-button">
      <span>View as...</span>
    </button>
    <button
      *ngIf="selectedItem.status !== adminStatuses.DISABLED"
      (click)="changeAdminStatus(selectedId, adminStatuses.DISABLED)"
    >
      <span>Disable</span>
    </button>
    <button
      *ngIf="selectedItem.status !== adminStatuses.ACTIVE"
      (click)="changeAdminStatus(selectedId, adminStatuses.ACTIVE)"
    >
      <span>Activate</span>
    </button>
    <button
      *ngIf="selectedItem.status === adminStatuses.INVITED"
      (click)="resendInvite(this.selectedId)"
      [appLoading]="isSendingInvite"
      class="resend-invite"
    >
      <span>Resend invite</span>
    </button>
  </section>
  <section class="selection-wrapper" *ngIf="selection?.selected?.length > 1">
    <button (click)="bulkChangeAdminStatus(adminStatuses.DISABLED)">
      <span>Disable</span>
    </button>
    <button (click)="bulkChangeAdminStatus(adminStatuses.ACTIVE)">
      <span>Activate</span>
    </button>
  </section>
</ng-container>
