import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ViewAsService } from './view-as.service';
import { Admin } from '../models/admin';

interface AuthResponse {
  admin: Admin;
  authToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenSubject: Subject<string> = new ReplaySubject(1);

  get token$(): Observable<string> {
    return this.tokenSubject.asObservable();
  }

  private userSubject: BehaviorSubject<Admin> = new BehaviorSubject(null);

  get user$(): Observable<Admin> {
    return this.userSubject.asObservable();
  }

  get user(): Admin {
    return this.userSubject.value;
  }

  private token: string;

  constructor(private router: Router, private http: HttpClient, private viewAsService: ViewAsService) {
    this.getDataFromStorage();
  }

  getDataFromStorage() {
    const adminViewUser: Admin = JSON.parse(sessionStorage.getItem('adminViewAsUser'));
    const adminViewToken = sessionStorage.getItem('authTokenViewAsUser');

    const isViewingAsAnotherUser = (): boolean => {
      return adminViewUser != null;
    };

    const token = isViewingAsAnotherUser()
      ? adminViewToken
      : sessionStorage.getItem('authToken') || localStorage.getItem('authToken');
    const user = isViewingAsAnotherUser()
      ? adminViewUser
      : JSON.parse(sessionStorage.getItem('admin') || localStorage.getItem('admin'));
    this.tokenSubject.next(token || null);
    this.token = token;
    this.userSubject.next(user);

    // show banner with user name and get return url from storage
    if (isViewingAsAnotherUser()) {
      this.viewAsService.updateVisibilityBanner(true);
      this.viewAsService.updateUserFullName(adminViewUser.firstName.concat(' ').concat(adminViewUser.lastName));
    }
  }

  async login(email: string, password: string, rememberMe = true) {
    if (!email || !password) {
      throw new Error('Invalid email or password');
    }

    const resp = await this.http.post<AuthResponse>('auth/login', { email, password }).toPromise();
    this.handleLoginResponse(resp, rememberMe);
  }

  private handleLoginResponse(resp: AuthResponse, rememberMe = true) {
    const { admin, authToken } = resp;

    if (rememberMe) {
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('admin', JSON.stringify(admin));
    }
    sessionStorage.setItem('authToken', authToken);
    sessionStorage.setItem('admin', JSON.stringify(admin));
    this.tokenSubject.next(authToken);
    this.token = authToken;
    this.userSubject.next(admin);
  }

  async updateAdmin(admin: Admin) {
    sessionStorage.setItem('admin', JSON.stringify(admin));
    this.userSubject.next(admin);
  }

  async impersonate(adminId: string, returnUrl: string) {
    if (!adminId) {
      throw new Error('Please provide a user id to impersonate');
    }

    const { admin, authToken } = await firstValueFrom(this.http.post<AuthResponse>('auth/impersonate', { adminId }));

    sessionStorage.setItem('returnUrlViewAsUser', encodeURI(returnUrl));
    sessionStorage.setItem('authTokenViewAsUser', authToken);
    sessionStorage.setItem('adminViewAsUser', JSON.stringify(admin));
    this.tokenSubject.next(authToken);
    this.token = authToken;
    this.userSubject.next(admin);
  }

  handleReturnFromViewAs() {
    // get admin from session storage or local storage
    const admin: Admin = JSON.parse(sessionStorage.getItem('admin')) || JSON.parse(localStorage.getItem('admin'));

    // get authToken from session storage or local storage
    const authToken: string = sessionStorage.getItem('authToken') || localStorage.getItem('authToken');

    // update token subject
    this.tokenSubject.next(authToken);

    // update token value
    this.token = authToken;

    // update user subject
    this.userSubject.next(admin);

    // retrieve return url before deleting the key
    const returnUrl = sessionStorage.getItem('returnUrlViewAsUser');

    // remove view user key items
    sessionStorage.removeItem('authTokenViewAsUser');
    sessionStorage.removeItem('adminViewAsUser');
    sessionStorage.removeItem('returnUrlViewAsUser');

    return returnUrl;
  }

  async logout() {
    await this.http.post('auth/logout', {}, { responseType: 'text' }).toPromise();
    this.clearLocalStorage();
  }

  async clearLocalStorage() {
    sessionStorage.clear();
    localStorage.clear();
    this.tokenSubject.next(null);
    this.userSubject.next(null);
    this.token = null;
    await this.router.navigate(['/login']);
  }

  isLoggedIn() {
    return !!this.token;
  }

  public async verifyCaptcha(token: string) {
    // Optional -- used if there are multiple clients that implement Recaptcha
    const queryParams = { app: 'admin' };

    const { ok } = await this.http.post<{ ok: boolean }>('captcha', { token }, { params: queryParams }).toPromise();

    return ok;
  }

  async changeAdminPasswordByEmailToken(token: string, newPassword: string) {
    return firstValueFrom(this.http.post<any>('auth/change-password/email', { token, newPassword }));
  }

  async changeAdminPasswordFromModal(userId: string, currentPassword: string, newPassword: string) {
    return firstValueFrom(
      this.http.post<any>('auth/change-password/profile', { userId, currentPassword, newPassword })
    );
  }

  async joinBusiness(token: string, password: string) {
    return firstValueFrom(this.http.post<any>('auth/join-business', { token, password }));
  }

  async getAdminByInvitationToken(token: string) {
    return firstValueFrom(this.http.get<any>(`auth/join-business/${token}`));
  }

  async forgotAdminPassword(email: string) {
    return firstValueFrom(this.http.post<any>('auth/forgot-password', { email }));
  }
}
