import { Injectable } from '@angular/core';
import { drawImageOnCanvasCover } from '../utils/canvas.utils';
import { ToastService } from './toast.service';

export const VIDEO_WIDTH = 1920;
export const VIDEO_HEIGHT = 1080;

@Injectable({
  providedIn: 'root',
})
export class FileService {
  isLoading: boolean;

  constructor(private toastService: ToastService) {}

  async getVideoThumbnail(file: File, seekTo?: number) {
    // Original answer: https://stackoverflow.com/a/63474748/11322237
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', () => {
        reject('error when loading video file');
      });

      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
        // seek to desired timestamp (in seconds) if possible
        if (!seekTo || videoPlayer.duration < seekTo) {
          seekTo = videoPlayer.duration / 2;
        }

        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);

        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          // define canvas dimensions
          const canvas = document.createElement('canvas');
          canvas.width = 1280;
          canvas.height = 720;

          // draw the video frame to canvas
          const ctx = canvas.getContext('2d');
          drawImageOnCanvasCover(ctx, videoPlayer);
          // ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

          // return the canvas image as a blob
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            'image/jpeg',
            0.7 /* quality */
          );
        });
      });
    });
  }

  async isVideoResolutionValid(file: File) {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);
      video.addEventListener('loadedmetadata', () => {
        if (video.videoWidth !== VIDEO_WIDTH || video.videoHeight !== VIDEO_HEIGHT) {
          // invalid resolution
          this.toastService.show(
            `Video ${file.name} resolution width must be ${VIDEO_WIDTH} pixels and ${VIDEO_HEIGHT} pixels height.`
          );
          resolve(false);
        } else {
          // valid resolution
          resolve(true);
        }
      });
    });
  }
}
