<div class="profile-edit-wrapper">
  <div class="dialog-header">
    <h1 mat-dialog-title>Edit profile</h1>
    <button cdkFocusInitial mat-dialog-close class="close-button"><i class="icon cursor-pointer"> close </i></button>
  </div>

  <form #userForm="ngForm" class="dialog-form">
    <div mat-dialog-content>
      <div class="user-image-and-details">
        <div class="user-image-camera">
          <div class="form-group">
            <app-upload
              [accept]="acceptedMimeTypes.imageJpeg + ',' + acceptedMimeTypes.imagePng"
              (change)="fileDetails = $event"
              [dataType]="'image'"
              [uploadKey]="data?.profilePictureKey"
              [showInfo]="false"
              [dataUrl]="data.profilePictureUrl"
              [userFullName]="data.fullName"
            >
            </app-upload>
          </div>
        </div>
        <div class="user-name-details-type">
          <div class="user-name-details">
            <div class="form-group">
              <label for="firstName">First name</label>
              <input
                [(ngModel)]="data.firstName"
                type="text"
                id="firstName"
                name="firstName"
                placeholder="Enter first name"
                maxlength="40"
                #firstName
                required
              />
              <app-input-error>{{ firstName.validationMessage }}</app-input-error>
            </div>
            <div class="form-group">
              <label for="lastName">Last name</label>
              <input
                [(ngModel)]="data.lastName"
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Enter last name"
                maxlength="40"
                #lastName
                required
              />
              <app-input-error>{{ lastName.validationMessage }}</app-input-error>
            </div>
          </div>
          <div class="form-group">
            <label for="type">Role</label>
            <ng-select [(ngModel)]="data.type" [items]="adminTypes" placeholder="Select role" name="type" required>
            </ng-select>
            <app-input-error>Role is invalid.</app-input-error>
          </div>
        </div>
      </div>

      <div mat-dialog-actions class="action-buttons">
        <button
          mat-button
          class="save-changes-button"
          [disabled]="(userForm.invalid || userForm.pristine) && !fileDetails?.file"
          (click)="onSaveChanges()"
          [appLoading]="isLoading"
        >
          Save changes
        </button>
        <button
          mat-button
          class="change-status-button"
          (click)="clickUpdateStatus(adminStatus.DISABLED)"
          [appLoading]="isLoading"
          *ngIf="this.data.status !== adminStatus.DISABLED"
        >
          Disable
        </button>
        <button
          mat-button
          class="change-status-button"
          (click)="clickUpdateStatus(adminStatus.ACTIVE)"
          [appLoading]="isLoading"
          *ngIf="this.data.status !== adminStatus.ACTIVE"
        >
          Activate
        </button>
        <button mat-button class="delete-account-button" (click)="clickDeleteButton()" [appLoading]="isLoading">
          Delete account
        </button>
      </div>
      <app-error [error]="error"></app-error>
    </div>
  </form>
</div>
