import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { StringUtils } from './string.utils';

export class RouterUtils {
  public static getLastSegmentNameOfUrl(router: Router) {
    const href = environment.apiBaseUrl.concat(router.url);
    const segments = new URL(href).pathname.split('/');
    return StringUtils.textHumanize(segments.pop() || segments.pop()); // Handle potential trailing slash
  }

  public static getUrlHash(router: Router) {
    const href = environment.apiBaseUrl.concat(router.url);
    return new URL(href).hash.slice(1); // remove first character which is #
  }
}
