import { Component, Inject, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notable, NotableAsset } from '../../models/notable';
import { AssetType, CreateAssets, ReassignAssets } from '../../models/asset';
import { UploadScope } from '../../models/upload';
import { AssetResponseDto, AssetsService } from '../../services/assets.service';
import { ToastService } from '../../services/toast.service';
import { UploadService } from '../../services/upload.service';
import { FileDetails } from '../upload/upload.component';
import { HttpResultsScope } from '../select-http-input/select-http-input.component';
import { EditSoundtracksCategoriesDialogData } from '../select-soundtrack-category-header/select-soundtrack-category-header.component';
import { FileService } from '../../services/file.service';

export interface UploadAssetDialogData extends EditSoundtracksCategoriesDialogData {
  assetType: AssetType;
  user?: Notable;
  isGeneral: boolean;
  isReassign: boolean;
  partialAssets?: AssetResponseDto[];
}

@Component({
  selector: 'app-upload-assets-dialog',
  templateUrl: './upload-assets-dialog.component.html',
  styleUrls: ['./upload-assets-dialog.component.scss'],
})
export class UploadAssetsDialogComponent implements OnInit {
  assets: FileDetails[];
  error: any;
  isEditingCategories = false;
  isLoading = false;
  uploadTitle: string;
  selectedNotableId: string;
  assetType = AssetType;
  selectedSoundtrackCategoryName: string;
  httpResultsScope = HttpResultsScope;
  queryParamsUploadSoundtracks = {
    assetType: AssetType.audio,
  };
  selectedSoundtrackCategoryId: string;

  constructor(
    public dialogRef: MatDialogRef<UploadAssetsDialogComponent>,
    private uploadService: UploadService,
    private assetsService: AssetsService,
    private fileService: FileService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: UploadAssetDialogData
  ) {
    if (this.data?.user?._id) {
      this.selectedNotableId = this.data.user._id;
    }
  }

  ngOnInit() {
    if (this.data.isReassign) {
      this.uploadTitle = 'Move to...';
      return;
    }

    switch (this.data.assetType) {
      case AssetType.audio:
        this.uploadTitle =
          this.data.isGeneral && !this.data.user ? 'Upload general soundtracks' : 'Upload custom soundtracks';
        break;
      case AssetType.video:
        this.uploadTitle = 'Upload background videos';
        break;
      case AssetType.brush:
        this.uploadTitle = 'Upload brush assets';
        break;

      default:
        break;
    }
  }

  cancel() {
    this.assets = [];
    this.dialogRef.close();
  }

  async upload() {
    this.isLoading = true;
    try {
      const assetsKeys: NotableAsset[] = [];
      let scope: UploadScope;
      switch (this.data.assetType) {
        case AssetType.audio:
          scope = this.data.isGeneral ? UploadScope.generalSoundtrack : UploadScope.notableCustomSoundtrack;
          break;
        case AssetType.video:
          scope = UploadScope.notableBackgroundVideo;
          break;
        case AssetType.brush:
          scope = this.data.isGeneral ? UploadScope.generalBrush : UploadScope.notableCustomBrush;
          break;

        default:
          break;
      }
      await Promise.all(
        this.assets.map(async (item) => {
          const uploadAssetFile = await this.uploadService.uploadFile({
            name: item.filename,
            scope,
            file: item.file,
            metadata: {
              filename: item.filename,
              size: item.size,
              duration: item.duration,
            },
          });

          let uploadThumbnailFileKey: string;
          if (this.data.assetType === AssetType.video || this.data.assetType === AssetType.brush) {
            const thumbnailBlob = (await this.fileService.getVideoThumbnail(item.file)) as Blob;
            const thumbnailFile = new File([thumbnailBlob], `thumbnail for ${item.filename}`, { type: 'image/jpeg' });
            const uploadThumbnailFile = await this.uploadService.uploadFile({
              name: thumbnailFile.name,
              scope: UploadScope.videoThumbnail,
              file: thumbnailFile,
              metadata: {
                filename: thumbnailFile.name,
                size: thumbnailFile.size,
              },
            });
            uploadThumbnailFileKey = uploadThumbnailFile.key;
          }

          assetsKeys.push({
            key: uploadAssetFile.key,
            name: uploadAssetFile.upload.metadata.filename,
            type: this.data.assetType,
            ...(uploadThumbnailFileKey && { thumbnailKey: uploadThumbnailFileKey }),
            ...(item.backgroundTextPosition && { backgroundTextPosition: item.backgroundTextPosition }),
          });
        })
      );
      const createAsset: CreateAssets = {
        assetsKeys,
        ...(this.selectedNotableId && { notableId: this.selectedNotableId }),
        ...(this.data.isGeneral &&
          this.selectedSoundtrackCategoryId && {
            soundtrackCategoryId: this.selectedSoundtrackCategoryId,
          }),
      };
      await this.assetsService.addOne(createAsset);
      this.dialogRef.close();
      this.toastService.showSuccess('Assets updated');
    } catch (error) {
      const customError = error as HttpErrorResponse;
      if (
        customError?.error &&
        typeof customError.error === 'string' &&
        customError.error?.includes('EntityTooLarge')
      ) {
        switch (this.data.assetType) {
          case AssetType.video:
            this.toastService.showError('Your upload exceeds the maximum allowed size of 300 MB');
            break;
          case AssetType.audio:
            this.toastService.showError('Your upload exceeds the maximum allowed size of 80 MB');
            break;
          case AssetType.brush:
            this.toastService.showError('Your upload exceeds the maximum allowed size of 300 MB');
            break;
          default:
            this.toastService.showError('Your upload exceeds the maximum allowed size');
            break;
        }
        return;
      }
      this.toastService.showError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async reassignItems() {
    try {
      this.isLoading = true;
      const reassignAssets: ReassignAssets = {
        assetsKeys: this.data.partialAssets,
        ...(this.selectedNotableId && { notableId: this.selectedNotableId }),
        ...(this.selectedSoundtrackCategoryId && {
          soundtrackCategoryId: this.selectedSoundtrackCategoryId,
        }),
      };
      await this.assetsService.updateManyData(reassignAssets);
      this.dialogRef.close(true);
      this.toastService.showSuccess('Assets updated');
    } catch (error) {
      this.toastService.showError(error);
    } finally {
      this.isLoading = false;
    }
  }
}
