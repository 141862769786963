import { BaseUser } from './user';
import { Notable } from './notable';

export enum AdminType {
  SUPERADMIN = 'superadmin',
  CONCIERGE = 'concierge',
  BROKER = 'broker',
}

export enum AdminStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  INVITED = 'invited',
}

export const AdminTypeAll = Object.values(AdminType);

export interface Admin extends BaseUser {
  status: AdminStatus;
  type: AdminType;
  email: string;
}

export interface Concierge extends Admin {
  clients?: number;
  brokers?: number;
  conciergeClients?: Notable[];
  averageTake?: number;
}

export interface Broker extends Admin {
  clients?: number;
  brokerClients?: Notable[];
  averageTake?: number;
}
