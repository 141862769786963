import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SoundtrackAssetsCategory, SoundtrackCategoryService } from '../../services/soundtrack-category.service';
import { EditSoundtracksCategoriesDialogComponent } from '../edit-soundtracks-categories-dialog/edit-soundtracks-categories-dialog.component';

export interface EditSoundtracksCategoriesDialogData {
  showCloseIcon?: boolean;
  soundtrackCategories?: SoundtrackAssetsCategory[];
}

@Component({
  selector: 'app-select-soundtrack-category-header',
  templateUrl: './select-soundtrack-category-header.component.html',
  styleUrls: ['./select-soundtrack-category-header.component.scss'],
})
export class SelectSoundtrackCategoryHeaderComponent implements OnInit {
  @Input() showCloseIconInput = true;
  @Input() soundtrackCategoriesInput: SoundtrackAssetsCategory[];

  constructor(public dialog: MatDialog, private soundtrackCategoryService: SoundtrackCategoryService) {
    soundtrackCategoryService.hasSavedChanges = false;
  }

  ngOnInit(): void {}

  openEditCategoriesDialog() {
    const data: EditSoundtracksCategoriesDialogData = {
      showCloseIcon: this.showCloseIconInput,
      soundtrackCategories: this.soundtrackCategoriesInput,
    };
    this.dialog.open(EditSoundtracksCategoriesDialogComponent, {
      width: '480px',
      data,
      autoFocus: true,
    });
  }
}
