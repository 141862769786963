<div class="add-admin-wrapper">
  <div class="dialog-header">
    <h1 mat-dialog-title>Add {{ tabService.tabEntity }}</h1>
    <button cdkFocusInitial mat-dialog-close class="close-button"><img src="/assets/images/icons/close.svg" /></button>
  </div>

  <form #userForm="ngForm" class="dialog-form">
    <div mat-dialog-content>
      <div class="user-image-and-details">
        <div class="user-image-camera">
          <div class="form-group">
            <app-upload
              [accept]="acceptedMimeTypes.imageJpeg + ',' + acceptedMimeTypes.imagePng"
              (change)="fileDetails = $event"
              [dataType]="'image'"
              [uploadKey]="data?.profilePictureKey"
              [showInfo]="false"
              dataUrl="/assets/images/user-placeholder.svg"
            >
            </app-upload>
          </div>
        </div>
        <div class="user-name-details-type">
          <div class="form-group">
            <label for="email">Email address</label>
            <input
              [(ngModel)]="data.email"
              type="text"
              id="email"
              name="email"
              placeholder="Enter email"
              maxlength="40"
              #email
              required
              autocomplete="do-not-autofill"
            />
            <app-input-error>{{ email.validationMessage }}</app-input-error>
          </div>
          <div class="user-name-details">
            <div class="form-group">
              <label for="firstName">First name</label>
              <!-- hidden input needed for disabling autofill -->
              <input class="hide-element-display" type="text" name="email" />
              <input
                [(ngModel)]="data.firstName"
                type="text"
                id="firstName"
                name="firstName"
                placeholder="Enter first name"
                maxlength="40"
                #firstName
                required
                autocomplete="do-not-autofill"
              />
              <app-input-error>{{ firstName.validationMessage }}</app-input-error>
            </div>
            <div class="form-group">
              <label for="lastName">Last name</label>
              <input
                [(ngModel)]="data.lastName"
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Enter last name"
                maxlength="40"
                #lastName
                required
                autocomplete="do-not-autofill"
              />
              <app-input-error>{{ lastName.validationMessage }}</app-input-error>
            </div>
          </div>
        </div>
      </div>

      <div mat-dialog-actions>
        <button
          mat-button
          [class]="'save-changes-button'"
          [disabled]="userForm.invalid"
          (click)="onSaveChanges()"
          [appLoading]="isLoading"
        >
          Done
        </button>
      </div>
      <app-error [error]="error"></app-error>
    </div>
  </form>
</div>
