<div
  class="container"
  appDragDrop
  [appLoading]="isLoading"
  [class.condensed]="condensed"
  (click)="fileInput.click()"
  (fileDropped)="onFileSelected($event['0'])"
>
  <div
    *ngIf="!hideFile && (fileDetails?.dataUrl || dataUrl)"
    class="cursor-pointer"
    [class]="showSmallUploadArea ? 'img-wrapper center' : 'page-photo-upload'"
    [class.initial-image]="(!!dataUrl && !fileDetails) || !showInfo"
    [ngClass]="shape"
    [class.cursor-default-important]="!!fileDetails"
  >
    <img
      *ngIf="(fileDetails?.type | isImage) || (dataType | isImage)"
      class="picture"
      [src]="fileDetails?.dataUrl || dataUrl"
    />

    <section [class.hide-element-display]="!isVideoFile()">
      <div class="video-wrapper" [appLoading]="isLoading">
        <video #videoElement>
          <source [src]="fileDetails?.dataUrl || dataUrl" />
          Cannot play video file
        </video>
        <img
          [src]="
            !isLoading && !videoElement.paused
              ? '/assets/images/icons/pause-video-overlay.svg'
              : '/assets/images/icons/play-video-notable.svg'
          "
          [matTooltip]="!isLoading && !videoElement.paused ? 'Pause video' : 'Play video'"
          (click)="playVideo()"
          class="cursor-pointer play-icon"
        />
        <div *ngIf="videoElement" class="video-duration">
          <span *ngIf="!videoElement.duration" [appLoading]="!videoElement.duration"></span>
          <span *ngIf="videoElement.duration"> {{ videoElement.duration | duration }} </span>
        </div>
      </div>
    </section>

    <audio *ngIf="(fileDetails?.type | isAudio) || (dataType | isAudio)" controls style="width: 100%">
      <source [src]="fileDetails?.dataUrl || dataUrl" />
    </audio>
    <div class="actions cursor-pointer" *ngIf="showActions">
      <img src="/assets/images/icons/trash-rounded.svg" matTooltip="Remove file" (click)="onCancel($event)" />
    </div>
  </div>
  <ng-container *ngIf="showSmallUploadArea">
    <div
      *ngIf="!fileDetails?.dataUrl && !dataUrl && userFullName"
      class="cursor-pointer"
      [class]="showSmallUploadArea ? 'img-wrapper center' : 'page-photo-upload'"
      [class.initial-image]="(!!dataUrl && !fileDetails) || !showInfo"
      [ngClass]="shape"
    >
      <app-avatar
        width="140"
        height="140"
        [type]="'circle'"
        [initialsFontSize]="'48px'"
        [userFullName]="userFullName"
      ></app-avatar>
    </div>
    <button class="photo-button">
      <img src="/assets/images/icons/choose-photo.svg" />
    </button>
  </ng-container>

  <ng-container *ngIf="!showSmallUploadArea">
    <div
      class="center no-file cursor-pointer {{ dataType }}"
      *ngIf="(!fileDetails && !dataUrl) || hideFile"
      [class.has-image]="dataUrl"
      [ngClass]="shape"
    >
      <div class="upload-text-and-icon">
        <p class="message" *ngIf="message">{{ message }}</p>
        <ng-container *ngIf="!allowBrowseFiles">
          <img [src]="'/assets/images/icons/' + uploadIcon" />
        </ng-container>
        <ng-container *ngIf="allowBrowseFiles">
          <p>or</p>
          <div class="browse-files-text">
            <p>Browse files</p>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div *ngIf="!hideFile && fileDetails && showInfo" class="file-info" (click)="$event.stopPropagation()">
    <span class="type">{{ fileDetails.extension }}</span>
    <p class="name">{{ fileDetails?.filename }}</p>
    <p class="size">
      {{ fileDetails?.size | filesize }}
      <ng-container *ngIf="fileDetails.duration"> {{ fileDetails.duration | duration }}</ng-container>
      <ng-container *ngIf="fileDetails.width"> ({{ fileDetails.width }} x {{ fileDetails.height }}px) </ng-container>
    </p>
  </div>

  <input
    type="file"
    #fileInput
    [(ngModel)]="selectedFile"
    [accept]="accept"
    (change)="onFileSelected($event.target.files['0'])"
    style="display: none"
    [disabled]="!!fileDetails"
  />
</div>
