import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SuccessDialogService } from 'src/app/shared/services/success-dialog.service';
import { CustomValidators } from 'src/app/shared/utils/custom-validators';

@UntilDestroy()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  password: string;
  samepassword: string;
  hidePassword = true;
  hideSamePassword = true;
  isLoading: boolean;
  error: any;
  token: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private successDialogService: SuccessDialogService
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params.id) {
        this.token = params.id;
      }
    });
  }

  async resetPassword() {
    if (this.isLoading) return;
    try {
      this.error = null;
      this.isLoading = true;

      if (this.password !== this.samepassword) {
        throw new Error('Passwords must match!');
      }

      if (!CustomValidators.isPasswordValid(this.password)) {
        throw new Error(
          'Your password must be at least 8 characters long, must contain at least 1 number, 1 lowercase letter, 1 uppercase letter and 1 special character.'
        );
      }

      await this.authService.changeAdminPasswordByEmailToken(this.token, this.password);
      this.successDialogService.openSuccessDialog();
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }
}
