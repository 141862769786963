<article class="entry-page">
  <app-logo></app-logo>
  <section>
    <div class="form-group">
      <p class="title">Forgot password?</p>

      <label for="email">Your email address</label>
      <!-- hidden input needed for disabling autofill -->
      <input class="hide-element-display" type="text" name="email" />
      <input
        [(ngModel)]="email"
        type="email"
        id="email"
        name="email"
        (keyup.enter)="resetPassword()"
        placeholder="Enter your email"
        autocomplete="do-not-autofill"
      />
      <!-- hidden input needed for disabling autofill -->
      <input class="hide-element-display" type="text" name="email" />
    </div>
    <p>We’ll send you the reset instructions to the address you used to create your account with.</p>
    <button mat-flat-button color="primary" (click)="resetPassword()" [disabled]="!email">Send instructions</button>
    <app-error [error]="error"></app-error>
  </section>
</article>
