<div *ngIf="asset.type === AssetType.video" class="video-details" [appLoading]="isLoading">
  <video [id]="assetId" [poster]="this.asset.thumbnailUrl || ''" #element>
    <source *ngIf="isAssetLoaded" type="video/mp4" [src]="asset.url || asset.key" />
    Cannot play video file
  </video>

  <img
    [src]="
      !isLoading && !element.paused
        ? '/assets/images/icons/pause-video-overlay.svg'
        : '/assets/images/icons/play-video.svg'
    "
    [matTooltip]="!isLoading && !element.paused ? 'Pause video' : 'Play video'"
    (click)="loadAsset()"
    class="cursor-pointer control-icon"
  />
</div>

<div *ngIf="asset.type === AssetType.audio" class="audio-details">
  <audio [src]="asset.url || asset.key" #element></audio>
  <div [appLoading]="isLoading" class="audio-play-wrapper">
    <button>
      <img
        [src]="
          !isLoading && !element.paused ? '/assets/images/icons/round-pause.svg' : '/assets/images/icons/round-play.svg'
        "
        [matTooltip]="!isLoading && !element.paused ? 'Pause audio' : 'Play audio'"
        (click)="loadAsset()"
        class="cursor-pointer"
      />
    </button>
    <svg *ngIf="asset.type === AssetType.audio" class="progress" viewBox="0 0 28 28">
      <circle
        class="progress-circle"
        #progressCircle
        cx="14"
        cy="14"
        stroke="#BD884B"
        r="13"
        fill="transparent"
        stroke-width="2"
      />
    </svg>
  </div>
</div>

<div *ngIf="asset.type === AssetType.brushDefinition" class="brush-definition-details">
  <img [src]="'/assets/' + asset.thumbnailUrl" />
</div>
