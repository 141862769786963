<section class="section-wrapper">
  <div class="form-header">
    <app-logo></app-logo>
    <h1 *ngIf="mode === 'edit'">Edit profile</h1>
    <h1 *ngIf="mode === 'add'">Add a new notable to your catalog</h1>
    <div class="close-wrapper">
      <button class="close-button cursor-pointer" (click)="closeAddOrEditNotable(false)">
        <img src="/assets/images/icons/close.svg" />
      </button>
    </div>
  </div>
  <div class="form-wrapper">
    <form #userForm="ngForm" (ngSubmit)="onSubmit()" [appLoading]="isLoading">
      <div class="form-box">
        <p>Notable information</p>
        <div class="full-name">
          <div class="item">
            <label for="firstName">First name</label>
            <input
              [(ngModel)]="notable.firstName"
              type="text"
              placeholder="First name"
              id="firstName"
              name="firstName"
              required
              maxlength="200"
              autocomplete="do-not-autofill"
            />
            <app-input-error>First name is invalid.</app-input-error>
          </div>
          <div class="item">
            <label for="lastName">Last name</label>
            <!-- hidden input needed for disabling autofill -->
            <input class="hide-element-display" type="text" name="email" />
            <input
              [(ngModel)]="notable.lastName"
              type="text"
              placeholder="Last name"
              id="lastName"
              name="lastName"
              required
              maxlength="200"
              autocomplete="do-not-autofill"
            />
            <app-input-error>Last name is invalid.</app-input-error>
          </div>
        </div>

        <div class="item">
          <label for="profession">Profession</label>
          <ng-select
            [(ngModel)]="notable.profession"
            [items]="notableProfession"
            placeholder="Select profession"
            name="profession"
            required
          >
          </ng-select>
          <app-input-error>Profession is invalid.</app-input-error>
        </div>
        <div *ngIf="mode === 'add'" class="item">
          <label for="phone">Phone number (optional)</label>
          <input
            [(ngModel)]="notable.phone"
            type="phone"
            placeholder="i.e. 2124567890"
            id="phone"
            name="phone"
            validPhoneNumber
            autocomplete="do-not-autofill"
          />

          <app-input-error>Phone number is invalid.</app-input-error>
        </div>
        <div *ngIf="mode === 'add'" class="item overflow-auto">
          <label for="email">Notable Email (optional)</label>
          <input
            [(ngModel)]="notable.email"
            type="email"
            id="email"
            name="email"
            maxlength="100"
            email
            placeholder="i.e. brad.pitt@global.com"
            autocomplete="do-not-autofill"
          />
          <app-input-error>Please enter valid email address.</app-input-error>
        </div>
      </div>
      <div class="form-box">
        <p>Profile photo</p>
        <div class="item"><label> This will be displayed on the notable's official profile on Graph. </label></div>
        <div class="grid-center-column-items item-upload">
          <app-upload
            [showSmallUploadArea]="false"
            [showInfo]="false"
            [showActions]="true"
            [accept]="acceptedMimeTypes.imageAccepted"
            (change)="handleChangeProfilePicture($event)"
            [fileDetails]="profilePictureFile"
            message="Upload profile picture"
            dataType="image"
          ></app-upload>
          <!-- <app-upload
            [showSmallUploadArea]="false"
            [showInfo]="false"
            [showActions]="true"
            [accept]="acceptedMimeTypes.videoAccepted"
            (change)="handleChangeIntroVideo($event)"
            [fileDetails]="introVideoFile"
            dataType="video"
            uploadIcon="choose-video.svg"
            message="Upload intro video"
            dataType="video"
          ></app-upload> -->
        </div>
      </div>

      <div class="form-box relative">
        <p>Notable bio</p>
        <div class="item">
          <textarea
            [(ngModel)]="notable.bio"
            id="bio"
            name="bio"
            placeholder="Write a short summary of the artist..."
            [maxlength]="notableBioTextLength"
            required
            #notableBio
          ></textarea>
          <app-input-error>Notable bio is mandatory.</app-input-error>
          <div class="notable-bio-text">
            <span>{{ notableBio.textLength }}</span
            ><span>/{{ notableBioTextLength }}</span>
          </div>
        </div>
      </div>

      <div class="form-box">
        <p>Graph price</p>
        <div class="item">
          <div class="input-with-icon graph-price-box">
            <span>$</span>
            <input
              [(ngModel)]="notable.graphPrice"
              type="graphPrice"
              id="graphPrice"
              name="graphPrice"
              required
              numbersOnly
              greaterThanZero
              placeholder="0"
              min="1"
              maxlength="6"
              autocomplete="do-not-autofill"
            />
            <app-input-error>Graph price is invalid.</app-input-error>
          </div>
        </div>
      </div>

      <div class="form-box">
        <p>Graph price with voice over (optional)</p>
        <div class="item">
          <div class="input-with-icon graph-price-box">
            <span>$</span>
            <input
              [(ngModel)]="notable.graphWithVoiceOverPrice"
              type="graphWithVoiceOverPrice"
              id="graphWithVoiceOverPrice"
              name="graphWithVoiceOverPrice"
              numbersOnly
              placeholder="0"
              min="0"
              maxlength="6"
              autocomplete="do-not-autofill"
            />
            <app-input-error>Graph price with voice over is invalid.</app-input-error>
          </div>
        </div>
        <label *ngIf="mode === 'edit'">To remove graph price with voice over, set the input value to 0.</label>
      </div>

      <div class="form-box">
        <p>{{ isSuperadmin ? "Broker's take" : 'Profit take' }}</p>
        <div class="item">
          <div class="input-with-icon profit-take-box">
            <span>%</span>
            <input
              [(ngModel)]="notable.brokerTake"
              type="brokerTake"
              id="brokerTake"
              name="brokerTake"
              required
              numbersOnly
              placeholder="0"
              maxlength="2"
              autocomplete="do-not-autofill"
            />
            <app-input-error>Broker take is invalid.</app-input-error>
          </div>
          <label
            >The percentage {{ isSuperadmin ? 'the broker takes' : 'you take' }} from the sale of each graph.</label
          >
        </div>
      </div>

      <div class="form-box">
        <p>Max requests until unavailable</p>
        <div class="item">
          <div class="profit-take-box">
            <input
              [(ngModel)]="notable.maxGraphRequestsCount"
              type="maxGraphRequestsCount"
              id="maxGraphRequestsCount"
              name="maxGraphRequestsCount"
              required
              numbersOnly
              placeholder="0"
              maxlength="6"
              class="text-left"
              autocomplete="do-not-autofill"
            />
            <app-input-error>Graph request count is invalid.</app-input-error>
          </div>
          <label> The maximum number of graphs the notable will sign. </label>
        </div>
      </div>

      <div class="form-box">
        <p>Available for signing</p>
        <div class="item">
          <ng-select
            [(ngModel)]="notable.availableForSigning"
            [items]="notableAvailableForSigning"
            placeholder="Select availability"
            name="availableForSigning"
            required
          >
          </ng-select>
          <app-input-error>Available for signing is invalid.</app-input-error>
        </div>
        <label> This will be displayed to users if notable is available for signing. </label>
      </div>

      <!-- <div *ngIf="mode === 'add'" class="form-box">
        <p>Upload Graph background videos (optional)</p>
        <div class="item">
          <label for="graphAssetUrl"
            >Will be used by the notable for creating graphs. Videos should have a resolution of 1920 x 1080.</label
          >
        </div>
        <div *ngIf="!backgroundVideosFiles.length" class="grid-center-row-items">
          <app-upload-asset
            [assetType]="assetType.video"
            [isAddingNotable]="true"
            (changeFiles)="onUploadBackgroundVideos($event)"
          >
          </app-upload-asset>
        </div>
        <div *ngIf="backgroundVideosFiles.length" class="grid-center-row-items item-upload assets">
          <ng-container *ngFor="let file of backgroundVideosFiles; index as i">
            <app-upload
              [showSmallUploadArea]="false"
              [showInfo]="false"
              [showActions]="true"
              [accept]="acceptedMimeTypes.videoAccepted"
              (change)="onRemoveBackgroundVideos($event, i)"
              [fileDetails]="file"
              message="Upload asset"
              uploadIcon="video.svg"
              dataType="video"
            ></app-upload>
          </ng-container>
        </div>
      </div> -->

      <app-error [error]="error"></app-error>

      <div class="actions">
        <button
          *ngIf="mode === 'add'"
          mat-flat-button
          color="primary"
          type="submit"
          [appLoading]="isLoading"
          [disabled]="!userForm.form.valid || !profilePictureFile"
        >
          Add notable
        </button>
        <button
          *ngIf="mode === 'edit'"
          mat-flat-button
          color="primary"
          type="submit"
          [appLoading]="isLoading"
          [disabled]="
            (!userForm.form.valid || !userForm.form.dirty) &&
            ((!hasChangedAssets.hasChangedProfilePicture && !hasChangedAssets.hasChangedIntroVideo) ||
              !userForm.form.valid)
          "
        >
          Save changes
        </button>
      </div>
    </form>
  </div>
</section>
