import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { formatNumber, parseNumber, ParsedNumber } from 'libphonenumber-js';

import { AcceptedMimeTypes, FileDetails } from 'src/app/shared/components/upload/upload.component';
import {
  Notable,
  NotableAsset,
  NotableAvailableForSigningStatus,
  NotableProfession,
  NotableStatus,
} from 'src/app/shared/models/notable';
import { NotableService } from 'src/app/shared/services/notable.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { StringUtils } from 'src/app/shared/utils/string.utils';
import { CustomValidators } from 'src/app/shared/utils/custom-validators';
import { UploadService } from 'src/app/shared/services/upload.service';
import { UploadScope } from 'src/app/shared/models/upload';
import { TableUtils } from 'src/app/shared/utils/table.utils';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuccessScreenComponent } from 'src/app/core/success-screen/success-screen.component';
import { AssetType, CreateAssets } from 'src/app/shared/models/asset';
import { AssetsService } from 'src/app/shared/services/assets.service';
import { FileService } from 'src/app/shared/services/file.service';
import { AddOrEditNotableProfileDialog } from '../overview-user-profile-notable/notable-details/notable-details.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorUtils } from 'src/app/shared/utils/error.utils';
import { AuthService } from '../../../../shared/services/auth.service';
import { AdminType } from '../../../../shared/models/admin';

export interface CreateUserProfileDialog {
  notableId?: string;
  hasCreatedAdminAccount?: boolean;
}

@Component({
  selector: 'app-add-or-edit-notable',
  templateUrl: './add-or-edit-notable.component.html',
  styleUrls: ['./add-or-edit-notable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddOrEditNotableComponent {
  @Input() isLoading = false;
  @Input() notable: Partial<Notable> = {
    firstName: '',
    lastName: '',
    profession: undefined,
    phone: undefined,
    email: undefined,
    bio: '',
    graphPrice: undefined,
    graphWithVoiceOverPrice: undefined,
    brokerTake: undefined,
  };

  error: any;
  notableStatuses = Object.values(NotableStatus).map((item) => ({ label: item, value: item }));
  notableProfession = Object.values(NotableProfession).map((item) => ({
    label: StringUtils.capitalizeFirstLetter(item),
    value: item,
  }));
  notableAvailableForSigning = Object.values(NotableAvailableForSigningStatus).map((item) => ({
    label: StringUtils.capitalizeFirstLetter(item),
    value: item,
  }));
  acceptedMimeTypes = AcceptedMimeTypes;
  assetType = AssetType;
  mode: 'add' | 'edit' = 'edit';
  separatorKeysCodes: number[] = [ENTER, COMMA];
  isEmailFieldTouched = false;
  profilePictureFile: FileDetails;
  introVideoFile: FileDetails;
  // backgroundVideosFiles: FileDetails[] = [];
  hasChangedAssets = {
    hasChangedProfilePicture: false,
    hasChangedIntroVideo: false,
  };
  entityTooLargeMessage =
    'Your upload exceeds the maximum allowed size. Maximum allowed size for profile picture is 1 MB compressed and 300 MB for each video file.';
  notableBioTextLength = 500;

  AdminType = AdminType;

  constructor(
    private notableService: NotableService,
    private assetsService: AssetsService,
    private fileService: FileService,
    private toast: ToastService,
    private uploadService: UploadService,
    public dialogRef: MatDialogRef<AddOrEditNotableComponent>,
    public dialog: MatDialog,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public editNotableData: AddOrEditNotableProfileDialog
  ) {
    if (this.editNotableData.mode === 'add') {
      this.mode = 'add';
      return;
    }

    this.mode = 'edit';
    this.notable = { ...this.editNotableData.notable };
    if (this.notable?.profilePictureUrl) {
      this.profilePictureFile = {
        dataUrl: this.notable.profilePictureUrl,
      };
    }
    if (this.notable?.introVideoUrl) {
      this.introVideoFile = {
        dataUrl: this.notable.introVideoUrl,
      };
    }
    this.notable.graphPrice = TableUtils.convertCentsToDollars(this.notable.graphPrice);
    this.notable.graphWithVoiceOverPrice = TableUtils.convertCentsToDollars(this.notable.graphWithVoiceOverPrice);
  }

  get isSuperadmin() {
    return this.auth.user.type === AdminType.SUPERADMIN;
  }

  async onSubmit() {
    if (!this.notable.phone) delete this.notable.phone;
    if (!this.notable.email) delete this.notable.email;
    if (
      this.notable.graphWithVoiceOverPrice != null &&
      (this.notable.graphWithVoiceOverPrice.toString() === '' || this.notable.graphWithVoiceOverPrice === 0)
    ) {
      delete this.notable.graphWithVoiceOverPrice;
    }

    try {
      this.error = null;
      this.isLoading = true;

      switch (this.mode) {
        case 'edit':
          let editedUser: Partial<Notable> = {};
          const editedUserId = this.notable._id;
          if (this.notable.firstName !== this.editNotableData.notable.firstName) {
            editedUser.firstName = this.notable.firstName;
          }
          if (this.notable.lastName !== this.editNotableData.notable.lastName) {
            editedUser.lastName = this.notable.lastName;
          }
          if (this.notable.profession !== this.editNotableData.notable.profession) {
            editedUser.profession = this.notable.profession;
          }
          if (this.notable.bio !== this.editNotableData.notable.bio) {
            editedUser.bio = this.notable.bio;
          }
          if (this.notable.graphPrice !== TableUtils.convertCentsToDollars(this.editNotableData.notable.graphPrice)) {
            editedUser.graphPrice = TableUtils.convertDollarsToCents(this.notable.graphPrice);
          }
          if (
            this.notable.graphWithVoiceOverPrice !==
            TableUtils.convertCentsToDollars(this.editNotableData.notable.graphWithVoiceOverPrice)
          ) {
            editedUser.graphWithVoiceOverPrice = TableUtils.convertDollarsToCents(this.notable.graphWithVoiceOverPrice);
          }
          if (this.notable.brokerTake !== this.editNotableData.notable.brokerTake) {
            editedUser.brokerTake = parseInt(this.notable.brokerTake.toString());
          }
          if (this.notable.maxGraphRequestsCount !== this.editNotableData.notable.maxGraphRequestsCount) {
            editedUser.maxGraphRequestsCount = parseInt(this.notable.maxGraphRequestsCount.toString());
          }
          if (this.notable.availableForSigning !== this.editNotableData.notable.availableForSigning) {
            editedUser.availableForSigning = this.notable.availableForSigning;
          }
          if (this.hasChangedAssets.hasChangedProfilePicture) {
            if (this.profilePictureFile) {
              await this.uploadProfilePictureFile(editedUserId);
            } else {
              editedUser.profilePictureKey = null;
              editedUser.profilePicturePlaceholderBase64 = null;
            }
          }
          if (this.hasChangedAssets.hasChangedIntroVideo) {
            if (this.introVideoFile) {
              await this.uploadIntroVideoFile(editedUserId);
              await this.uploadIntroVideoThumbnailFile(editedUserId);
            } else {
              editedUser.introVideoKey = null;
            }
          }
          await this.notableService.updateOne(editedUserId, editedUser);
          await this.toast.show('Notable updated');
          this.closeAddOrEditNotable(true);
          break;
        case 'add':
          const oneMBInBytes = 1048576;
          if (this.profilePictureFile.file && this.profilePictureFile.file.size >= oneMBInBytes) {
            throw Error(this.entityTooLargeMessage);
          }

          if (this.introVideoFile && this.introVideoFile.size >= oneMBInBytes * 300) {
            throw Error(this.entityTooLargeMessage);
          }

          const newNotable = await this.notableService.addOne({
            ...this.notable,
            brokerTake: parseInt(this.notable.brokerTake.toString()),
            graphPrice: parseInt(TableUtils.convertDollarsToCents(this.notable.graphPrice).toString()),
            ...(this.notable.graphWithVoiceOverPrice && {
              graphWithVoiceOverPrice: parseInt(
                TableUtils.convertDollarsToCents(this.notable.graphWithVoiceOverPrice).toString()
              ),
            }),
            ...(this.notable.phone && {
              phone: formatNumber(parseNumber(this.notable.phone, 'US') as ParsedNumber, 'E.164'),
            }),
          });
          const newNotableId = newNotable._id;

          if (this.profilePictureFile) {
            await this.uploadProfilePictureFile(newNotableId);
          }
          if (this.introVideoFile) {
            await this.uploadIntroVideoFile(newNotableId);
          }

          // if (this.backgroundVideosFiles) {
          //   const assetsKeys: NotableAsset[] = [];
          //   const scope = UploadScope.notableBackgroundVideo;
          //   await Promise.all(
          //     this.backgroundVideosFiles.map(async (item) => {
          //       const uploadVideoFile = await this.uploadService.uploadFile({
          //         name: item.filename,
          //         scope,
          //         file: item.file,
          //         metadata: {
          //           filename: item.filename,
          //           size: item.size,
          //           duration: item.duration,
          //         },
          //       });
          //       const thumbnailBlob = (await this.fileService.getVideoThumbnail(item.file)) as Blob;
          //       const thumbnailFile = new File([thumbnailBlob], `thumbnail for ${item.filename}`, {
          //         type: 'image/jpeg',
          //       });
          //       const uploadThumbnailFile = await this.uploadService.uploadFile({
          //         name: thumbnailFile.name,
          //         scope: UploadScope.videoThumbnail,
          //         file: thumbnailFile,
          //         metadata: {
          //           filename: thumbnailFile.name,
          //           size: thumbnailFile.size,
          //         },
          //       });
          //       assetsKeys.push({
          //         key: uploadVideoFile.key,
          //         name: uploadVideoFile.upload.metadata.filename,
          //         type: this.assetType.video,
          //         thumbnailKey: uploadThumbnailFile.key,
          //       });
          //     })
          //   );
          //   const createAssets: CreateAssets = {
          //     assetsKeys,
          //     notableId: newNotableId,
          //   };
          //   await this.assetsService.addOne(createAssets);
          // }

          this.dialog.open(SuccessScreenComponent, {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            data: {
              notableId: newNotableId,
            },
          });
          this.closeAddOrEditNotable(true);
          break;
        default:
          break;
      }
    } catch (error) {
      const customError = error as HttpErrorResponse;
      if (
        customError?.error &&
        typeof customError.error === 'string' &&
        customError.error?.includes('EntityTooLarge')
      ) {
        this.error = this.entityTooLargeMessage;
        return;
      }
      this.error = ErrorUtils.toString(error);
    } finally {
      this.isLoading = false;
    }
  }

  closeAddOrEditNotable(hasMadeChanges: boolean) {
    document.body.classList.remove('hide-scrollbar');
    this.dialogRef.close(hasMadeChanges);
  }

  // onUploadBackgroundVideos(event) {
  //   if (!event.length) return;
  //   event.map((item) => {
  //     this.backgroundVideosFiles.push(item);
  //   });
  // }

  // onRemoveBackgroundVideos(event, index: number) {
  //   this.backgroundVideosFiles.splice(index, 1);
  // }

  handleChangeProfilePicture(event) {
    this.hasChangedAssets.hasChangedProfilePicture = true;
    if (event && event.file != null) {
      this.profilePictureFile = event;
      return;
    }
    if (event && event.hasOwnProperty('filename') && event.filename == undefined) {
      this.profilePictureFile = null;
    }
  }

  handleChangeIntroVideo(event) {
    this.hasChangedAssets.hasChangedIntroVideo = true;
    if (event && event.file != null) {
      this.introVideoFile = event;
      return;
    }
    if (event && event.hasOwnProperty('filename') && event.filename == undefined) {
      this.introVideoFile = null;
    }
  }

  async uploadIntroVideoFile(notableId: string) {
    await this.uploadService.uploadFile({
      name: this.introVideoFile.filename,
      scope: UploadScope.notableIntroVideo,
      file: this.introVideoFile.file,
      owner: notableId,
      metadata: {
        filename: this.introVideoFile.filename,
        height: this.introVideoFile.height,
        width: this.introVideoFile.width,
        size: this.introVideoFile.size,
        duration: this.introVideoFile.duration,
      },
    });
  }

  async uploadIntroVideoThumbnailFile(notableId: string) {
    const thumbnailBlob = (await this.fileService.getVideoThumbnail(this.introVideoFile.file)) as Blob;
    const thumbnailFile = new File([thumbnailBlob], `thumbnail for ${this.introVideoFile.filename}`, {
      type: 'image/jpeg',
    });
    await this.uploadService.uploadFile({
      name: thumbnailFile.name,
      scope: UploadScope.notableIntroVideoThumbnail,
      file: thumbnailFile,
      owner: notableId,
      metadata: {
        filename: thumbnailFile.name,
        size: thumbnailFile.size,
      },
    });
  }

  async uploadProfilePictureFile(notableId: string) {
    await this.uploadService.uploadFile({
      name: this.profilePictureFile.filename,
      scope: UploadScope.notableProfilePicture,
      file: this.profilePictureFile.file,
      owner: notableId,
      metadata: {
        filename: this.profilePictureFile.filename,
        height: this.profilePictureFile.height,
        width: this.profilePictureFile.width,
        size: this.profilePictureFile.size,
      },
    });
  }
}
