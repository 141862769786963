import { Component, Input, OnChanges, OnInit } from '@angular/core';

const DEFAULT_SIZE = '40px';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() src: string;
  @Input() width: string;
  @Input() height: string;
  @Input() alt: string;
  @Input() type: 'square' | 'circle' = 'circle';
  @Input() userFullName: string;
  @Input() initialsFontSize: string;

  imgWidth: string = DEFAULT_SIZE;
  imgHeight: string = DEFAULT_SIZE;
  userInitials: string;

  constructor() {}

  ngOnInit() {
    if (this.userFullName) {
      this.userInitials = (
        this.userFullName[0] + this.userFullName.split(' ')[this.userFullName.split(' ').length - 1][0]
      ).toUpperCase();
    }
  }

  ngOnChanges(): void {
    if (!this.width && !this.height) {
      this.imgWidth = DEFAULT_SIZE;
      this.imgHeight = DEFAULT_SIZE;
    }

    if (this.initialsFontSize) {
      this.imgHeight = '100%';
    }

    if (this.width && !this.height && !this.initialsFontSize) {
      this.height = this.width;
    }

    if (this.height && !this.width) {
      this.width = this.height;
    }

    if (this.width && /^\d+$/.test(this.width)) {
      this.imgWidth = this.width + 'px';
    }

    if (this.height && /^\d+$/.test(this.height)) {
      this.imgHeight = this.height + 'px';
    }
  }
}
