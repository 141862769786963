<section class="upload-asset">
  <p *ngIf="assetType === allAssetType.video || assetType === allAssetType.brush" class="accepted-formats">
    Accepted formats: .mp4
  </p>
  <p *ngIf="assetType === allAssetType.audio" class="accepted-formats">Accepted formats: .mp3</p>
  <div
    appDragDrop
    [appLoading]="isLoading"
    (click)="multipleAudioFilesInput.click()"
    (fileDropped)="onMultipleFilesSelected($event)"
    class="placeholder-asset cursor-pointer"
    [class.small-box]="pendingUploadFiles.length"
    [class.large-box]="!pendingUploadFiles.length"
    [class.is-adding-notable]="isAddingNotable"
  >
    <div *ngIf="!isAddingNotable" class="upload-text-and-icon" [class.resize]="pendingUploadFiles.length">
      <div class="center">
        <img src="/assets/images/icons/{{ iconDisplay }}.svg" />
      </div>
      <p class="message">Drag and drop {{ fileCategoryMessage }} here or</p>
      <div class="browse-files-text">
        <div class="text-wrapper">
          <p>Browse files</p>
        </div>
      </div>
    </div>

    <div *ngIf="isAddingNotable" class="upload-text-and-icon is-adding-notable">
      <p class="message">Upload assets</p>
      <div class="center">
        <img src="/assets/images/icons/choose-video.svg" />
      </div>
    </div>

    <input
      type="file"
      #multipleAudioFilesInput
      multiple
      style="display: none"
      [accept]="acceptedFiles"
      (change)="onMultipleFilesSelected($event.target.files)"
      (click)="$event.target.value = null"
    />
  </div>

  <div
    *ngIf="pendingUploadFiles.length"
    class="uploaded-files thin-scrollbar"
    [class.is-notable-selected]="isNotableSelected"
    [class.is-brush-asset-and-general-tab]="assetType === allAssetType.brush && isGeneralTab"
  >
    <ng-container *ngFor="let pendingAsset of pendingUploadFiles; index as i">
      <app-asset
        [isOnModal]="true"
        [shouldPreloadAsset]="true"
        [isLocalFile]="true"
        [givenAsset]="pendingAsset"
        (saveRenameChange)="onSaveRename($event, i)"
        (removeItemChange)="onRemove($event, i)"
        (backgroundTextPositionChange)="onBackgroundPositionChange($event, i)"
      ></app-asset>
    </ng-container>
  </div>
</section>
