import { BaseModel } from './base-model';

export enum UserType {
  notable = 'notable',
}

export const UserTypeAll = Object.values(UserType);

export enum UserStatus {
  active = 'active',
  blocked = 'blocked',
  reported = 'reported',
  suspended = 'suspended',
}

export interface BaseUser extends BaseModel {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  lastActivity?: Date;
  profilePictureUrl?: string;
  profilePictureKey?: string;
  profilePicturePlaceholderBase64?: string;
  // only for editing
  password?: string;
}

export interface User extends BaseUser {
  status: UserStatus;
  type: UserType;
  email: string;
}
