import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-selection-text',
  templateUrl: './item-selection-text.component.html',
  styleUrls: ['./item-selection-text.component.scss'],
})
export class ItemSelectionTextComponent implements OnInit {
  @Input() count: number;

  constructor() {}

  ngOnInit(): void {}
}
