import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { Asset } from '../models/asset';
import { firstValueFrom } from 'rxjs';

export type BaseAssetResponseDto = Pick<
  Asset,
  '_id' | 'name' | 'key' | 'thumbnailKey' | 'type' | 'soundtrackCategoryId' | 'backgroundTextPosition'
>;

export interface AssetResponseDto extends BaseAssetResponseDto {
  url?: string;
  thumbnailUrl?: string;
  soundtrackCategoryName?: string;
}

export type NotableAssets = { backgrounds: AssetResponseDto[]; soundtracks: AssetResponseDto[] };

@Injectable({
  providedIn: 'root',
})
export class AssetsService extends CrudService<Asset> {
  constructor(http: HttpClient) {
    super(http, 'assets');
  }

  getByNotableId(notableId: string) {
    return firstValueFrom(this.http.get<NotableAssets>(`${this.endpoint}/notable/${notableId}`));
  }
}
