export interface Upload {
  _id: string;
  key: string;
  url: string;
  mimetype: string;
  scope: string;
  metadata?: {
    filename?: string; // original file name
    size?: number; // size in bytes
    width?: number; // pixels - only on images or videos
    height?: number; // pixels - only on images or videos
    duration?: number; // seconds - only on audios or videos
  };
}

export interface UploadRequest {
  name: string;
  scope: UploadScope;
  owner?: string;
  file: File;
  metadata?: Upload['metadata'];
}

export interface UploadResponse {
  url: string;
  key: string;
  upload: Upload;
  [key: string]: any;
}

export interface UploadData {
  upload: {
    url: string;
    fields: {
      'Content-Type': string;
      key: string;
      success_action_redirect: string;
      bucket: string;
      'X-Amz-Algorithm': string;
      'X-Amz-Credential': string;
      'X-Amz-Date': string;
      Policy: string;
      'X-Amz-Signature': string;
    };
  };
}

export enum UploadScope {
  userProfilePicture = 'userProfilePicture',
  adminProfilePicture = 'adminProfilePicture',
  generalBrush = 'generalBrush',
  generalSoundtrack = 'generalSoundtrack',
  videoThumbnail = 'videoThumbnail',
  // notable scopes
  notableIntroVideo = 'notableIntroVideo',
  notableIntroVideoThumbnail = 'notableIntroVideoThumbnail',
  notableSignature = 'notableSignature',
  notableProfilePicture = 'notableProfilePicture',
  notableCustomSoundtrack = 'notableCustomSoundtrack',
  notableBackgroundVideo = 'notableBackgroundVideo',
  notableCustomBrush = 'notableCustomBrush',
}
