import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEnGb from '@angular/common/locales/en-GB';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { FiltersComponent } from './components/filters/filters.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { QueryFiltersComponent } from './components/query-filters/query-filters.component';
import { TooltipRendererDirective } from './directives/custom-tooltip.directive';
import { ImagePreloaderDirective } from './directives/image-preloader.directive';
import { LoadingDirective } from './directives/loading.directive';
import { TextHumanizePipe } from './pipes/text-humanize.pipe';
import { InputErrorComponent } from './components/input-error/input-error.component';
import { BackComponent } from './components/back/back.component';
import { UploadComponent } from './components/upload/upload.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { FileSizePipe } from './pipes/file-size.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { IsImagePipe } from './pipes/is-image.pipe';
import { IsAudioPipe } from './pipes/is-audio.pipe';
import { IsVideoPipe } from './pipes/is-video.pipe';
import { MoneyTransformPipe } from './pipes/money-transform.pipe';
import { ArraySumPipe } from './pipes/array-sum.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectFilterComponent } from './components/select-filter/select-filter.component';
import { SelectHttpFilterComponent } from './components/select-http-filter/select-http-filter.component';
import { SelectHttpInputComponent } from './components/select-http-input/select-http-input.component';
import { DateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDateFormats, MatNativeDateModule, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { EmojiInputComponent } from './components/emoji-input/emoji-input.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DragCursorDirective } from './directives/drag-cursor.directive';
import { ProfileDialogComponent } from './components/profile-dialog/profile-dialog.component';
import { NotableTableComponent } from './components/notable-table/notable-table.component';
import { MoreActionsComponent } from './components/more-actions/more-actions.component';
import { ViewAsBannerComponent } from './components/view-as-banner/view-as-banner.component';
import { TrackRecordComponent } from './components/track-record/track-record.component';
import { AddAdminDialogComponent } from './components/add-admin-dialog/add-admin-dialog.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { ValidPhoneNumberDirective } from './directives/valid-phone-number.directive';
import { GreaterThanZeroDirective } from './directives/greater-than-zero.directive';
import { EditAdminDialogComponent } from './components/edit-admin-dialog/edit-admin-dialog.component';
import { TimeDisplayPipe } from './pipes/time-display.pipe';
import { PasswordConstraintsComponent } from './components/password-constraints/password-constraints.component';
import { UploadAssetsDialogComponent } from './components/upload-assets-dialog/upload-assets-dialog.component';
import { UploadAssetComponent } from './components/upload-asset/upload-asset.component';
import { AssetComponent } from './components/asset/asset.component';
import { VideoOverlayComponent } from './components/video-overlay/video-overlay.component';
import { TrimFileExtensionPipe } from './pipes/trim-file-extension.pipe';
import { QRCodeModule } from 'angularx-qrcode';
import { MoreActionsAssetsComponent } from './components/more-actions-assets/more-actions-assets.component';
import { SelectSoundtrackCategoryHeaderComponent } from './components/select-soundtrack-category-header/select-soundtrack-category-header.component';
import { EditSoundtracksCategoriesDialogComponent } from './components/edit-soundtracks-categories-dialog/edit-soundtracks-categories-dialog.component';
import { ItemSelectionTextComponent } from './components/item-selection-text/item-selection-text.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SortByColumnNameComponent } from './components/sort-by-column-name/sort-by-column-name.component';
import { SortBySoundtrackCategoryComponent } from './components/sort-by-soundtrack-category/sort-by-soundtrack-category.component';
import { SortByStatusComponent } from './components/sort-by-status/sort-by-status.component';
import { AssetPreviewComponent } from './components/asset-preview/asset-preview.component';
import { LogoComponent } from './components/logo/logo.component';

registerLocaleData(localeEnGb);

export const DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: false,
    } as Intl.DateTimeFormatOptions,
  },
};

const materialModules = [
  MatCardModule,
  MatButtonModule,
  MatDialogModule,
  MatTooltipModule,
  MatMenuModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatChipsModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatTabsModule,
  MatButtonToggleModule,
];

const usedModules = [
  FormsModule,
  ReactiveFormsModule,
  DragDropModule,
  RouterModule,
  HttpClientModule,
  NgSelectModule,
  PickerModule,
  QRCodeModule,
];

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    ErrorComponent,
    AvatarComponent,
    ImagePreloaderDirective,
    TextHumanizePipe,
    BadgeComponent,
    CustomTooltipComponent,
    TooltipRendererDirective,
    LoadingDirective,
    FiltersComponent,
    QueryFiltersComponent,
    SearchBarComponent,
    InputErrorComponent,
    BackComponent,
    UploadComponent,
    DragDropDirective,
    FileSizePipe,
    DurationPipe,
    IsImagePipe,
    IsAudioPipe,
    IsVideoPipe,
    MoneyTransformPipe,
    ArraySumPipe,
    TrimFileExtensionPipe,
    SelectFilterComponent,
    SelectHttpFilterComponent,
    SelectHttpInputComponent,
    DateRangeFilterComponent,
    DateFilterComponent,
    DateInputComponent,
    EmojiInputComponent,
    DragCursorDirective,
    ProfileDialogComponent,
    NotableTableComponent,
    MoreActionsComponent,
    ViewAsBannerComponent,
    TrackRecordComponent,
    AddAdminDialogComponent,
    NumbersOnlyDirective,
    ValidPhoneNumberDirective,
    GreaterThanZeroDirective,
    EditAdminDialogComponent,
    TimeDisplayPipe,
    PasswordConstraintsComponent,
    UploadAssetsDialogComponent,
    UploadAssetComponent,
    AssetComponent,
    MoreActionsAssetsComponent,
    SelectSoundtrackCategoryHeaderComponent,
    EditSoundtracksCategoriesDialogComponent,
    ItemSelectionTextComponent,
    VideoOverlayComponent,
    SortByColumnNameComponent,
    SortBySoundtrackCategoryComponent,
    SortByStatusComponent,
    AssetPreviewComponent,
    LogoComponent,
  ],
  imports: [CommonModule, ...materialModules, ...usedModules],
  exports: [
    ...materialModules,
    ...usedModules,
    ErrorComponent,
    AvatarComponent,
    ImagePreloaderDirective,
    TextHumanizePipe,
    TrimFileExtensionPipe,
    BadgeComponent,
    TooltipRendererDirective,
    LoadingDirective,
    FiltersComponent,
    QueryFiltersComponent,
    SearchBarComponent,
    InputErrorComponent,
    BackComponent,
    UploadComponent,
    DragDropDirective,
    FileSizePipe,
    DurationPipe,
    IsImagePipe,
    IsAudioPipe,
    IsVideoPipe,
    MoneyTransformPipe,
    ArraySumPipe,
    SelectFilterComponent,
    SelectHttpFilterComponent,
    SelectHttpInputComponent,
    DateRangeFilterComponent,
    DateFilterComponent,
    DateInputComponent,
    EmojiInputComponent,
    DragCursorDirective,
    ProfileDialogComponent,
    NotableTableComponent,
    MoreActionsComponent,
    ViewAsBannerComponent,
    TrackRecordComponent,
    AddAdminDialogComponent,
    NumbersOnlyDirective,
    ValidPhoneNumberDirective,
    GreaterThanZeroDirective,
    EditAdminDialogComponent,
    TimeDisplayPipe,
    PasswordConstraintsComponent,
    UploadAssetsDialogComponent,
    UploadAssetComponent,
    AssetComponent,
    MoreActionsAssetsComponent,
    SelectSoundtrackCategoryHeaderComponent,
    EditSoundtracksCategoriesDialogComponent,
    ItemSelectionTextComponent,
    VideoOverlayComponent,
    SortByColumnNameComponent,
    SortBySoundtrackCategoryComponent,
    SortByStatusComponent,
    AssetPreviewComponent,
    LogoComponent,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'en-GB',
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class SharedModule {}
