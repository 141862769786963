<section class="confirmation-dialog">
  <h2 mat-dialog-title>{{ title }}</h2>
  <div mat-dialog-content>
    <p>{{ message }}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button (click)="dialogRef.close(false)">No</button>
    <button mat-flat-button color="{{ color }}" (click)="dialogRef.close(true)">
      {{ actionMessage }}
    </button>
  </div>
</section>
