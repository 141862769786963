import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { RoleGuard } from '../shared/guards/role.guard';
import { AdminType } from '../shared/models/admin';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'overview',
        loadChildren: () => import('./overview/overview.module').then((m) => m.OverviewModule),
      },
      {
        path: 'assets',
        loadChildren: () => import('./assets/assets.module').then((m) => m.AssetsModule),
      },
      {
        path: 'orders',
        canActivate: [RoleGuard],
        data: { allowedTypes: [AdminType.SUPERADMIN] },
        loadChildren: () => import('./requests/requests.module').then((m) => m.RequestsModule),
      },
      // {
      //   path: 'users',
      //   loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
      // },
      // {
      //   path: 'admin-users',
      //   canActivate: [RoleGuard],
      //   data: { allowedTypes: [AdminType.SUPERADMIN] },
      //   loadChildren: () => import('./admin-users/admin-users.module').then((m) => m.AdminUsersModule),
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
