import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  email: string;
  password: string;
  hidePassword: boolean = true;
  isLoading: boolean;
  error: any;

  constructor(private authService: AuthService, private router: Router, private toastService: ToastService) {}

  ngOnInit(): void {}

  async resetPassword() {
    if (this.isLoading) return;

    try {
      this.error = null;
      this.isLoading = true;
      await this.authService.forgotAdminPassword(this.email);

      this.toastService.show('An email was sent to reset your password!');
      this.router.navigate(['/login']);
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }
}
