<div
  class="asset-wrapper"
  [class.is-on-table]="isDisplayedOnTable"
  [class.is-audio]="givenAsset.type === allAssetTypes.audio"
  [class.is-soundtrack-category]="givenAsset.type === allAssetTypes.soundtrackCategory"
  [class.should-show-large-video]="shouldShowLargeVideo"
>
  <div
    class="asset-wrapper-icon-text"
    [class.is-audio]="givenAsset.type === allAssetTypes.audio"
    [class.is-on-table]="isDisplayedOnTable"
    [class.should-show-large-video]="shouldShowLargeVideo"
  >
    <section [class.hide-element-display]="hideAsset || !isVideoAsset()" [class.video-wrapper-small]="isVideoAsset()">
      <div
        class="video-details"
        [class.is-on-table]="isDisplayedOnTable"
        [class.should-show-large-video]="shouldShowLargeVideo"
        [appLoading]="isLoading"
      >
        <video [id]="assetId" [poster]="this.givenAsset.thumbnailUrl || ''" #element>
          <source
            *ngIf="givenAsset.type !== allAssetTypes.soundtrackCategory && isAssetLoaded"
            type="video/mp4"
            [src]="givenAsset.key"
          />
          Cannot play video file
        </video>
        <img
          [src]="
            !isLoading && !element.paused
              ? '/assets/images/icons/pause-video-overlay.svg'
              : shouldShowLargeVideo
              ? '/assets/images/icons/play-video-notable.svg'
              : '/assets/images/icons/play-video.svg'
          "
          [matTooltip]="!isLoading && !element.paused ? 'Pause video' : 'Play video'"
          (click)="loadAsset()"
          class="cursor-pointer control-icon"
        />
        <div *ngIf="element && shouldShowLargeVideo" class="video-duration">
          <span *ngIf="!element.duration" [appLoading]="!element.duration"></span>
          <span *ngIf="element.duration"> {{ element.duration | duration }} </span>
        </div>
      </div>
    </section>

    <div class="asset-name-wrapper" [class.is-audio]="givenAsset.type === allAssetTypes.audio">
      <div
        *ngIf="givenAsset.type === allAssetTypes.audio && !hideAsset"
        [appLoading]="isLoading"
        class="audio-play-wrapper"
      >
        <button>
          <img
            [src]="
              !isLoading && !element.paused
                ? '/assets/images/icons/round-pause.svg'
                : '/assets/images/icons/round-play.svg'
            "
            [matTooltip]="!isLoading && !element.paused ? 'Pause audio' : 'Play audio'"
            (click)="loadAsset()"
            class="cursor-pointer"
          />
        </button>
        <svg *ngIf="givenAsset.type === allAssetTypes.audio && !hideAsset" class="progress">
          <circle
            class="progress-circle"
            #progressCircle
            cx="0"
            cy="0"
            stroke="#BD884B"
            r="13"
            fill="transparent"
            stroke-width="2"
          />
        </svg>
      </div>

      <div
        *ngIf="(!isDisplayedOnTable || hideAsset) && !shouldHideActions"
        class="item"
        [class.is-soundtrack-category]="givenAsset.type === allAssetTypes.soundtrackCategory"
      >
        <button
          *ngIf="givenAsset.type === allAssetTypes.soundtrackCategory"
          [disabled]="isDisabledDragInput"
          [class.is-soundtrack-category]="givenAsset.type === allAssetTypes.soundtrackCategory"
        >
          <img
            src="/assets/images/icons/order.svg"
            [matTooltip]="isDisabledDragInput ? '' : 'Order'"
            [class.is-disabled-grab]="isDisabledDragInput"
            [class.is-soundtrack-category]="givenAsset.type === allAssetTypes.soundtrackCategory"
          />
        </button>
        <div class="item-details">
          <div class="asset-name">
            <input
              [(ngModel)]="assetName"
              [name]="assetName"
              (keydown.enter)="onSaveRename($event)"
              (click)="onClickInput($event)"
              (blur)="onBlurInput()"
              [disabled]="isInputDisabled"
              [class.is-on-table]="isDisplayedOnTable"
              [class.is-input-disabled]="isInputDisabled"
              [class.is-on-modal]="isOnModal"
              [class.is-video]="isVideoAsset()"
              [class.is-soundtrack-category]="givenAsset.type === allAssetTypes.soundtrackCategory"
              type="text"
              placeholder="Enter file name"
              maxlength="80"
              required
            />
            <div class="rename-buttons">
              <button (click)="onSaveRename($event)" [disabled]="!assetName">Save</button>
              <button (click)="onCancelRename($event)">Cancel</button>
            </div>
          </div>

          <div class="background-text-position" *ngIf="givenAsset.type === allAssetTypes.video && !isDisplayedOnTable">
            <label>Text position:</label>
            <mat-button-toggle-group
              [(ngModel)]="backgroundTextPosition"
              (ngModelChange)="backgroundTextPositionChange.emit($event)"
              name="backgroundTextPosition"
            >
              <mat-button-toggle [value]="BackgroundTextPosition.LEFT">
                {{ BackgroundTextPosition.LEFT }}
              </mat-button-toggle>
              <mat-button-toggle [value]="BackgroundTextPosition.RIGHT">
                {{ BackgroundTextPosition.RIGHT }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
  </div>

  <img
    *ngIf="!isDisplayedOnTable && !isInputDisabled && !hideRemove && !shouldHideActions"
    src="/assets/images/icons/trash.svg"
    class="cursor-pointer remove-icon"
    (click)="onRemove($event)"
  />
</div>
