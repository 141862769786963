import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { AuthService } from '../../services/auth.service';
import { AdminUserService } from '../../services/admin-user.service';
import { AcceptedMimeTypes, FileDetails } from '../upload/upload.component';
import { UploadService } from '../../services/upload.service';
import { UploadScope } from '../../models/upload';
import { ViewAsService } from '../../services/view-as.service';
import { Admin, AdminTypeAll, AdminType } from '../../models/admin';
import { SuccessDialogService } from '../../services/success-dialog.service';

export interface ProfileDialogData extends Partial<Admin> {
  newPassword?: string;
  confirmPassword?: string;
}
export interface ProfileDialogCloseResult {
  hasUploadedFile: boolean;
}

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss'],
})
export class ProfileDialogComponent {
  error: any;
  isLoading = false;
  hidePassword: boolean = true;
  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;
  isLoggingOut = false;
  adminTypes = AdminTypeAll.map((item) => ({ label: item, value: item }));
  isChangingPassword = false;
  fileDetails: FileDetails;
  acceptedMimeTypes = AcceptedMimeTypes;

  filename: string;
  hasUploadedFile = false;

  @Output() logout: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public dialogRef: MatDialogRef<ProfileDialogComponent>,
    public dialogSuccess: MatDialog,
    private toastService: ToastService,
    private authService: AuthService,
    private adminUserService: AdminUserService,
    private uploadService: UploadService,
    private viewAsService: ViewAsService,
    @Inject(MAT_DIALOG_DATA) public data: ProfileDialogData,
    private successDialogService: SuccessDialogService
  ) {}

  getUploadScope() {
    const userType = this.authService.user.type;
    if (userType === AdminType.SUPERADMIN || userType === AdminType.CONCIERGE || userType === AdminType.BROKER) {
      return UploadScope.adminProfilePicture;
    }
    return UploadScope.userProfilePicture;
  }

  async onSaveChanges(): Promise<void> {
    const closeResult: ProfileDialogCloseResult = {
      hasUploadedFile: this.hasUploadedFile,
    };
    this.isLoading = true;
    try {
      // Upload new image only if user selected an image
      if (this.fileDetails) {
        this.hasUploadedFile = true;
        await this.uploadService.uploadFile({
          name: this.fileDetails.filename,
          scope: this.getUploadScope(),
          owner: this.authService.user._id,
          file: this.fileDetails.file,
          metadata: {
            filename: this.fileDetails.filename,
            height: this.fileDetails.height,
            width: this.fileDetails.width,
            size: this.fileDetails.size,
          },
        });
      }
      await this.adminUserService.updateOne(this.authService.user._id, this.data);
      this.dialogRef.close(closeResult);
      this.toastService.showSuccess('Changes were saved');
    } catch (error) {
      this.toastService.showError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async onLogout() {
    try {
      this.isLoggingOut = true;
      await this.authService.logout();
      this.logout.emit();
      this.viewAsService.updateVisibilityBanner(false);
    } catch (error) {
      this.toastService.showError(error);
    } finally {
      const dialogCloseResult: ProfileDialogCloseResult = {
        hasUploadedFile: this.hasUploadedFile,
      };
      this.dialogRef.close(dialogCloseResult);
      this.isLoggingOut = false;
    }
  }

  togglePasswordField() {
    this.isChangingPassword = !this.isChangingPassword;
  }

  onBack() {
    this.error = '';
    this.isChangingPassword = !this.isChangingPassword;
    this.data.password = '';
    this.data.newPassword = '';
    this.data.confirmPassword = '';
  }

  async changePassword() {
    this.isLoading = true;
    try {
      if (this.data.newPassword !== this.data.confirmPassword) {
        throw Error('New password and confirm password must match.');
      }

      if (this.data.password === this.data.newPassword) {
        throw Error('Your new password must be different than your current password.');
      }

      await this.authService.changeAdminPasswordFromModal(this.data._id, this.data.password, this.data.newPassword);
      this.dialogRef.close();
      await this.authService.logout();
      this.successDialogService.openSuccessDialog();
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }
}
