import { firstValueFrom, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TableQueryParams } from '../models/table-query-params';
import { PagedResponse } from '../models/paged-response';
import { TableUtils } from '../utils/table.utils';
import { UserStatus } from '../models/user';
import { AdminStatus } from '../models/admin';
import { NotableStatus } from '../models/notable';
import { AssetStatus } from '../models/asset';

export abstract class CrudService<T> {
  private dataChangedSubject: Subject<boolean> = new Subject<boolean>();

  public dataChanged$(): Observable<boolean> {
    return this.dataChangedSubject.asObservable();
  }

  protected constructor(protected http: HttpClient, protected endpoint: string) {}

  getAll(params: TableQueryParams): Promise<PagedResponse<T>> {
    const queryParams = TableUtils.prepareForApiParams(params);
    return this.http.get<PagedResponse<T>>(this.endpoint, { params: queryParams }).toPromise();
  }

  getOne(id: string): Promise<T> {
    return this.http.get<T>(`${this.endpoint}/${id}`).toPromise();
  }

  async deleteOne(id: string) {
    const result = await this.http.delete(`${this.endpoint}/${id}`).toPromise();
    this.dataChangedSubject.next(true);
    return result;
  }

  async deleteMany(ids: string[]) {
    const result = await this.http.delete(`${this.endpoint}`, { body: { ids } }).toPromise();
    this.dataChangedSubject.next(true);
    return result;
  }

  async updateOne(id: string, data: Partial<T>, { skipTriggerChange = false }: { skipTriggerChange?: boolean } = {}) {
    const result = await this.http.put<T>(`${this.endpoint}/${id}`, data).toPromise();
    if (!skipTriggerChange) {
      this.dataChangedSubject.next(true);
    }
    return result;
  }

  async addOne(data: Partial<T>) {
    const result = await this.http.post<T>(this.endpoint, data).toPromise();
    this.dataChangedSubject.next(true);
    return result;
  }

  async updateManyStatus(ids: string[], status: UserStatus | AdminStatus | NotableStatus | AssetStatus) {
    const result = await this.http
      .put<T>(`${this.endpoint}/status`, {
        ids,
        status,
      })
      .toPromise();
    this.dataChangedSubject.next(true);
    return result;
  }

  async updateManyData(data: any) {
    const result = await firstValueFrom(
      this.http.put<T>(`${this.endpoint}`, {
        data,
      })
    );
    this.dataChangedSubject.next(true);
    return result;
  }

  markUpdate() {
    this.dataChangedSubject.next(true);
  }
}
