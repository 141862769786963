import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddOrEditNotableComponent } from 'src/app/admin/overview/overview-notable/add-or-edit-notable/add-or-edit-notable.component';
import { AddOrEditNotableProfileDialog } from 'src/app/admin/overview/overview-notable/overview-user-profile-notable/notable-details/notable-details.component';
import { AdminType } from '../../models/admin';
import { Notable, NotableStatus } from '../../models/notable';
import { AuthService } from '../../services/auth.service';
import { NotableService } from '../../services/notable.service';
import { TabService } from '../../services/tab.service';
import { ToastService } from '../../services/toast.service';
import { Constants } from '../../utils/constants';
import { DataTableComponent } from '../data-table.component';

export enum NotableTabs {
  financials = 'Financials',
  // requests = 'Requests',
  // internal = 'Internal',
}
@Component({
  selector: 'app-notable-table',
  templateUrl: './notable-table.component.html',
  styleUrls: ['./notable-table.component.scss'],
})
export class NotableTableComponent extends DataTableComponent<Notable> implements OnInit, OnChanges {
  displayedColumns = [
    'select',
    'firstName',
    'status',
    'lastActivity',
    'graphPrice',
    'brokerTake',
    // 'earnings',
    // 'potentialEarnings',
  ];

  // displayedColumnsGraphs = [
  //   'select',
  //   'firstName',
  //   'status',
  //   'lastActivity',
  //   'allRequests',
  //   'unfulfilled',
  //   'sold',
  //   'declined',
  // ];

  // displayedColumnsInternal = ['select', 'firstName', 'status', 'lastActivity', 'notes', 'socialLinks'];

  userStatuses = NotableStatus;

  @Input() isLoading = false;
  @Input() data: Notable[];
  @Input() dataTable: Notable[];
  @Input() dataLengthPaginator: number;
  @Input() activePagePaginator: number;
  @Input() activeLengthPaginator: number;
  @Input() pageSizeOptionsPaginator: number[];
  @Input() showDefaultPaginator = true;
  @Input() take: number;
  @Input() shouldShowAddButton = true;

  tabsTypeAll = Object.values(NotableTabs);
  tabs = NotableTabs;
  activeTab: NotableTabs;

  constructor(
    public route: ActivatedRoute,
    private notableService: NotableService,
    public router: Router,
    private toast: ToastService,
    public tabService: TabService,
    public dialog: MatDialog,
    public auth: AuthService
  ) {
    super(notableService, Constants.overviewTabs.notables);
  }

  ngOnInit() {
    this.activeTab = this.tabs.financials;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.data = changes.data?.currentValue;
    this.dataLength = this.data?.length ?? 0;
    if (!this.data && this.dataTable) {
      this.data = this.dataTable;
    }
  }

  get isSuperadmin() {
    return this.auth.user.type === AdminType.SUPERADMIN;
  }

  changeActiveTab(tab: NotableTabs) {
    this.activeTab = tab;
  }

  isActiveTab(tab: NotableTabs) {
    return this.activeTab === tab;
  }

  getDisplayedColumns() {
    switch (this.activeTab) {
      case this.tabs.financials:
        return this.displayedColumns;
      // case this.tabs.requests:
      //   return this.displayedColumnsGraphs;
      // case this.tabs.internal:
      //   return this.displayedColumnsInternal;
      default:
        return this.displayedColumns;
    }
  }

  async changeNotableStatus(id: string, status: NotableStatus) {
    try {
      this.error = null;
      this.isLoading = true;
      await this.notableService.updateOne(id, { status });
      this.clearSelection();
      this.toast.show('User updated');
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  async bulkChangeNotableStatus(status: NotableStatus) {
    try {
      this.error = null;
      this.isLoading = true;
      await this.notableService.updateManyStatus(this.selection.selected, status);
      this.clearSelection();
      this.toast.show('Users updated');
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  addOrEditNotableProfile(mode: 'add' | 'edit', selectedItem?: Notable) {
    document.body.classList.add('hide-scrollbar');
    const data: AddOrEditNotableProfileDialog = {
      mode,
      ...(mode === 'edit' && selectedItem && { notable: selectedItem }),
    };
    this.dialog
      .open(AddOrEditNotableComponent, {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) return;
        // if result is true, changes have been made
        this.clearSelection();
      });
  }
}
