<section class="toolbar">
  <p *ngIf="shouldDisplayPageName()" class="page-text">{{ pageName }}</p>
  <div *ngIf="!shouldDisplayPageName()" class="flex-row-wrapper w100">
    <app-back [route]=""></app-back>
  </div>
  <div>
    <button mat-button class="menu-button" *ngIf="isMobile" (click)="menuToggle.emit()">
      <i class="icon"> menu </i>
    </button>
  </div>
</section>
