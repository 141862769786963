import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NotableAsset } from '../../models/notable';
import { AssetResponseDto } from '../../services/assets.service';
import { OverlayVideoService } from '../../services/overlay-video.service';

@Component({
  selector: 'app-video-overlay',
  templateUrl: './video-overlay.component.html',
  styleUrls: ['./video-overlay.component.scss'],
})
export class VideoOverlayComponent implements OnInit {
  @Input() message: string;
  @Output() closePanel = new EventEmitter<void>();

  @ViewChild('assetElement') assetElement: ElementRef<HTMLVideoElement>;
  @ViewChild('sliderElement') sliderElement: ElementRef<HTMLInputElement>;

  videoAsset: AssetResponseDto;
  hasError = false;
  isLoading = true;
  assetDuration = 0.0; // in seconds
  assetTimeDisplay: string;
  assetCurrentPlayDuration = 0.0;
  abortController = new AbortController();
  progressInterval: any;
  shouldPlayVideoAfterSliderChange = true;

  constructor(private overlayVideoService: OverlayVideoService) {}

  ngOnInit(): void {
    this.videoAsset = this.overlayVideoService.video;
  }

  ngAfterViewInit() {
    this.assetElement.nativeElement.addEventListener(
      'loadedmetadata',
      async () => {
        this.assetDuration = parseFloat(this.assetElement.nativeElement.duration.toString());
        this.assetTimeDisplay = this.formatTime(this.assetCurrentPlayDuration);

        this.sliderElement.nativeElement.min = '0';
        this.sliderElement.nativeElement.max = this.assetDuration.toString();
        this.sliderElement.nativeElement.style.setProperty('--min', this.sliderElement.nativeElement.min);
        this.sliderElement.nativeElement.style.setProperty('--max', this.sliderElement.nativeElement.max);
        this.sliderElement.nativeElement.style.setProperty('--value', this.sliderElement.nativeElement.value);

        this.isLoading = false;
        this.playPauseVideoAsset();
      },
      {
        signal: this.abortController.signal,
      }
    );

    // After 60 seconds, if asset didn't load, remove listener and raise error
    setTimeout(() => {
      if (this.isLoading) {
        this.abortController.abort();
        this.hasError = true;
        this.isLoading = false;
      }
    }, 60000);
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.closePanel.emit();
  }

  playPauseVideoAsset(e?) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (this.hasError) {
      return;
    }

    if (this.isVideoAssetPlaying()) {
      this.shouldPlayVideoAfterSliderChange = false;
      this.pauseVideo();
      this.updateVideoAndSlider();
      return;
    }

    if (this.isReplayVideo()) {
      this.assetCurrentPlayDuration = 0;
      this.assetTimeDisplay = this.formatTime(0);
      this.sliderElement.nativeElement.value = '0';
      this.sliderElement.nativeElement.style.setProperty('--value', '0');
    }

    this.assetElement.nativeElement.play();
    this.shouldPlayVideoAfterSliderChange = true;
    this.progressInterval = setInterval(() => {
      this.updateVideoAndSlider();
      if (this.assetCurrentPlayDuration === this.assetDuration) {
        clearInterval(this.progressInterval);
      }
    }, 10);
  }

  private updateVideoAndSlider() {
    this.assetCurrentPlayDuration = parseFloat(this.assetElement.nativeElement.currentTime.toString());
    this.assetTimeDisplay = this.formatTime(this.assetCurrentPlayDuration);

    this.sliderElement.nativeElement.value = this.assetCurrentPlayDuration.toString();
    this.sliderElement.nativeElement.style.setProperty('--value', this.sliderElement.nativeElement.value);
  }

  private pauseVideo() {
    this.assetElement.nativeElement.pause();
    clearInterval(this.progressInterval);
  }

  private isVideoAssetPlaying() {
    return this.assetElement && !this.assetElement.nativeElement.paused;
  }

  private isReplayVideo() {
    return (
      this.assetElement &&
      this.assetElement.nativeElement.paused &&
      this.assetCurrentPlayDuration === this.assetDuration
    );
  }

  onInputSlider(e) {
    e.stopPropagation();
    e.preventDefault();

    this.pauseVideo();

    this.assetElement.nativeElement.currentTime = parseFloat(this.sliderElement.nativeElement.value);
    this.assetCurrentPlayDuration = parseFloat(this.sliderElement.nativeElement.value);
    this.sliderElement.nativeElement.style.setProperty('--value', this.sliderElement.nativeElement.value);
    this.assetTimeDisplay = this.formatTime(this.assetCurrentPlayDuration);
  }

  onChangeSlider(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.shouldPlayVideoAfterSliderChange) {
      this.playPauseVideoAsset(e);
    }
  }

  closeOverlay() {
    this.closePanel.emit();
  }

  preventCloseOverlay(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  formatTime(seconds: number) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.round(seconds % 60);
    return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s].filter(Boolean).join(':');
  }
}
