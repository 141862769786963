import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { VideoOverlayComponent } from '../components/video-overlay/video-overlay.component';
import { NotableAsset } from '../models/notable';
import { AssetResponseDto } from './assets.service';

@Injectable({
  providedIn: 'root',
})
export class OverlayVideoService {
  video: AssetResponseDto;

  constructor(private overlay: Overlay) {}

  public openVideoOverlay(asset: AssetResponseDto) {
    this.video = asset;
    const overlayRef = this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.block(),
      backdropClass: 'backdropBackground',
    });
    const componentPortal = new ComponentPortal(VideoOverlayComponent);
    const openedRef = overlayRef.attach(componentPortal);
    openedRef.instance.closePanel.subscribe(() => overlayRef.detach());
  }
}
