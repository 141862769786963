<ng-container *ngIf="selection && isOnTableView">
  <section class="selection-wrapper" *ngIf="selection?.selected?.length === 1 && selectedId && selectedItem">
    <button *ngIf="!hideMoveTo" (click)="openMoveToDialog()">
      <span>Move to...</span>
    </button>
    <button *ngIf="selectedItem.status !== assetStatus.DISABLED" (click)="changeAssetStatus(assetStatus.DISABLED)">
      <span>Disable</span>
    </button>
    <button *ngIf="selectedItem.status !== assetStatus.SHOWING" (click)="changeAssetStatus(assetStatus.SHOWING)">
      <span>Activate</span>
    </button>
    <button (click)="removeAsset()">
      <span>Delete</span>
    </button>
  </section>
  <section class="selection-wrapper" *ngIf="selection?.selected?.length > 1">
    <button *ngIf="!hideMoveTo" (click)="openMoveToDialog()">
      <span>Move to...</span>
    </button>
    <button (click)="bulkChangeAssetStatus(assetStatus.DISABLED)">
      <span>Disable</span>
    </button>
    <button (click)="bulkChangeAssetStatus(assetStatus.SHOWING)">
      <span>Activate</span>
    </button>
    <button (click)="bulkDeleteAsset()">
      <span>Delete</span>
    </button>
  </section>
</ng-container>
