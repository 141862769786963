<div class="mat-sort-header-container">
  <p>{{ columnDisplayName }}</p>
  <ng-container *ngIf="statusEntityInput === statusEntity.ASSET">
    <button class="filter-table-row-arrow" [matMenuTriggerFor]="nameSort">
      <i></i>
    </button>
    <mat-menu #nameSort="matMenu" xPosition="before" class="menu-wrapper">
      <button mat-menu-item (click)="sortOrderByStatus('desc')">
        <div>
          <span class="text-capitalize">Show {{ assetStatus.SHOWING }}</span
          ><span> first</span>
        </div>
        <i *ngIf="activeSortOrder === 'desc' && activeStatus" class="icon">check</i>
      </button>
      <button mat-menu-item (click)="sortOrderByStatus('asc')">
        <div>
          <span class="text-capitalize">Show {{ assetStatus.DISABLED }}</span
          ><span> first</span>
        </div>
        <i *ngIf="activeSortOrder === 'asc' && activeStatus" class="icon">check</i>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="statuses">
        <span>Show only</span>
      </button>
    </mat-menu>
    <mat-menu #statuses="matMenu" xPosition="after" class="menu-wrapper status-menu-wrapper">
      <button mat-menu-item (click)="sortByStatusOnly(assetStatus.SHOWING)">
        <span class="text-capitalize">{{ assetStatus.SHOWING }}</span>
        <i *ngIf="activeStatus === assetStatus.SHOWING" class="icon">check</i>
      </button>
      <button mat-menu-item (click)="sortByStatusOnly(assetStatus.DISABLED)">
        <span class="text-capitalize">{{ assetStatus.DISABLED }}</span>
        <i *ngIf="activeStatus === assetStatus.DISABLED" class="icon">check</i>
      </button>
    </mat-menu>
  </ng-container>
  <ng-container *ngIf="statusEntityInput === statusEntity.REQUEST">
    <button class="filter-table-row-arrow" [matMenuTriggerFor]="requests">
      <i></i>
    </button>
    <mat-menu #requests="matMenu" xPosition="before" class="menu-wrapper">
      <button mat-menu-item (click)="sortByStatusOnly(requestStatus.PENDING)">
        <span class="text-capitalize">{{ requestStatus.PENDING }}</span>
        <i *ngIf="activeStatus === requestStatus.PENDING" class="icon">check</i>
      </button>
      <button mat-menu-item (click)="sortByStatusOnly(requestStatus.DELIVERED)">
        <span class="text-capitalize">{{ requestStatus.DELIVERED }}</span>
        <i *ngIf="activeStatus === requestStatus.DELIVERED" class="icon">check</i>
      </button>
      <button mat-menu-item (click)="sortByStatusOnly(requestStatus.DECLINED)">
        <span class="text-capitalize">{{ requestStatus.DECLINED }}</span>
        <i *ngIf="activeStatus === requestStatus.DECLINED" class="icon">check</i>
      </button>
    </mat-menu>
  </ng-container>
</div>
