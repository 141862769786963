import { Pipe, PipeTransform } from '@angular/core';
import { TableUtils } from '../utils/table.utils';

@Pipe({
  name: 'moneyTransform',
})
export class MoneyTransformPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    return TableUtils.moneyFormatter(TableUtils.convertCentsToDollars(value));
  }
}
