import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreateUserProfileDialog } from 'src/app/admin/overview/overview-notable/add-or-edit-notable/add-or-edit-notable.component';
import { Admin, AdminStatus } from 'src/app/shared/models/admin';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SuccessDialogService } from 'src/app/shared/services/success-dialog.service';
import { CustomValidators } from 'src/app/shared/utils/custom-validators';
import { ErrorUtils } from 'src/app/shared/utils/error.utils';

@UntilDestroy()
@Component({
  selector: 'app-join-business',
  templateUrl: './join-business.component.html',
  styleUrls: ['./join-business.component.scss'],
})
export class JoinBusinessComponent implements OnInit {
  password: string;
  samepassword: string;
  hidePassword: boolean = true;
  isLoading: boolean;
  error: any;
  token: string;
  invitedAdmin: Partial<Admin>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private successDialogService: SuccessDialogService
  ) {}

  async ngOnInit() {
    this.route.params.pipe(untilDestroyed(this)).subscribe(async (params) => {
      if (params.id) {
        this.token = params.id;
        try {
          this.isLoading = true;
          this.invitedAdmin = await this.authService.getAdminByInvitationToken(this.token);
          if (this.invitedAdmin?.status !== AdminStatus.INVITED) {
            this.router.navigate(['/login']);
          }
        } catch (error) {
          this.error = ErrorUtils.toString(error);
        } finally {
          this.isLoading = false;
        }
      }
    });
  }

  async setPassword() {
    if (this.isLoading) return;
    try {
      this.error = null;
      this.isLoading = true;

      if (this.password !== this.samepassword) {
        throw new Error('Passwords must match!');
      }

      if (!CustomValidators.isPasswordValid(this.password)) {
        throw new Error(
          'Your password must be at least 8 characters long, must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.'
        );
      }
      await this.authService.joinBusiness(this.token, this.password);
      const data: CreateUserProfileDialog = {
        hasCreatedAdminAccount: true,
      };
      this.successDialogService.openSuccessDialog(data);
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }
}
